import styled from '@emotion/styled';
import React from "react";
import { QuestionStage, Remark } from '../../api/types/candidates';
import { Question, QuestionType_NoVote } from '../../api/types/main';
import { Stages } from '../../components/Stages/Stages';
import { QuestionButtons } from '../candidates/QuestionButtons';
import { CandidateRemark } from '../candidates/CandidateRemark';
import { Candidates } from '../candidates/Candidates';
import { QuestionDeadline } from '../candidates/QuestionDeadline';
import { media } from '../media';
import { QuestionCase } from './QuestionCase/QuestionCase';
import { QuestionCaseDescription } from './QuestionCase/QuestionCaseDescription';
import { QuestionCasePlaintiffs } from './QuestionCase/QuestionCasePlaintiffs';
import { QuestionVoteDates } from './QuestionCase/QuestionVoteDates';
import { QuestionOtherCases } from './QuestionCase/QuestionOtherCases';
import { OptionLabel } from './OptionLabel';
import { FormattedMessage } from 'react-intl';
import { themeColor } from '../../components/Theme';



const QuestionContentPlaintiff = styled.div`
    margin-right: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;

    @media ${media.l} {
        width: 100%;
        margin: auto;
    }
`;

const QuestionCaseContent = styled.div`
    margin: auto;
    width: 80%;
    display: flex;

    @media ${media.l} {
        width: 90%;
        margin: auto;
        flex-direction: column;
        align-items: center;
    }
`;

const Note = styled.p`
    width: 100%;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
    color: ${themeColor(c => c.textGreyBasic)};
`;

export const QuestionDescription = ({ data, setData }: { data: Question, setData: (data: Question) => void }) => {
    const questionStatus = data.stages.filter((stage: QuestionStage) => !stage.is_finished)[0]?.code as keyof Remark;

    const [startDate, endDate] = data.is_candidates_question ? [data.vote_start_date, data.vote_end_date] : [data.start_date, data.end_date];

    return <>
        {data.case_info && <QuestionCase case_info={data.case_info} />}
        <QuestionCaseContent>
            <QuestionContentPlaintiff>
                {data.case_info && <QuestionCasePlaintiffs type="claimants" case_info={data.case_info} />}
                {data.case_info && <QuestionCasePlaintiffs type="respondants" case_info={data.case_info} />}
                <QuestionVoteDates
                    type={data.question_type === QuestionType_NoVote ? "discussion" : "voting"}
                    start_date={startDate}
                    end_date={endDate}
                    />
            </QuestionContentPlaintiff>

            {!data.status.is_conflict &&
                <QuestionCaseDescription
                    isConflict={data.status.is_conflict}
                    questionId={data._id}
                    comments={data.comments}
                    documents={data.documents}
                />
            }

        </QuestionCaseContent>

        <QuestionOtherCases otherQuestions={data.related_questions} />

        {data.active_stage !== null &&
            <Stages active_stage={data.active_stage} stages={data.stages} />}

        {!data.status.is_conflict && data.is_candidates_question &&
            <Candidates candidates={data.candidates} questionId={data._id} />}

        <CandidateRemark status={questionStatus} />
        <QuestionDeadline date={data.active_stage.end_date} />

        {data.status.option?.label && <Note><FormattedMessage id="feedback.youVoted" />: <OptionLabel option={data.status.option} /></Note>}
        <QuestionButtons candidates={data.candidates} data={data} id={data._id} setData={setData} />
    </>
}
