import React, { createContext, ReactNode, useContext } from "react";
import { useWebSocket, socketByToken, WebSocketHandle } from '../toolympus/hooks/useWebsocket';

const FeedContext = createContext<IFeedContext>({
    open: false,
    send: () => {},
    socket: undefined,
});

export type IFeedContext = WebSocketHandle;

export const FeedProvider = (props: { children?: ReactNode }) => {
    const feedSocket = useWebSocket(
        socketByToken('/presidium-app/feed/token', token => '/presidium-app/feed?token=' + token),
        () => {},
        false);
    return (
        <FeedContext.Provider value={feedSocket}>
            {props.children}
        </FeedContext.Provider>
    );
}

export const useFeedSocket = () => useContext(FeedContext);
