export const racaEnMessages = {
    general: {
        forgot_password: "Forgot your password?",
        reset_password_hint: "If you forgot your password, you can start password reset procedure by submiting your email through this form.",
        board: "Board",
    },
    notifications: {
        settings_dialog_title: "E-mail notification settings",
        notifications_mode_section: "Send E-mail notifications",
        profile_notifications_mode: "for future questions",
        question_notifications_mode: "for this question",
        language: "Notifications language",
        notifications_language: {
            ru: "Русский",
            en: "English",
        },
        notifications_mode: {
            all: "All activity",
            "only-important-events": "Important events, without discussion messages",
        },
    },
    question: {
        claimantsTitle: "Title",
        claimants: "Claimants",
        respondants: "Respondants",
        inn: "TIN",
        caseNumber: "Case number",
        candidates: "Candidates",
        candidatesDesc: "You may suggest a candidate before voting starts",
        coordination: "Confirming",
        coordinationDesc: "Please wait while we collect preliminary confirmations from candidates",
    }, 

    results: {
        abstain: "Abstained",
        against_all: "Against all",
        conflict: "Conflict",
        vote: "Voted",
        caseNumber: "№",
        lnsCandidate: "Candidate full name",
        option: "Option",
        points: "Score",
        voices: "Votes",
        results: "Results",

        score_candidates_explanation: `The score of a particular candidate is calculated as an average of the positions selected for the candidate by board members.
        
The lower the score, the higher is the priority of the candidate.`,
        score_options_explanation: `Option score is calculated as the number of board members who voted for the option.
        
The option with the highest score wins.`,
    },

    tabs: {
        description: "Details",
        discussion: "Discussion",
        results: "Results",
        voices: "Votes",
    },

    button: {
        voting: "Vote",
        changeVote: "Change vote",
        suggestCandidate: "Suggest a candidate", 
        suggestCandidateDes: "During this stage you may suggest a candidate",
        discuss: "Discuss",
        abstain: "Abstain",
        againstAll: "Against all",
        conflict: "Declare conflict",
    },

    voting: {
        start: "Voting starts",
        end: "Voting ends",
        discussion_start: "Discussion starts",
        discussion_end: "Discussion ends",
        confirm: "Confirm",

        candidates: {
          options: {
            empty: "...",
            dontvote: "don't vote",
            conflict: "conflict",
          },
        },
    },

    candidate: {
        langs: "Languages",
        specialization: "Specialisation",
        sphere: "Area",
    },

    service: {
        chooseFromList: "Select from the list",
        download: "download",
        downloadDescription: "Download the description",
        downloadDisclosureDescription: "Download the document",
        description: "Description",
        descriptionFull: "© 2016-{YEAR} The Russian Arbitration Center at the Autonomous Non-profit Organisation «Russian Institute of Modern Arbitration»",
        copyright: "© 2016-2022 RAC",
        logout: "Log out",
        more: "Show more",
        collapse: "Hide",
        leftToEnd: "Time left",
        send: "Send",
        back: "Back",
        d: "d",
        hr: "h",
        mn: "m",
        stages: "Stages",
        start: "Start",
        end:  "End",
        past: "Past",
        current: "Current",
        circumstances: "Additional circumstances were disclosed",
        revote: "Change vote",
        none: "-",
        clean: "clear",
        hide: "Hide",
        showOtherCases: "Other questions on this case",
        dash: "-",
        no_questions: "There are no questions for voting",
        no_questions_found: "No questions found",
    },

    history: {  
        home: "Home",
        votingQuestions: "Questions for voting",
        news: "Feed",
        caseTitle: "Case",
        documentation: "Documentation",
    },

    addition: {
        notSimilarQuestions: "There are no related questions",
        questions: "questions",
        question: "Question",
        votingDate: "Voting date",
        suggestBefore: "Suggest a candidate by",
        voteBefore: "Cast a vote by",
        freeForActive: "We're collecting confirmations from the candidates",
        finishing: "Finalising results",
        search: "Search",
        close: "Close",
        
        writeFullName: "Enter full name",
        writeTelNumber: "Enter phone",
        writeEmail: "Enter email",
        
        logoTitle: "Russian Arbitration Center",
        currentLang: "Current language: English",
        messanger: "Discussion",
        
        loader: "Loading, please wait",
        showDescription: "Show description",
        downloadFile: "Download the file",
    
        emptyContent: "-",
        unknown: "Unknown",
        caseTitle: "Case",
        empty: "-",
        toDescription: "To description",
        toQuestion: "To question",
        news: "Feed",

        unknownUser: "Someone",
        emptyCandidates: "No candidates"
    },

    chat: {
        no_messages: "No messages yet",
        message_placeholder: "Enter your message",
        message_empty: "Please enter message",
        send_file_to_chat: "Send file to discussion?",
    },

    information: {
        contacts: {
            contact: "Contact",
            address: "119017, Moscow, Kadashevskaya embankment, 14, bldg 3",
            mail: "info@centerarbitr.ru",
            phone: "7 495 797 94 77", 
            phonePlaceholder: "+74957979477" 
        },
        
        menu: {
            main: "Home",
            name: "RAC website",
            contacts: "Contacts",
            results: "Results",
            chat: "Discussion",
            regulations: "Arbitration Rules"
        },

        blockVote: {
            conflict: 
            ` 
                You declared a conflict of interests, \nvoting is not available to you.\n
    
                If you don't have a conflict,\n please reach out to the administrative office.
            `,
    
            abstain:
            `
                Вы воздержались, \nголосование для вас недоступно.\n
    
                Если вы не воздержались,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
    
            againstAll:
            `
                Вы проголосовали против всех, \nголосование для вас недоступно.\n
    
                Если вы ошиблись,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            voting:
            `
                Вы уже проголосовали, \nголосование для вас недоступно.
    
                Если у вы ошиблись,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            suggestCandidate:
            `
                Вы уже указали кандидата, \nголосование для вас недоступно.
    
                Если вы хотите изменить свой выбор,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            default:
            `
                You're not permitted to vote
            `
        }
    },

    modalWindow: {
        abstain: "Are you sure you want to abstain from voting?",
        conflict: "Do you have a conflict of interests?",
        removeFromVote: "Remove me from voting",
        againstAll: "Are you sure you want to vote against all?",
        voting: "Please arrange the candidates from the most preferable to the least preferable",
        voting_options_title: "Please select an option",

        suggestCandidate: {
            title: "Suggest a candidate",

            writeFullName: "Candidate full name", 
            writeFullNamePlaceholder: "Ivanova Maria",
            writeTelNumber: "Candidate phone", 
            writeTelNumberPlaceholder: "8 999 888 7766",
            writeEmail: "Candidate E-mail", 
            writeEmailPlaceholder: "maria.ivanova@firm.com",
            writeComments: "Comment",
            writeCommentsPlaceholder: "...",

            missing_fullname: "Enter name",
            missing_email: "Enter E-mail",
        },

            
        choice: {
            accept: "Yes",
            reject: "No",
            vote: "Vote",
        },

        successModalWindow: "You have already cast your vote",

        suggest_option: {
            title: "Suggest another option",
            existing_options: "Existing options:",
            suggest_and_vote: "Suggest and vote",
            option_label: "Your option",

            autovote_note: "When suggesting an option you automatically vote for it",
        },
    },

    outputCases: {
        abstain: "abstained from voting",
        vote: "voted",
        changedVote: "changed vote",
        "against-all": "voted against all",
        conflict: "declared a conflict",
        question_closed_down: "The question was withdrawn",
        suggest_candidate: "suggested a candidate",
        suggest_option: "suggested an option",
        "question-launched": "The question was created",
        question_closed_candidates: "Voting is finished, selected",
        question_closed_options: "Voting is finished. The result is:",
    },

    feedback: {
        confirmationConfirmed: "Confirmation received from candidate: {name}",
        confirmationRetreated: "Candidate declined: {name}",
        confirmationDisclosure: "The candidate disclosed additional circumstances (see detail): {name}",
        changedVoice: "Changed vote",
        emptyMessage: "Enter a message",
        thxForVoting: "Thank you! Your vote was received",
        youVoted: "You voted",
        youAgainstAll: "You rejected all candidates",
        youAbstained: "You abstained",
        youConflicted: "You declared a conflict",
        youAddCandidate: "You suggested a candidate",
        youDeletedFromVoting: "You were removed from voting",
        optionalCandidates: "If you are against selecting a candidate as an arbitrator \nchoose \"dont vote\". \nIf you have a conflict of interest in regards to a candidate \nchoose \"conflict\".",
    },
    
    presidium: {
        question: {
            stage: {
                discussion: "Discussion",
                suggesting: "Candidates suggestion by the Board",
                voting: "Board voting",
                confirming: "Collecting confirmations and selecting the arbitrator",
                finishing: "Finalising results",
            }
        }
    }
}