import { Modal } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from "react";
import styled from '@emotion/styled';
import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { media } from '../../media';
import { Loader } from '../../../common/Loader';
import { Question } from '../../../api/types/main';
import { themeColor } from '../../../components/Theme';
import { CloseModalWindow } from '../Controls';
import { ModalWindowCaseSettings, SuggestCandidateConfig } from '../modal_window';
import { Title } from '../../questions/Questions.style';
import { useIntl } from 'react-intl';
import { TextareaComments, TextInput } from '../Controls';
import { ModalWindowButton } from '../../candidates/Candidate.style';
import { BoxWrapper } from './Vote/Vote.styles';

const useSuggestCandidate = ({ closeModalWindow, params }: Pick<ModalWindowCaseSettings, "closeModalWindow" | "params">) => {
    const [candidate, setCandidate] = useState<SuggestCandidateConfig>({
        fullname: "",
        phone: "",
        email: "",
        comment: "",
        loading: false
    });

    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const update = (type: string, text: string) => {
        setCandidate((prevState) => ({
            ...prevState,
            [type]: text
        }));
    }

    const validationNotification = (errorText: string, variant: "error" | "success") => {
        enqueueSnackbar(errorText, { variant: variant, autoHideDuration: 2000 });
    }

    const submit = () => {
        if(!candidate.fullname) {
            validationNotification(formatMessage({ id: "modalWindow.suggestCandidate.missing_fullname" }), "error");
            return;
        }

        if(!candidate.email) {
            validationNotification(formatMessage({ id: "modalWindow.suggestCandidate.missing_email" }), "error");
            return;
        }

        
        setCandidate((prevState) => ({ ...prevState, loading: true }));
        apiFetch<Question>(`/api/presidium-app/question/${params.id}/candidate`, FetchTypes.POST, {
            fullname: `${candidate.fullname}`,
            phone: `${candidate.phone}`,
            email: `${candidate.email}`,
            comment: candidate.comment,
        }).then(response => {
            params.setData(response);
            setCandidate((prevState) => ({ ...prevState, loading: false }));
            validationNotification(formatMessage({ id: "feedback.youAddCandidate" }), "success");
            closeModalWindow();
        }).catch((err) => {
            enqueueSnackbar(formatMessage({ id: "error.signupConfirmation.errorMessage" }), { variant: "error", autoHideDuration: 2000 });
        });
    }

    return {
        candidate,
        update,
        submit,
    }
}

export const SuggestCandidate = ({ closeModalWindow, params }: ModalWindowCaseSettings) => {
    const { formatMessage } = useIntl();
    
    const {
        candidate,
        update,
        submit,
    } = useSuggestCandidate({ params, closeModalWindow });

    

    return <Modal
        open={true}
        onClose={closeModalWindow}>
        <BoxWrapper2>
            <CloseModalWindow closeModalWindow={closeModalWindow} />
            <Form onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                submit();
            }}>
                <QuestionTitle>
                    {formatMessage({ id: "modalWindow.suggestCandidate.title" })}
                </QuestionTitle>

                <PlaceHolder>
                    <Prompt>
                        {formatMessage({ id: "modalWindow.suggestCandidate.writeFullName" })}
                        <RequiredField> *</RequiredField>
                    </Prompt>

                    <TextInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => update("fullname", e.target.value)}
                        placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeFullNamePlaceholder" })} />
                </PlaceHolder>

                <PlaceHolder>
                    <Prompt>
                        {formatMessage({ id: "modalWindow.suggestCandidate.writeEmail" })}
                        <RequiredField> *</RequiredField>
                    </Prompt>
                    
                    <TextInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => update("email", e.target.value)}
                        placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeEmailPlaceholder" })} />
                </PlaceHolder>

                <PlaceHolder>
                    <Prompt>
                        {formatMessage({ id: "modalWindow.suggestCandidate.writeTelNumber" })}
                    </Prompt>
                    <TextInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => update("phone", e.target.value)}
                        placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeTelNumberPlaceholder" })} />
                </PlaceHolder>

                <PlaceHolder>
                    {formatMessage({ id: "modalWindow.suggestCandidate.writeComments" })}
                    <TextareaComments
                        onChange={e => update("comment", e.target.value)}
                        aria-label="minimum height"
                        rows={3}
                        placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeCommentsPlaceholder" })} />
                </PlaceHolder>


                <ModalWindowButton type="submit" style={{ marginTop: 30 }}>
                    {candidate.loading ? <Loader type="button" /> : formatMessage({ id: "service.send" })}
                </ModalWindowButton>
            </Form>
        </BoxWrapper2>
    </Modal>
}


const RequiredField = styled.span`
    padding-left: 0.25em;
    color: ${themeColor(c => c.validation)};
`;

const Prompt = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const BoxWrapper2 = styled(BoxWrapper)`
    padding: 40px;
    width: auto;

    @media ${media.l} {
        width: 50%;
    }

    @media ${media.m} {
        padding: 30px;
        width: 60%;
    }

    @media ${media.s} {
        padding: 20px;
        width: 75%;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;

    @media ${media.xs} {
        margin: auto;
        width: 100%;
    }
`;

const QuestionTitle = styled(Title)`
    width: 100%;
    text-align: center;
`;

QuestionTitle.defaultProps = { margin: "0 0 10px 0", font: "500 20" }

const PlaceHolder = styled.label`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 400px;
    border: 0.5px solid ${themeColor(c => c.catskillWhite)};
    font: 300 20px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    border: none;
    outline: none;

    & input, textarea {
        font-weight: 300;
        font-size: 20px;
    }
    & ::placeholder {
        color: ${themeColor(c => c.textGreyBasic)};
    
        @media ${media.xs} {
            font-size: 15px;
        }
    }

    @media ${media.l} {
        margin-top: 10px;
        width: inherit;
    }

    @media ${media.m} {
        width: inherit;
    }

    @media ${media.s} {
        margin-top: 8px;
        font-size: 18px;
    }

    @media ${media.xs} {
        padding: 0;
        font-size: 16px;

        &::placeholder {
            padding-left: 10px;
        }
    }
`;
