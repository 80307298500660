import { Button, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../toolympus/components/primitives';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { useReturnUrl } from '../../toolympus/hooks/auth/useReturnUrl';
import { PublicPageHarness } from '../Common/PublicPageWrapper';

export const LoginContainer = () => {
    const returnUrl = useReturnUrl();
    const signupData = useLoginForm("/api/login", returnUrl.fromParam || "");
    return <PublicPageHarness>
        <form onSubmit={e => { e.preventDefault(); signupData.send(); }} className="form">
          <TextField
              key="email"
              autoFocus
              label={<FormattedMessage id="login.email"/>}
              name='email'
              variant='outlined'
              value={signupData.data.login}
              disabled={signupData.isSecondFactorRequested}
              onChange={e => signupData.update({ ...signupData.data, login: e.target.value || '' })} />
          {!signupData.isSecondFactorRequested && <TextField
              key="password"
              label={<FormattedMessage id="login.password"/>}
              name='password'
              type='password'
              variant='outlined'
              value={signupData.data.password}
              onChange={e => signupData.update({ ...signupData.data, password: e.target.value || '' })} />}
          {signupData.isSecondFactorRequested &&
              <TextField
                  key="code"
                  autoFocus
                  label={<FormattedMessage id="login.code"/>}
                  name='code'
                  type='code'
                  variant='outlined'
                  value={signupData.code}
                  helperText={<FormattedMessage id="login.code_hint"/>}
                  onChange={e => signupData.setCode(e.target.value || '')} />}
          <Button type='submit' variant='contained' color='primary'><FormattedMessage id="login.submit"/></Button>
          <Typography><Link to="/reset"><FormattedMessage id="general.forgot_password" /></Link></Typography>
        </form>
    </PublicPageHarness>;
}