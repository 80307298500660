import React from 'react';
import UserManagementForm from '../../toolympus/components/userManagement/UserManagementForm';

const Roles = [
    { name: 'Администратор', key: 'admin' },
    { name: 'Организатор', key: 'manager' },
    { name: 'Пользователь', key: 'user' },
]

export const UserManagementContainer = () => {
    return <UserManagementForm userRoles={Roles} allowInvites={true} />
}
