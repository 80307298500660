import { Modal } from '@material-ui/core';
import React, { useState } from "react";
import styled from '@emotion/styled';
import { dict } from '../../../../constants/system_reference';
import { apiFetch, FetchTypes } from '../../../../toolympus/api/core';
import { Feedback, ModalWindowBoxWrapper, ModalWindowButton, ModalWindowButtons } from '../../../candidates/Candidate.style';
import { media } from '../../../media';
import { Loader } from '../../../../common/Loader';
import { Question } from '../../../../api/types/main';
import { themeColor } from '../../../../components/Theme';
import { ModalWindowCaseSettingsExt, ModalWindowConfigByDefault } from '../../modal_window';
import { useIntl } from 'react-intl';
import { TextareaComments, CloseModalWindow } from '../../Controls';

export const RemoveFromVote = ({ closeModalWindow, params }: ModalWindowCaseSettingsExt) => {
    const { formatMessage } = useIntl();
    const [removeFromVoteConfig, setRemoveFromVoteConfig] = useState<ModalWindowConfigByDefault>({
        isVote: false,
        isLoading: false,
        comment: ""
    });

    const removeFromVote = () => {
        setRemoveFromVoteConfig((prevState) => ({ ...prevState, isLoading: true }));
        apiFetch<Question>(`/api/presidium-app/question/${params.id}/action/conflict`, FetchTypes.POST, {
            comment: removeFromVoteConfig.comment
        }).then(response => {
            params.setData(response);
            setRemoveFromVoteConfig((prevState) => ({ ...prevState, isVote: true, isLoading: false }));
            params.hideCandidateButtons(dict.button.conflict);
        });
    }

    return <Modal
        open={true}
        onClose={
            () => {
                if (params?.voteStatus?.isDiscuss) {
                    params.reject(dict.spl.isDiscuss);
                    params.redirect(dict.spl.isConflict, true);
                }
                closeModalWindow();
            }
        }>
        <ModalWindowBoxWrapper>
            <CloseModalWindow closeModalWindow={closeModalWindow} />
            <Title>
                {
                    removeFromVoteConfig.isVote
                        ? formatMessage({ id: "feedback.youConflicted" })
                        : formatMessage({ id: "modalWindow.removeFromVote" })
                }
            </Title>

            <TextareaComments
                rows={3}
                onChange={e => {
                    setRemoveFromVoteConfig((prevState) => ({
                        ...prevState,
                        comment: e.target.value,
                    }))
                }}
                placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeCommentsPlaceholder" })}
            />

            <ModalWindowButtons voted={removeFromVoteConfig.isVote}>

                <ModalWindowButton onClick={removeFromVote} isHidden={removeFromVoteConfig.isVote}>
                    {removeFromVoteConfig.isLoading ? <Loader type={"button"} /> : formatMessage({ id: "modalWindow.choice.accept" })}
                </ModalWindowButton>


                <ModalWindowButton onClick={() => {
                    params.reject(dict.spl.isDiscuss);
                    params.redirect(dict.spl.isConflict, true);
                    removeFromVoteConfig.isVote && closeModalWindow();
                }}>
                    {removeFromVoteConfig.isVote ? formatMessage({ id: "feedback.youDeletedFromVoting" }) : formatMessage({ id: "modalWindow.choice.reject" })}
                </ModalWindowButton>


            </ModalWindowButtons>

            <Feedback margin={"10px 0 0 0"} padding={"0"} isVisible={removeFromVoteConfig.isVote}>
                {formatMessage({ id: "feedback.thxForVoting" })}
            </Feedback>

        </ModalWindowBoxWrapper>
    </Modal>
}

const Title = styled.p`
    margin: auto;
    width: 80%;
    height: auto;
    min-height: 80px;
    padding-bottom: 10px;
    font: 500 22px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
    text-align: center;

    word-break: break-word;

    @media ${media.m} {
        margin-bottom: 2px;
        width: 100%;
    }

    @media ${media.s} {
        padding: 0;
        margin: 0;
        word-break: break-word;
    }
`;

