export const dict = {
    current: "current",
    past: "past",
    disclosure: "disclosure",

    tabs: {
        description: "description",
        discussion: "discussion",
        voices: "voices",
        results: "results"
    },

    chatOperations: {
        get_feed: "get-feed",
        add_message: "add-message"
    },

    button: {
        voting: "is_voted",
        suggestCandidate: "suggestCandidate_btn",
        discuss: "discuss_btn" ,
        abstain: "is_abstained" ,
        againstAll: "is_against_all",
        conflict: "is_conflict" ,
    },

    claimants: "claimants" ,
    respondants: "respondants",

    hx: {
        home: "h_home",
        votingQuestions: "h_votingQuestions",
    },

    results: {
        is_abstained: "is_abstained",
        is_against_all: "is_against_all",
        is_conflict: "is_conflict",
        is_voted: "is_voted"
    },

    spl: {
        confirmed: "confirmed",
        disclosure: "disclosure",
        retreated: "retreated",
        voting: "voting",
        pending_confirmation: "pending_confirmation",
        suggesting: "suggesting",
        finishing: "finishing",
        current: "current",
        past: "past",
        confirming: "confirming",
    
        isConflict: "isConflict",
        isDiscuss: "isDiscuss",
        to_vote: "to_vote",

        boxShadow: {        
            heavy: "0px 4px 6px",
            heavyUp: "0px -4px 6px",
            average: "0px 2px 4px",
            averageBottom: "0px -2px 4px",
            light: "0px 0px 4px",
            lightTop: "0px 1px 4px",
            ultraLight: "0px 0px 2px",
            ultraLightTop: "0px 1px 2px",
            x: "0px 2px 4px",
        }
    }
}
