import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { themeColor } from '../../components/Theme';
import { dict } from '../../constants/system_reference';
import { Title } from '../questions/Questions.style';
import { Remark } from '../../api/types/candidates';

export const CandidateRemark = ({ status }: { status: keyof Remark }) => {
    const { formatMessage } = useIntl();

    const getContent = (typeContent: keyof Remark) => (
        ({
            [dict.spl.suggesting]: {
                title: "",
                desc: formatMessage({ id: "question.candidatesDesc" })
            },
            [dict.spl.confirming]: {
                title: formatMessage({ id: "question.coordination" }),
                desc: formatMessage({ id: "question.coordinationDesc" })
            }
        } as Remark)[typeContent]
    )

    const content = getContent(status);
    return <Wrapper>
        <Title margin={"0 0 40px 0"} font={"500 26"}>{content?.title}</Title>
        <Note>
            {content?.desc}
        </Note>
    </Wrapper>
}

const Wrapper = styled.div`
    width: 80%;
    margin: 100px auto;
    text-align: center;
`;

const Note = styled.p`
    margin-top: auto;
    font: 300 1rem "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
`;