import { Modal } from '@material-ui/core';
import React from "react";
import styled from '@emotion/styled';
import { dict } from '../../../../constants/system_reference';
import { Feedback, ModalWindowBoxWrapper, ModalWindowButton, ModalWindowButtons } from '../../../candidates/Candidate.style';
import { media } from '../../../media';
import { themeColor } from '../../../../components/Theme';
import { CloseModalWindow } from '../../Controls';
import { ModalWindowCaseSettingsExt } from '../../modal_window';
import { useIntl } from 'react-intl';

export const Conflict = ({ closeModalWindow, takeConflict, params }: ModalWindowCaseSettingsExt) => {
    const { formatMessage } = useIntl();
    return <Modal
        open={true}
        onClose={closeModalWindow}>
        <ModalWindowBoxWrapper>
            <CloseModalWindow closeModalWindow={closeModalWindow} />
            <Title>{formatMessage({ id: "modalWindow.conflict" })}</Title>

            <ModalWindowButtons voted={true}>
                <ModalWindowButton onClick={() => {
                    params.accept(dict.spl.isDiscuss);
                    params.redirect(dict.spl.isConflict, false);
                }}>
                    {formatMessage({ id: "modalWindow.choice.accept" })}
                </ModalWindowButton>

                <ModalWindowButton onClick={() => {
                    takeConflict ? closeModalWindow() : params.redirect(dict.spl.isConflict, dict.spl.to_vote)
                }}>
                    {formatMessage({ id: "modalWindow.choice.reject" })}
                </ModalWindowButton>
            </ModalWindowButtons>

            <Feedback isVisible={false} margin={"10px 0 0 0"} padding={"0"}>
                {formatMessage({ id: "feedback.thxForVoting" })}
            </Feedback>
        </ModalWindowBoxWrapper>
    </Modal>
}

const Title = styled.p`
    margin: auto;
    width: 80%;
    height: auto;
    min-height: 80px;
    padding-bottom: 10px;
    font: 500 22px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
    text-align: center;

    word-break: break-word;

    @media ${media.m} {
        margin-bottom: 2px;
        width: 100%;
    }

    @media ${media.s} {
        padding: 0;
        margin: 0;
        word-break: break-word;
    }
`;

