import React, { ReactNode, useMemo } from 'react';
import { VoteConfig } from '../../modal_window';
import { CandidateBox2, CandidateName, CanidatesList2, CandidatePositionNbr, Warning2, UnvotedCandidatesParagraph } from './Vote.styles';
import { OccupyFreeSpace } from '../../../../toolympus/components/primitives';
import { useIntl } from 'react-intl';

interface Props {
  data: VoteConfig;
  getCandidateNameById: (candidateId: string) => ReactNode;
  candidateHasDisclosure: (candidateId: string) => boolean;
}

export const CandidateVotePreview = (props: Props) => {
  const { data, getCandidateNameById, candidateHasDisclosure } = props;

  const excludedCandidates = useMemo(() => {
    return data.candidates.filter(c => !data.candidatesOrder.includes(c) && !(data.candidatesConflict || []).includes(c))
  }, [data.candidates, data.candidatesOrder, data.candidatesConflict]);

  const { formatMessage } = useIntl();

  return (<>
    <CanidatesList2>
      {data.candidatesOrder.map((candidate,idx) => (
        <CandidateBox2 key={candidate}>
          <CandidatePositionNbr position={idx}>{idx+1}</CandidatePositionNbr>
          <CandidateName>
            {getCandidateNameById(candidate)}
          </CandidateName>
          {candidateHasDisclosure(candidate) && <Warning2 />}

          <OccupyFreeSpace />

        </CandidateBox2>
      ))}
    </CanidatesList2>
    {!!data.candidatesConflict?.length &&
      <UnvotedCandidatesParagraph>
        <span>{formatMessage({ id: "voting.candidates.options.conflict"})}:</span> {data.candidatesConflict.map(c => getCandidateNameById(c)).join(", ")}
      </UnvotedCandidatesParagraph>}
    
    {!!excludedCandidates.length &&
      <UnvotedCandidatesParagraph>
        <span>{formatMessage({ id: "voting.candidates.options.dontvote"})}:</span> {excludedCandidates.map(c => getCandidateNameById(c)).join(", ")}
      </UnvotedCandidatesParagraph>}
  </>);
}
