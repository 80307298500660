import { Question } from '../api/types/main';

// initial question
export const QuestionPageInstance : Question = {
    _id: "",
    question_type: "arbitration_jury_formation",
    is_closed: false,
    stages: [{
        code: "",
        label: "",
        end_date: "",
        is_finished: false,
        label_id: "",
        permissions: {}
    }],
    candidates: [{
        _id: "",
        lastname: "",
        city: "",
        middlename: "",
        firstname: "",
        area_site: [""],
        language:  [""],
        specialization_site: [""],
        info: "",
        country: "",
        document_id: "",
        confirmation_status: null,
        disclosure_document_id: null,
        disclosure_details: ""
    }],
    case_info: {
        casenbr: "",
        claimants: [
            {
                title: "",
                inn: ""
            }
        ],
        respondants: [
            {
                title: "",
                inn: ""
            }
        ],
        description: ""
    },
    comments: "",
    active_stage: {
        code: "",
        label: "",
        end_date: "",
        is_finished: false,
        label_id: "",
        permissions: {}
    },
    title: "",
    vote_end_date: "",
    start_date: "",
    end_date: "",
    is_candidates_question: true,
    documents: [
        {
            _id: "",
            owner_id: "",
            last_modified_datetime: "",
            meta: {
                updated: "",
                question_id: ""
            },
            contenttype: "",
            created_datetime: "",
            last_modified_by: "",
            filename: "",
            alias: null,
            kind: "",
            created_by: ""
        }
    ],
    is_finished: false,
    status: {
        is_conflict: false,
        is_abstained: false,
        is_against_all: false,
        is_voted: false,
        vote: [] as never
    },
    vote_start_date: "",
    positions: 2,
    responses: [],
    result: {
        candidates: []
    },
    related_questions: []
}
