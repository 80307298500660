import { useState } from "react";
import { apiUploadFile } from "../../toolympus/api/core";
import { FileInfo } from "../../toolympus/components/files";
import { MessageExtraData } from "./hook/useQuestionFeed";

export const ChatAttachmentFileApiPath = "/api/presidium-app/member-document";

export const useCreateAttachment = (sendMessage: (text: string, extra?: MessageExtraData) => void) => {
  const [activeFile, setActiveFile] = useState<FileInfo | null>(null);
  const saveFile = (file: File) => {
    return apiUploadFile(ChatAttachmentFileApiPath, "post", "file", file)
      .then(response => setActiveFile(response.data));
  }

  const close = () => setActiveFile(null);

  const complete = () => {
    if(activeFile) {
      sendMessage(activeFile.filename, { attachment_file_id: activeFile._id });
      close();
    }
  }

  return {
    saveFile,
    activeFile,
    cancel: close,
    complete,
  }
}
