import { Box, Button as btn } from '@material-ui/core';
import styled from '@emotion/styled';
import { Sizes, themeColor } from '../../components/Theme';
import { media } from '../media';

export const Button = styled(btn) <{ count?: number, isHighlighted?: boolean }>`
    width: 255px;
    padding: 13px 7px;
    font: 500 1rem "Ubuntu";
    color: ${props => themeColor(c => props.isHighlighted ? c.whiteDefault : c.darkRedDefault)};
    background-color: ${props => themeColor(c => props.isHighlighted ? c.darkRedDefault : c.white)};
    border: 2px solid ${themeColor(c => c.darkRedDefault)};
    border-radius: ${Sizes.geometry.borderRadius.button}px;
    outline: none;
    word-break: break-word;
    cursor: pointer;

    &:first-child {
        @media ${media.m} {
            margin: auto;
            width: ${props => props.count === 2 ? "90%" : "100%"};
            grid-column: ${props => props.count === 2 ? "auto" : "1 / 4"};
        }

        @media ${media.xs} {
            grid-column: none;
        }
    }

    &:nth-child(odd) {
        margin: 0 5px;

        &:hover {
            ${ props => props.isHighlighted && `color: ${ themeColor(c => c.darkRedDefault) };` } 
        }

        @media ${media.l} {
            margin: 20px 0;
        }

        @media ${media.m} {
            width: 100%;
            margin: ${props => props.count === 2 ? "20px auto auto auto;" : "10% auto auto auto"};
        }

        @media ${media.xs} {
            margin: 3% auto auto auto;
        }
    }

    &:nth-child(3) {    
        @media ${media.m} {
            width: 100%;
            margin: 20px auto auto auto;
        }

        @media ${media.xs} {
            margin: 3% auto auto auto;
        }
    }

    @media ${media.l} {
        width: 255px;
    }

    @media ${media.m} {
        width: 100%;
        font-size: 14px;
        margin: 20px auto auto auto;
    }
`;

export const ModalWindowButtons = styled.div<{ voted: boolean }>`
    margin: auto;
    display: flex;
    justify-content: center;
    justify-content: ${props => props.voted ? "center" : "space-between"};
    width: 100%;

    @media ${media.l} {
        margin: auto;
        flex-direction: row;
    }

    @media ${media.m} {
        margin: 20px 0 5px 0;

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media ${media.s} {
        width: 100%;
        margin: auto;
    }
`;

export const ModalWindowBoxWrapper = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    transform: translate(-50%, -50%);
    background-color: ${themeColor(c => c.whiteDefault)};
    padding: 30px;
    word-break: break-word;
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;

    @media ${media.l} {
        width: 50%;
    }

    @media ${media.m} {
        width: 50%;
    }

    @media ${media.s} {
        padding: 20px;
        width: 80%;
        word-break: break-word;
    }
`;

export const Feedback = styled.p<{ 
    margin?: string, 
    padding?: string, 
    isVisible?: boolean 
}>`
    margin: ${ props => props.margin || "10px 0 15px 0"};
    padding: ${ props => props.padding || "0" };
    height: auto;
    text-align: center;
    color: ${themeColor(c => c.textGreyBasic)};
    font: 300 17px "Ubuntu";
    visibility: ${props => props.isVisible ? "visible" : "hidden"};

    @media ${media.s} {
        font-size: 15px;
    }
`;

export const ModalWindowButton = styled(btn) <{ 
    isHidden?: boolean, 
    isVoted?: boolean, 
    isNotReady?: boolean, 
    isTransparent?: boolean,
    isDeleted?: boolean
}>`
    display: ${props => props.isHidden ? "none" : "block"};
    width: ${props => props.isHidden ? "0px" : "100%"};
    padding: ${props => props.isHidden ? "0px" : "13px 7px"};
    
    font: 500 90% "Ubuntu";
    background-color: ${ props => props.disabled ? themeColor(c => c.textGreyBasic) : props.isTransparent ? "transparent" : themeColor(c => c.darkRedDefault) };
    color: ${props => themeColor(c => props.isHidden || !props.isTransparent ? c.whiteDefault : c.darkRedDefault)};
    border: ${ props => props.isTransparent ? `1px solid ${themeColor(c => c.darkRedDefault)}` : "transparent" };
    border-radius: ${Sizes.geometry.borderRadius.button}px;
    outline: none;
    cursor: ${props => props.isNotReady ? "not-allowed" : "pointer"};
    word-break: break-word;
    height: ${props => props.isHidden ? "0px" : "auto"};

    &:hover {
        background-color: ${themeColor(c => c.darkRedDefault)};
        color: ${themeColor(c => c.whiteDefault)};
    }

    @media ${media.m} {
        width: 100%;
        margin: 2px 0;
    }

    &:not(:last-child) {
        margin-right: ${props => props.isHidden ? 0 : "20px"};
        @media ${media.l} {
            height: ${props => props.isHidden ? "0px" : "auto"};
        }

        @media ${media.m} {
            margin-right: 0;
        }
    }

    &:nth-child(2) {
        width: ${props => props.isVoted && "218px"};
        padding: ${props => props.isVoted && "13px 7px"};
        color: ${props => props.isVoted && themeColor(c => c.whiteDefault)};
        word-break: ${props => props.isVoted && "break-word"};

        @media ${media.l} {
            width: ${props => props.isVoted && "49%"};
        }

        @media ${media.m} {
            width: ${props => props.isVoted && "100%"};
        }
    }
`;

