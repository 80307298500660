import React from "react";
import styled from '@emotion/styled';
import { EntityText } from '../Questions.style';
import { FormattedMessage } from 'react-intl';
import { useFormats } from "../../../toolympus/components/schemed";

export interface QuestionCaseVoteProps {
    start_date: string;
    end_date: string;
    type: "voting" | "discussion";
}

export const QuestionVoteDates = ({ type, start_date, end_date } : QuestionCaseVoteProps) => {
    const { formatDate } = useFormats();

    return <Wrapper>

         <Line>
            <EntityText>
                <FormattedMessage id={type === "discussion" ? "voting.discussion_start" : "voting.start"} />
            </EntityText>
            
            <EntityText>
                {formatDate(start_date) || <FormattedMessage id="addition.unknown" />}
            </EntityText>
        </Line>

        <Line>
            <EntityText>
                <FormattedMessage id={type === "discussion" ? "voting.discussion_end" : "voting.end"} />
            </EntityText>
            
            <EntityText>
                {formatDate(end_date) || <FormattedMessage id="addition.unknown" />}
            </EntityText>
        </Line>

    </Wrapper>
}

const Wrapper = styled.div`
    margin-top: 30px;
    width: 100%;
`;

const Line = styled.div`
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;