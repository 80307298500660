const mediaMaxWidth = (width: number) => {
    return `(max-width: ${width}px)`;
}

export const media = {
    tablet: `(max-width: 1090px) and (min-width: 480px)`,
    l: mediaMaxWidth(768),
    m: mediaMaxWidth(600),
    s: mediaMaxWidth(480),
    xs: mediaMaxWidth(320)
}
