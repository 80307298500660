import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingIndicator } from '../../toolympus/components/primitives';
import { useQuery } from '../../toolympus/hooks/useQueryParamsState';
import { login, useUser } from '../../toolympus/userContext/UserContext';

export const EmployeeLoginToQuestionPage = () => {
    const { id } = useParams<{ id?: string }>();
    const token = useQuery().get("token");
    const history = useHistory();

    const user = useUser();

    useEffect(() => {
        if(id && token) {
            login(user, token);
            setTimeout(() => history.push(`/question/${id}`), 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, token]);
    return (
        <div>
            <p>{!id && "Question ID missing"}</p>
            <p>{!token && "Auth token missing"}</p>
            {id && token && <LoadingIndicator />}
        </div>
    );
}
