import React from 'react';
import styled from '@emotion/styled';
import { QuestionItemPast } from './QuestionFiltered/QuestionPast';
import { isQuestionActionDueSoon, QuestionArrow, QuestionItemCurrent } from './QuestionFiltered/QuestionCurrent';
import { Title } from './Questions.style';
import { dict } from '../../constants/system_reference';
import { Loader } from '../../common/Loader';
import { Sizes, themeColor } from '../../components/Theme';
import { media } from '../media';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { QuestionListData } from './hook/useQuestionList';
import { Question } from '../../api/types/main';

const Questions = ({ question, isCurrent }: { question: Question, isCurrent: boolean }) => <>
  {
    isCurrent
      ? <QuestionItemCurrent key={question._id} question={question} />
      : <QuestionItemPast key={question._id} question={question} />
  }
</>

export const QuestionsList = ({ data }: { data: QuestionListData }): React.ReactElement => {
  return <>
    {data.isLoading && <Loader type={'default'} />}
    {!data.isLoading && (
        <QuestionsWrapper>
            {data.questions.map(question => {
            return (
                <QuestionWrapper
                issuggesting={`${isQuestionActionDueSoon(question)}`}
                aria-label={`${question.title.replaceAll('/', ' ')} ${question.active_stage?.label}`}
                key={question._id}
                to={`/question/${question._id}`}
                >
                <Questions question = {question} isCurrent = {data.searchText.length > 0 ? !question.is_finished : data.view === dict.current} />
                </QuestionWrapper>
            );
            })}
        </QuestionsWrapper>
    )}
    {!data.isLoading && data.questions.length === 0 && (
        <Title margin={'0 0 30px 0'} font={'500 20'}>
            <FormattedMessage id={data.searchText.length > 0 ? "service.no_questions_found" : "service.no_questions"} />
        </Title>
    )}
  </>;
};

const QuestionsWrapper = styled.div`
    width: 100%;
    margin-bottom: 75px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;

    @media ${media.l} {
        grid-template-columns: 1fr;
    }
`;

const QuestionWrapper = styled(Link) <{ issuggesting: string }>`
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;  
    box-shadow: 
      ${dict.spl.boxShadow.average} ${(props) =>
    `${themeColor(c => props.issuggesting === "true"
      ? `${c.burntUmberShadow}`
      : `${c.blackShadow}`)}, ${dict.spl.boxShadow.ultraLight} ${themeColor(c => props.issuggesting
        ? `${c.burntUmberShadow}`
        : `${c.blackShadow}`)}`};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    cursor: pointer;
    text-decoration: none;

    & ${QuestionArrow} > img {
      opacity: 0.85;
    }
    &:hover {
      & ${QuestionArrow} > img {
        opacity: 1;
      }
    }

    @media ${media.m} {
        margin: auto;
    }
`;
