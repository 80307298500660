import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, } from '@material-ui/pickers';
import { SwitchableLocalizationProvider } from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { RACAMessages } from './messages';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
                <SwitchableLocalizationProvider defaultLocale='ru' customMessagesMultiLocale messages={RACAMessages} localStorageKey="__racap_lang">
                    <FormatsProvider formats={{ date: "D MMMM YYYY", shortDatetime: "D MMMM HH:mm" }}>
                        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                {props.children}
                            </MuiPickersUtilsProvider>
                        </SnackbarProvider>
                    </FormatsProvider>
                </SwitchableLocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
