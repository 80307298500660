import styled from '@emotion/styled';
import React, { useState } from 'react';
import arrow_down from "../../../events/Pages/common_icons/arrow_down_red.svg";
import { useIntl } from 'react-intl';
import { Sizes, themeColor } from '../../../components/Theme';
import { media } from '../../media';
import { Link } from 'react-router-dom';
import { useFormats } from '../../../toolympus/components/schemed';
import { Question } from '../../../api/types/main';
import { dict } from '../../../constants/system_reference';

export const QuestionOtherCases = ({ otherQuestions }: { otherQuestions: Question[] }) => {
    const [isHidden, setIsHidden] = useState(false);
    const { formatMessage } = useIntl();
    const { formatDate } = useFormats();

    const isQuestionActive = (q: Question) => q.active_stage && !q.active_stage.is_finished;

    return otherQuestions?.length > 0 ?
        <Wrapper isActive={!isHidden}>
            <ShowHideButton onClick={() => { setIsHidden(!isHidden); }} role="button">
                {formatMessage({ id: "service.showOtherCases" })}
                <Collapse isHidden={isHidden} src={arrow_down} alt="" />
            </ShowHideButton>

            {!isHidden && <Content>
                {otherQuestions.map((item: any) =>
                    <RelatedQuestion to = {`/question/${item._id}`}>
                        <Title>{item.title}</Title>
                        {isQuestionActive(item) && <Status>{item.active_stage?.label || ""}</Status>}
                        {isQuestionActive(item) && <Date key="stage_end_date">{formatMessage({ id: "service.endDate" })}: {formatDate(item.active_stage?.end_date)}</Date>}
                        {!isQuestionActive(item) && <Date key="start_date">{formatMessage({ id: "service.start" })}: {formatDate(item.start_date)}</Date>}
                        {!isQuestionActive(item) && <Date key="end_date">{formatMessage({ id: "service.end" })}: {formatDate(item.end_date)}</Date>}
                    </RelatedQuestion>
                )}
            </Content>}
        </Wrapper>
        :
        <></>
}

const Wrapper = styled.div<{ isActive?: boolean }>`
    margin: 50px auto 100px auto;
    width: 80%;
    max-height: ${props => props.isActive ? "1000px" : 0};
    transition: all ease 0.5s;

    @media ${media.l} {
        width: 90%;
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: minmax(calc(50% - 40px), 588px) minmax(calc(50% - 40px), 588px);
    gap: 40px;

    @media ${media.l} {
        grid-template-columns: 1fr;
    }
`;

const RelatedQuestion = styled(Link)`
    padding: 20px;
    background-color: ${themeColor(c => c.whiteDefault)};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    box-shadow: ${dict.spl.boxShadow.light} ${themeColor(c => c.mineShaftShadow)}, ${dict.spl.boxShadow.lightTop} ${themeColor(c => c.mineShaftShadowLighter)};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    text-decoration: none;
    display: flex;
    flex-flow: column;

    & > :not(:last-child) {
        margin: 0 0 10px;
    }
`;

const Title = styled.span`
    color: ${themeColor(c => c.blackDefault)};
    font-size: 20px;
    font-weight: 500;
`;

const Status = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: ${themeColor(c => c.blackDefault)};
`;

const Date = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: ${themeColor(c => c.textGreyBasic)};
`;

const Collapse = styled.img<{ isHidden: boolean }>`
    margin-left: 4px;
    vertical-align: middle;
    transform: rotate(${props => props.isHidden ? "180deg" : "0deg"});
`;

const ShowHideButton = styled.div`
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: 400;
    color: ${themeColor(c => c.darkRedDefault)};
    cursor: pointer;
`;