import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CandidateQuestion } from "../../../../api/types/candidates";
import { Question } from "../../../../api/types/main";
import { dict } from "../../../../constants/system_reference";
import { apiFetch, downloadFile, FetchTypes } from "../../../../toolympus/api/core";
import { useLocale } from "../../../../toolympus/components/localization";
import { getCandidateName } from "../../../candidates/CandidatePerson";
import { ModalWindowCaseSettingsExt, VoteConfig } from "../../modal_window";
import { CandidateVote } from "../../../../api/types/questions";

export const useVoteState = ({ params, closeModalWindow }: ModalWindowCaseSettingsExt) => {
    const { formatMessage } = useIntl();
    const { locale } = useLocale();

    const candidatePositionOptions = useMemo(() => {
      return [
        ...params.candidates.map((c,i) => [`${i}`, `${i+1}`]),
        ["dontvote", formatMessage({ id: "voting.candidates.options.dontvote"})],
        ["conflict", formatMessage({ id: "voting.candidates.options.conflict"})],
      ];
    }, [params.candidates, formatMessage]);

    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [candidatePositions,setCandidatePositions] = useState<Record<string,string>>({});

    const [voteConfig, setVoteConfig] = useState<VoteConfig>({
        isVote: !!params.data.status.vote,
        candidates: params.candidates.map(c => c._id),
        candidatesChoose: params.candidates.map(c => c._id),
        candidatesOrder: params.data.status.vote ? params.data.status.vote : [...params.candidates.map(() => "")],
        candidatesConflict: params.data.status.candidates_conflict || undefined,
        comment: "",
        
        showCandidates: false,
        isCallingCandidate: { id: -1, fetch: false },
        
        isFetching: false,
    });

    const hasSelectedSomething = !!Object.entries(candidatePositions).find(([c,position]) => position !== null && position !== "dontvote");
    const canGoToPreview = !!Object.entries(candidatePositions).find(([c,position]) => !isNaN(+position)) && (params.candidates || []).every(c => !!candidatePositions[c._id]);

    const goToPreview = () => {
      const candidatesOrder: (string|null)[] = params.candidates.map((c,i) => null);
      const candidatesConflict: string[] = [];
      Object.entries(candidatePositions).forEach(([candidate,position]) => {
        const posIdx = +position;
        if(!isNaN(posIdx)) {
          candidatesOrder[posIdx] = candidate;
        } else if(position === "conflict") {
          candidatesConflict.push(candidate);
        }
      });
      setVoteConfig(x => {
        const res: VoteConfig = {
          ...x,
          candidatesOrder: candidatesOrder.filter(x => !!x) as string[],
        };
        delete res.candidatesConflict;
        if(candidatesConflict.length) {
          res.candidatesConflict = candidatesConflict;
        }
        return res;
      });
      setIsPreview(true);
    }

    const hasSelectedCandidates = voteConfig.candidatesOrder.filter(cnd => !!cnd).length >= 1;

    //   TODO - LOOKS DUMB - REMOVE ????
    useEffect(() => {
    if(voteConfig.isVote) {
        setVoteConfig((prevState) => ({
            ...prevState,
            isVote: true
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voteConfig.isVote, voteConfig.isFetching]);


    const castVote = () => {
        voteConfig.isVote && closeModalWindow();
        if (!voteConfig.isVote && hasSelectedCandidates) {
          setVoteConfig((prevState) => ({ ...prevState, isFetching: true }));

          const data: CandidateVote = {
            candidates: voteConfig.candidatesOrder
              .map(cnd => params.candidates.find(c => c._id === cnd))
              .map(candidate => candidate === undefined ? null : candidate._id),
            comment: voteConfig.comment,
          };
          if(voteConfig.candidatesConflict) {
            data.candidates_conflict = voteConfig.candidatesConflict;
          }
    
          apiFetch<Question>(`/api/presidium-app/question/${params.id}/action/vote`, FetchTypes.PUT, data).then(response => {
            params.setData(response);
            setIsPreview(false);
            setCandidatePositions({});
            setVoteConfig((prevState) => ({
              ...prevState,
              isVote: true,
              isSubmitted: true,
              isFetching: false,
              candidatesOrder: voteConfig.candidatesOrder.filter(cnd => !!cnd),
              comment: ""
            }));
            params.hideCandidateButtons(dict.button.voting);
            params.disableVoting();
          });
        }
      }

    const startRevote = () => {
        setVoteConfig((prevState) => ({
            ...prevState,
            isVote: false,
            candidatesOrder: [...params.candidates.map(() => "")],
            candidates: params.candidates.map(c => c._id)
          }));
    }

    const chooseCandidate = (position: number, candidate: string) => {
        const remainingCandidatesAfterChoose = voteConfig.candidatesOrder.map(
            (empty: string, id: number) => id === position ? candidate : empty);
        
        const deleteCandidate = () => voteConfig.candidatesOrder
            .map(cnd => cnd === candidate ? "" : cnd)
            .map((existing: string, idx: number) => idx === position ? candidate : existing);

        setVoteConfig((prevState) => ({
          ...prevState,
          candidates: voteConfig.candidates.filter(currentCandidate => currentCandidate !== candidate),
          candidatesOrder: voteConfig.candidatesOrder.includes(candidate) ? deleteCandidate() : remainingCandidatesAfterChoose,
        }));
    }

    const chooseCandidate2 = (candidate: string, position: string) => {
      setCandidatePositions(x => {
        const result = { ...x, [candidate]: position };
        if(!isNaN(+position)) {
          Object.entries(x).forEach(([c,pos]) => {
            if(pos === position) {
              delete result[c];
            }
          });
        }
        return result;
      });
    }

    const startPickingCandidate = (position: number) => {
        setVoteConfig((prevState) => (
            {
              ...prevState,
              showCandidates: true,
              isCallingCandidate: {
                id: !prevState.isCallingCandidate.fetch === false ? -1 : position,
                fetch: !prevState.isCallingCandidate.fetch,
              },
            }
          ))
    }

    const showCandidates = () => {
        setVoteConfig((prevState) => ({
            ...prevState,
            showCandidates: true,
        }));
    }

    const hideCandidates = () => {
        setVoteConfig((prevState) => ({
            ...prevState,
            showCandidates: false,
            isCallingCandidate: {
              id: -1,
              fetch: false
            }
          }));
    }

    const clearCandidates = () => {
        if(!voteConfig.isVote && hasSelectedCandidates) {
            setVoteConfig((prevState) => ({
                ...prevState,
                candidates: params.data.candidates.map(c => c._id),
                candidatesOrder: [...params.candidates.map(() => "")]
            }));
           setCandidatePositions({});
        }
    }

    const updateComment = (comment: string) => {
        setVoteConfig((prevState) => ({
            ...prevState,
            comment,
        }));
    }

    const downloadDisclosureDocument = (candidate: CandidateQuestion) => {
        downloadFile(`/api/presidium-app/question/${params.id}/document/${candidate.disclosure_document_id}`, "");
    }

    const candidateHasDisclosure = (candidateId: string) => {
        return !!params.candidates.find(c => c._id === candidateId && c.confirmation_status === dict.disclosure);
    }

    const isSelectedCandidate = (candidateId: string) => !!candidateId;

    const getCandidateNameById = (candidateId: string) => {
        const candidate = !!candidateId && params.candidates.find(c => c._id === candidateId);
        return candidate
            ? getCandidateName(locale, candidate)
            : formatMessage({ id: "service.chooseFromList" });
    }

    return {
        voteConfig,

        castVote,
        hasSelectedCandidates,
        startRevote,
        chooseCandidate,
        clearCandidates,
        
        updateComment,
        
        startPickingCandidate,
        showCandidates,
        hideCandidates,
        
        downloadDisclosureDocument,
        
        candidateHasDisclosure,
        isSelectedCandidate,
        getCandidateNameById,
        
        candidatePositionOptions,
        candidatePositions,
        chooseCandidate2,

        isPreview,
        hasSelectedSomething,
        canGoToPreview,
        goToPreview,
        cancelPreview: () => setIsPreview(false),
    }
}
