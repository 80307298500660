import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TableCellProps } from '@material-ui/core';
import { FieldSchema, FieldType, Schema } from '../../hooks/useSchema';
import { MaybeFieldDefinition, FieldDefinition, OnFieldChange, fieldLabel } from './Common';
import { Link } from '../primitives/Common.styles';
import { TableDisplay } from './TableDisplay';
import { TableEditor } from './TableEditor';
import { EmptyState } from '../primitives/EmptyState';
import { FieldSorting } from '../../hooks/useFieldSorting';
import { useListAutoexpander } from '../primitives/useListAutoexpander';

export type FieldElementRenderer<RowType> = ((row: RowType, schema?: FieldSchema, original?: ReactNode) => (JSX.Element | string)) | null | undefined;

export const NewTabLinkProps = { target: "_blank", rel: "noreferrer noopener" };

export interface TableProps<RowType> {
    fields: MaybeFieldDefinition[];
    data: RowType[];
    schema: Schema;
    fieldLink?: (field: string) => ((row: RowType) => string) | null | undefined;
    fieldLinkNewTab?: boolean;
    fieldElement?: (field: string) => FieldElementRenderer<RowType>;
    fieldHeader?: ((field: string) => ReactNode | null | undefined) | null | undefined;
    onRowClick?: (row: RowType) => void;
    onDblClick?: (row: RowType) => void;
    selected?: (row: RowType) => boolean;
    rowButtons?: (row: RowType) => ReactNode;
    rowStyle?: (row: RowType) => React.CSSProperties | undefined;
    emptyState?: ReactNode;
    emptyStateText?: string | ReactNode;
    emptyStateClick?: () => void;
    onChange?: OnFieldChange;

    getRowId?: (row: RowType) => any;
    editByOneRow?: boolean;

    sorting?: FieldSorting;
    autoexpander?: {
        initialRows: number;
        increment: number;
    };
    tailAnchor?: ReactNode;

    dense?: boolean;
    withRowNbr?: boolean;
}

const StyledTableCell = styled(TableCell)<{ isCheckbox?: boolean}>`
    & > * {
        min-width: 60%;
        ${props => props.isCheckbox ? 'justify-content: center;' : ''}
    }

    & > .form-control > .MuiFormControl-root {
        margin-top: 0;
        margin-bottom: 0;

        & > .MuiInput-root {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

interface RowProps<RowType> extends Omit<TableProps<RowType>, "data"> {
    row: RowType;
    idx: number;
    editedRowId: any | null;
    setEditedRowId: (v: any) => void;
}

const getAlign = (field: string, schema: Schema, config?: any): TableCellProps['align'] | undefined => {
    const fromConfig = config?.align as TableCellProps['align'] || undefined;
    if(fromConfig) {
        return fromConfig;
    } else {
        const type = schema[field]?.type;
        switch(type) {
            case FieldType.bool:
                return 'center';
            case FieldType.number:
            case FieldType.decimal:
                return 'right';
            default:
                return undefined;
        }
    }
}

const RowUnmemo = <RowType, >(props: RowProps<RowType>) => {
    const { 
        fields,
        schema,
        onRowClick,
        onDblClick,
        selected,
        rowButtons,
        rowStyle,
        fieldLink,
        fieldElement,
        editByOneRow,

        row,
        editedRowId,
        setEditedRowId,
        withRowNbr,
        idx,
    } = props;

    const getRowId = props.getRowId || (r => (r as any)._id);
    const getFieldLink = fieldLink || (() => () => null);
    const getElement = fieldElement || (() => () => null);

    const onChange = props.onChange || (() => { });

    return (
        <TableRow
            key={getRowId(row)}
            onClick={onRowClick ? () => onRowClick(row) : undefined}
            onDoubleClick={onDblClick ? () => onDblClick(row) : (
                editByOneRow ? () => setEditedRowId(getRowId(row)) : undefined
            )}
            selected={selected ? selected(row) : false}
            style={rowStyle ? rowStyle(row) : undefined}
            >
            {withRowNbr && <TableCell key="rownbr">{idx+1}.</TableCell>}
            {(fields.filter(fd => !!fd) as FieldDefinition[]).map(([field, config]) => {
                const link = (getFieldLink(field) || (() => null))(row);
                const valueDisplay = (config as any)?.editable && (!editByOneRow || editedRowId === getRowId(row))
                    ? <TableEditor row={row} field={field} schema={schema[field]} onChange={onChange} />
                    : <TableDisplay row={row} field={field} schema={schema[field]} config={config} />;
                const element = (config?.element || getElement(field) || (() => null))(row, schema[field], valueDisplay);

                let cellContent = null;

                if(link) {
                    cellContent = <Link to={link} {...(props.fieldLinkNewTab ? NewTabLinkProps : {})}>{valueDisplay}</Link>;
                } else if(element) {
                    cellContent = element;
                } else {
                    cellContent = valueDisplay;
                }
                
                const {width, cellStyle } = config || {};
                const style = { ...cellStyle, width };

                return <StyledTableCell
                        style={style}
                        isCheckbox={schema[field]?.type === FieldType.bool}
                        key={field}
                        align={getAlign(field, schema, config)}>
                    {cellContent}
                    </StyledTableCell>
            })}
            {rowButtons && (
                <TableCell key="buttons" align="right">
                    {rowButtons(row)}
                </TableCell>
            )}
        </TableRow>
    );
};

const Row = React.memo(RowUnmemo) as typeof RowUnmemo;

export const TableForFields = <RowType, >(props: TableProps<RowType>) => {
    const { 
        fields,
        schema,
        rowButtons,
        fieldHeader,
        dense,
        withRowNbr,
    } = props;

    const {
        data,
        emptyStateText,
        emptyStateClick,
        sorting,
        autoexpander: autoexpanderConfig,
        ...rowRelevantProps
    } = props;

    const autoexpander = useListAutoexpander(autoexpanderConfig ? data : [], autoexpanderConfig?.initialRows || 20, autoexpanderConfig?.increment || 20);
    
    const [editedRowId, setEditedRowId] = useState<any>(null);
    const emptyState = props.emptyState || <EmptyState text={emptyStateText} onClick={emptyStateClick} />;
    const getRowId = props.getRowId || (r => (r as any)._id);

    const visibledata = autoexpanderConfig ? autoexpander.visibleItems : data;

    return (
        <TableContainer>
            <Table size={dense ? "small" : undefined}>
                <TableHead onClick={() => setEditedRowId(null)}>
                    <TableRow>
                        {withRowNbr && <TableCell key="rownbr" />}
                        {(fields.filter(fd => !!fd) as FieldDefinition[]).map(([field, config]) => (
                            <TableCell
                                key={field}
                                sortDirection={sorting?.sort?.field === field ? sorting.sort.direction : undefined}
                                align={getAlign(field, schema, config)}>
                                  {sorting && sorting.fieldSortAllowed(field)
                                    ? (
                                      <TableSortLabel
                                        disabled={!sorting || !sorting.fieldSortAllowed(field)}
                                        active={sorting?.sort?.field === field}
                                        direction={sorting?.sort?.direction}
                                        onClick={(sorting && sorting.fieldSortAllowed(field)) ? () => sorting.setNextSort(field) : undefined}
                                        >
                                        {(fieldHeader && fieldHeader(field)) || config?.label || fieldLabel(schema[field], field)}
                                    </TableSortLabel>)
                                    : (fieldHeader && fieldHeader(field)) || config?.label || fieldLabel(schema[field], field)}
                            </TableCell>))}
                        {rowButtons && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibledata.map((row,idx) => (
                        <Row
                            key={getRowId(row)}
                            idx={idx}
                            {...rowRelevantProps}
                            row={row}
                            editedRowId={editedRowId}
                            setEditedRowId={setEditedRowId}
                            withRowNbr={withRowNbr} />))}
                </TableBody>
            </Table>
            {autoexpanderConfig && autoexpander.anchor}
            {props.tailAnchor}
            {(!visibledata || visibledata.length === 0) && emptyState}
        </TableContainer>);
}
