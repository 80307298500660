import styled from '@emotion/styled';
import React from "react";
import { NavLink } from 'react-router-dom';
import { Sizes, themeColor } from '../../components/Theme';
import { media } from '../media';
import { Time } from '../questions/Questions.style';
import { FeedEvent } from '../../api/types/main';
import { useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';
import ArrowBack from '../../events/Pages/header_icons/arrow_back.svg';
import warning from "../Pages/common_icons/warning.svg";
import { dict } from '../../constants/system_reference';


const EventItem = styled.article`
    margin: 20px auto;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${themeColor(c => c.whiteDefault)};
    box-shadow: ${dict.spl.boxShadow.ultraLightTop} ${themeColor(c => c.blackShadow)};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    white-space: pre-line;

    @media ${media.l} {
        width: 90%;
    }
`;

const CaseInfo = styled.div`
    display: flex;
    justify-content: space-between;

    & span, a {
        font-weight: 400;
        font-size: 14px;
        color: ${themeColor(c => c.textGreyBasic)};

        &:last-child {
            cursor: pointer;
            text-decoration: none;
        }
    }

    @media ${media.xs} {
        flex-direction: column;
    }
`;


const Arrow = styled.span`
    text-decoration: none;
    display: inline-block;
    mask: url(${ArrowBack});
    transform: scaleX(-1);
    mask-size: cover;
    width: 14px;
    height: 14px;
    background: ${themeColor(c => c.textGreyBasic)};
    flex-shrink: 0;
`;

const Description = styled.div`
    margin: 15px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: ${themeColor(c => c.blackDefault)};

    @media ${media.s} {
      flex-flow: column;
      align-items: flex-start;
    }
`;

const EventTime = styled(Time)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    font-size: 14px;
    white-space: nowrap;
    margin-left: 3rem;
`;

const Warning = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: ${themeColor(c => c.burntUmber)};
`;

const WarningIcon = styled.span`
    text-decoration: none;
    display: inline-block;
    mask: url(${warning});
    mask-size: cover;
    width: 22px;
    height: 22px;
    background: ${themeColor(c => c.darkRedDefault)};
    flex-shrink: 0;
    margin-right: 5px;
    box-sizing: border-box;
`;

export const NewsEventItem = (props: FeedEvent) => {
    const { formatDatetimeShort } = useFormats();
    return <EventItem>
        <CaseInfo>
            <span>{props.question_title}</span>
            <NavLink to={`/question/${props.question_id}`}><Arrow /></NavLink>
        </CaseInfo>

        <Description>
            {props.isWarning
                ? <Warning><WarningIcon /> {props.display_text}</Warning>
                : props.display_text}

            <EventTime>
                {formatDatetimeShort(utc.toLocal(props.time))}
            </EventTime>
        </Description>

    </EventItem>
}
