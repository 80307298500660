import { useState } from "react";
import { Question } from "../../../api/types/main";
import { PresidiumMember } from "../../../api/types/questions";
import { apiFetch } from "../../../toolympus/api/core";
import { useDialogState } from "../../../toolympus/components/primitives";
import { useAuthorizedUser } from "../../../useContext/AuthorizedUserContext";

const useValueWithUpdate = <T,>(initial: T, update: (v: T) => Promise<T>) => {
    const [value, setValue] = useState<T>(initial);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const makeUpdate = (v: T) => {
        const oldVal = value;
        setValue(v);
        setIsUpdating(true);
        return update(v)
            .then(newVal => {
                setValue(newVal);
                setIsUpdating(false);
                return newVal;
            })
            .catch(err => {
                setIsUpdating(false);
                setValue(oldVal);
                throw err;
            });
    }

    return {
        value,
        update: makeUpdate,
        isUpdating,
    }

}

export const useNotificationSettings = (question: Question) => {
    const dialog = useDialogState();

    const profile = useAuthorizedUser();

    const profileNotificationsMode = useValueWithUpdate(
        profile.notifications_mode,
        (v: string) => {
            return apiFetch<PresidiumMember>(`/api/presidium-app/me`, "put", { notifications_mode: v })
                .then(updatedProfile => {
                    profile.reload();
                    return updatedProfile.notifications_mode;
                })
        }
    );

    const profileLanguage = useValueWithUpdate(
        profile.language,
        (v: string) => {
            return apiFetch<PresidiumMember>(`/api/presidium-app/me`, "put", { language: v })
                .then(updatedProfile => {
                    profile.reload();
                    return updatedProfile.language;
                })
        }
    );

    const questionNotificationsMode = useValueWithUpdate(
        question.notifications_mode || profile.notifications_mode,
        (v: string) => {
            return apiFetch<Question>(`/api/presidium-app/question/${question._id}/settings`, "put", { notifications_mode: v })
                .then(updatedQuestion => {
                    return updatedQuestion.notifications_mode || profile.notifications_mode;
                })
        }
    );

    return {
        dialog,

        profileNotificationsMode,
        profileLanguage,
        questionNotificationsMode,
    }
}

export type NotificationSettings = ReturnType<typeof useNotificationSettings>;
