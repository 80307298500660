import { CMSRecord } from "../toolympus/components/CMS";
import { useLocale } from "../toolympus/components/localization";
import { useLoadedData } from "../toolympus/hooks/useLoadedData";

export const useDocumentationPage = (slug: string) => {
  const { locale } = useLocale();
  return useLoadedData<Pick<CMSRecord, "content" | "_id" | "slug">>(
    `/api/cms/public/board_help/${slug}?lang=${locale}`,
    { _id: -1, });
}
