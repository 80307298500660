import { Button } from '@material-ui/core';
import React from "react";
import styled from '@emotion/styled';
import { dict } from '../constants/system_reference';
import download_file from "../events/Pages/common_icons/download_file.svg";
import { downloadFile } from '../toolympus/api/files';
import { FileQuestion } from '../api/types/questions';
import { themeColor } from '../components/Theme';
import { useIntl } from 'react-intl';

export const DownloadFile = ({file, questionId} : { questionId: string, file: FileQuestion }) => {
    const { formatMessage } = useIntl();

    return <DownloadButton
                title={file.filename}
                onClick={() => downloadFile(`/api/presidium-app/question/${questionId}/document/${file._id}`, file.filename)}>
        <img src={ download_file } alt="" />
        <Info>
            <Title>{file.filename}</Title>
            <Download>{formatMessage({ id: "service.download" })}</Download>
        </Info>
    </DownloadButton>
}

const DownloadButton = styled(Button)`
    box-sizing: content-box;
    padding: 5px;
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${themeColor(c => c.alabaster)};
    box-shadow: ${dict.spl.boxShadow.light} ${themeColor(c => c.downloadFileShadow)}, ${dict.spl.boxShadow.heavy} ${themeColor(c => c.downloadFileShadow)};
`;

const Info = styled.div`
    margin-left: 8px;
    width: 70%;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const Download = styled.button`
    padding: 0;
    text-align: left;
    font: 400 14px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    background: none;
    border: none;
    cursor: pointer;
`;

const Title = styled.span`
    margin: 0;
    font: 300 14px "Ubuntu";
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;