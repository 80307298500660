import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { QuestionCaseInfo } from '../../../api/types/questions';
import { themeColor } from '../../../components/Theme';

export const QuestionCase = ({ case_info }: { case_info: QuestionCaseInfo }) => {
    const { formatMessage } = useIntl();
    return <>
        <TitleCase>
            <Text margin={"30px auto 0"} font = {"300 20px"} color = {themeColor(c => c.textGreyBasic)}>
                {formatMessage({ id: "question.caseNumber" })}
            </Text>

            <Text margin = {"6px auto 0"} font = {"400 26px"} color = {themeColor(c => c.blackDefault)}>
                {case_info.casenbr}
            </Text>
        </TitleCase>
    </>
}

const Text = styled.p<{ margin: string, font: string, color: string }>`
    margin: ${ props => props.margin };
    font: ${ props => props.font } "Ubuntu";
    color: ${ props => props.color };
`;

const TitleCase = styled.div`
    margin-bottom: 30px;
    text-align: center;
`;