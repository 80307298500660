import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { HistoryWrapper, Wrapper } from '../events/Pages/QuestionPage';
import { History } from '../common/History';
import { useDocumentationPage } from './useDocumentationPage';
import { media } from '../events/media';
import { DocumentationViewer } from './DocumentationViewer';
import { ColorsTheme } from '../components/Theme';

interface Props {
  
}

const Content = styled.div`
  margin: auto;
  width: 80%;
  white-space: pre-line;

  @media ${media.l} {
      padding: 0 1rem;
      box-sizing: border-box;
      width: 100%;
  }

  font-weight: 400;
  & h1, & h2, & h3, & h4, & h5 {
    font-weight: 500;
  }
`;

const XLoader = styled.div`
  margin: auto;
  width: 80%;
  min-height: 30svh;
  border-radius: 36px;
  background: ${ColorsTheme.modalBackground};

  @media ${media.l} {
      padding: 0 1rem;
      box-sizing: border-box;
      width: 100%;
  }

  position: relative;
  overflow: hidden;

  @keyframes slideuistubshine {
    0% {transform:translateX(-100%);}
    50% {transform:translateX(100%);}
    100% {transform:translateX(100%);}
  }

  &:after {
    content:'';
    top:0;
    transform:translateX(100%);
    width:100%;
    height:120%;
    position: absolute;
    z-index:1;
    animation: slideuistubshine 2s infinite;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  }
`;

export const DocumentationPage = (props: Props) => {
  const { slug } = useParams<{ slug: string }>();
  const { data: page, isLoading } = useDocumentationPage(slug);
  const { formatMessage } = useIntl();
  
  return (
    <Wrapper>
      <HistoryWrapper>
          <History path={[
              { path: "/", link: "/", t: formatMessage({ id: "history.home" }) },
              { path: "/documentation/home", link: "/documentation/home", t: formatMessage({ id: "history.documentation" }), isClick: slug === "home" ? false : true },
          ]} />
      </HistoryWrapper>
      
      {isLoading
          ? <XLoader />
          : <Content>
            <DocumentationViewer content={page.content} key={`${page._id}`} />
          </Content>
      }
    </Wrapper>
  );
}
