import { useEffect } from 'react';
import { useState } from 'react';
import { Question, SearchSettings } from '../../../api/types/main';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';

export type QuestionListData = {
    questions: Question[];
    updateText: (searchText: string) => void;
    searchText: string;
    isLoading: boolean;
    view: "current" | "past";
    setView: (button: "current" | "past") => void;
}

export const useQuestionList: () => QuestionListData = () => {
    const [searchConfig, setSearchConfig] = useState<SearchSettings>({
        searchText: "",
        searchTextApplied: "",
    });

    const [view, setView] = useState<"current" | "past">("current");
    const { data, isLoading } = useLoadedData(`/api/presidium-app/question?${ searchConfig.searchTextApplied.length === 0 ? `view=${view}` : `search=${searchConfig.searchTextApplied}` }`, []);

    const updateText = (searchText: string) => {
        setSearchConfig((prevState) => ({
            ...prevState,
            searchText,
        }));
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchConfig((prevState) => ({
                ...prevState,
                searchTextApplied: searchConfig.searchText,
            }));
        }, 500);

        return () => clearTimeout(timer);
    }, [searchConfig.searchText, isLoading, data, searchConfig.searchTextApplied]);

    return {
        questions: data,
        updateText,
        searchText: searchConfig.searchText,
        isLoading,
        view,
        setView,
    }
}