import React from 'react';
import styled from '@emotion/styled';
import { History } from '../../common/History';
import { themeColor } from '../../components/Theme';
import { media } from '../media';
import { useIntl } from 'react-intl';
import { News } from '../news/News';
import { history } from '../../constants/history_reference';

export const NewsPage = () => {
    const { formatMessage } = useIntl();
    return <Wrapper>
        <Content>
            <History path={[
                history.home(formatMessage({ id: "history.home" })),
                history.news(formatMessage({ id: "history.news" }))
            ]} />
            <News />
        </Content>
    </Wrapper>
};

const Content = styled.div`
    width: 728px;
    margin: 0 auto;

    @media ${media.l} {
        width: 100%;
    }

    @media ${media.m} {
        margin: 0 auto 40px auto;
    }
`;

const Wrapper = styled.div`
    padding-top: 10px;
    background-color: ${themeColor(c => c.searchBackground)};
    min-height: 80vh;
`;