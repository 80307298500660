import React from "react";
import styled from '@emotion/styled';
import { CandidatePerson } from './CandidatePerson';
import { CandidateQuestion } from '../../api/types/candidates';
import { themeColor } from '../../components/Theme';
import { Title } from '../questions/Questions.style';
import { FormattedMessage } from 'react-intl';
import { dict } from "../../constants/system_reference";


const Wrapper = styled.div`
    padding: 50px 0;
    margin-top: 100px;
    text-align: left;
    background-color: ${themeColor(c => c.searchBackground)};
    box-shadow: ${dict.spl.boxShadow.average} inset ${themeColor(c => c.blackShadow)}, ${dict.spl.boxShadow.averageBottom} inset ${themeColor(c => c.blackShadow)};
`;

const ContentTitle = styled(Title)`
    text-align: center;
`;

const Remark = styled.p`
    text-align: center;
`;

export const Candidates = ({ questionId, candidates }: { candidates: CandidateQuestion[], questionId: string }) => {
    return <Wrapper>
        <ContentTitle margin={"0 0 30px 0"} color={"black"} font={"400 26"}>
            <FormattedMessage id="question.candidates" />
        </ContentTitle>
        {candidates.length
            ? candidates.map(candidate => <CandidatePerson key={candidate._id} candidate={candidate} questionId={questionId} />)
            : <Remark><FormattedMessage id="addition.empty" /></Remark>}
    </Wrapper>
}
