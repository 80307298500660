import styled from '@emotion/styled';
import React from "react";
import { useIntl } from 'react-intl';
import { QuestionTab } from '../../../api/types/questions';
import { themeColor } from '../../../components/Theme';
import { dict } from '../../../constants/system_reference';
import { media } from '../../media';

interface Props {
    questionTab: string;
    changeQuestionTab: (x : QuestionTab) => void;
    noResults?: boolean;
};

export const QuestionTabs = ({ questionTab, changeQuestionTab, noResults }: Props) => {
    const { formatMessage } = useIntl();

    const tabs = [
        {
            link: dict.tabs.description,
            t: formatMessage({ id: "tabs.description" })
        },

        {
            link: dict.tabs.discussion,
            t: formatMessage({ id: "tabs.discussion" })
        },

        noResults ? null : {
            link: dict.tabs.results,
            t: formatMessage({ id: "tabs.results" })
        },
    ].filter(t => !!t) as { link: string, t: string }[];
    
    return <TabsWrapper widthFactor={tabs.length}>
        {tabs.map(({ link, t }, id : number) =>
            <TabTitle key={id} onClick={() => changeQuestionTab(link as QuestionTab)} isactive={link === questionTab}>
                {t}
            </TabTitle>)
        }
    </TabsWrapper>
}

export const TabsWrapper = styled.div<{ widthFactor?: number, showOnSmall?: boolean }>`
    width: ${props => (props.widthFactor || 3) * 147}px;
    margin: 25px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${themeColor(c => c.textGreyBasic)};

    @media ${media.m} {
        display: ${props => props.showOnSmall ? "flex" : "none"};
    }
`;

export const TabTitle = styled.span<{ isactive: boolean }>`
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    color: ${props => themeColor(c => props.isactive ? c.blackDefault : c.textGreyBasic)};
    border-bottom: 3px solid ${props => props.isactive ? themeColor(c => c.darkRedDefault) : "transparent"};
    cursor: pointer;

    @media ${media.m} {
        font-size: 16px;
    }
`;