export const racaRuMessages = {
    general: {
        forgot_password: "Забыли пароль?",
        reset_password_hint: "Если вы забыли пароль, его можно восстановить с помощью этой формы. Для начала введите свой E-mail.",
        board: "Президиум",
    },
    notifications: {
        settings_dialog_title: "Настройки E-mail уведомлений",
        notifications_mode_section: "Отправлять E-mail уведомления",
        profile_notifications_mode: "по будущим вопросам",
        question_notifications_mode: "по этому вопросу",
        language: "Язык уведомлений",
        notifications_language: {
            ru: "Русский",
            en: "English",
        },
        notifications_mode: {
            all: "Вся активность",
            "only-important-events": "Важные события, без уведомлений из обсуждения",
        },
    },
    question: {
        claimantsTitle: "Название",
        claimants: "Истцы",
        respondants: "Ответчики",
        inn: "ИНН",
        caseNumber: "Номер дела",
        candidates: "Кандидаты",
        candidatesDesc: "Вы можете предложить кандидата до начала голосования",
        coordination: "Согласование",
        coordinationDesc: "Дождитесь завершения предварительного согласования кандидатов",
    }, 

    results: {
        abstain: "Воздержался",
        against_all: "Против всех",
        conflict: "Конфликт",
        vote: "Проголосовал",
        caseNumber: "№",
        lnsCandidate: "ФИО кандидата",
        option: "Вариант",
        points: "Результат",
        voices: "Голоса",
        results: "Результаты",

        score_candidates_explanation: `Результат кандидата определяется как среднее номера позиции, выбранной всеми членами президиума для этого кандидата.
        
Чем ниже значение, тем более приоритетной считается кандидатура арбитра.`,
        score_options_explanation: `Результат варианта определяется как число членов президиума, проголосовавших за него.
        
Чем выше результат, тем более предпочтительным считается соответствующий вариант.`,
    },

    tabs: {
        description: "Описание",
        discussion: "Обсуждение",
        results: "Результаты",
        voices: "Голоса",
    },

    button: {
        voting: "Проголосовать",
        changeVote: "Изменить голос",
        suggestCandidate: "Предложить кандидата", 
        suggestCandidateDes: "Необходимо предложить кандидата или нажать «Воздержаться» до завершения текущего этапа",
        discuss: "Обсудить",
        abstain: "Воздержаться",
        againstAll: "Против всех",
        conflict: "Конфликт",
    },

    voting: {
        start: "Начало голосования",
        end: "Окончание голосования",
        discussion_start: "Начало обсуждения",
        discussion_end: "Окончание обсуждения",
        confirm: "Подтвердить",

        candidates: {
          options: {
            empty: "...",
            dontvote: "не выбирать",
            conflict: "конфликт",
          },
        },
    },

    candidate: {
        langs: "Языки",
        specialization: "Специализация",
        sphere: "Сфера",
    },

    service: {
        chooseFromList: "Выберите из списка",
        download: "скачать",
        downloadDescription: "Скачать справку",
        downloadDisclosureDescription: "Скачать документ",
        description: "Описание",
        descriptionFull: "© 2016-2022 The Russian Arbitration Center at the Autonomous Non-profit Organisation «Russian Institute of Modern Arbitration»",
        copyright: "© 2016-{YEAR} РАЦ",
        logout: "Выйти",
        more: "Подробнее",
        collapse: "Свернуть",
        leftToEnd: "Времени осталось",
        send: "Отправить",
        back: "Назад",
        d: "дн",
        hr: "ч",
        mn: "мин",
        stages: "Этапы",
        start: "Начало",
        end:  "Окончание",
        endDate: "Дата окончания",
        past: "Прошедшие",
        current: "Текущие",
        circumstances: "Раскрыты дополнительные обстоятельства",
        revote: "Изменить голос",
        none: "Не указано",
        clean: "очистить",
        hide: "Скрыть",
        showOtherCases: "Другие вопросы по делу",
        dash: "-",
        no_questions: "Нет вопросов для голосования",
        no_questions_found: "Вопросов не найдено",
    },

    history: {  
        home: "Главная",
        votingQuestions: "Вопросы для голосования",
        news: "События",
        caseTitle: "Дело",
        documentation: "Документация",
    },

    addition: {
        notSimilarQuestions: "Не существует похожих вопросов",
        questions: "вопросы",
        question: "Вопрос",
        votingDate: "Дата голосования",
        suggestBefore: "Предложите до",
        voteBefore: "Проголосуйте до",
        freeForActive: "Получаем согласие кандидатов",
        finishing: "Подводим итоги",
        search: "Поиск",
        close: "Закрыть",
        
        writeFullName: "Введите ФИО",
        writeTelNumber: "Введите телефон",
        writeEmail: "Введите почту",
        
        logoTitle: "Российский арбитражный центр",
        currentLang: "Текущий язык: Русский",
        messanger: "Чат",
        
        loader: "Подождите, идет загрузка",
        showDescription: "Раскрыть описание",
        downloadFile: "Скачать файл",
    
        emptyContent: "-",
        unknown: "Неизвестно",
        caseTitle: "Дело",
        empty: "-",
        toDescription: "К описанию",
        toQuestion: "Перейти к вопросу",
        news: "События",

        unknownUser: "Кто-то",
        emptyCandidates: "Кандидатов нет"
    },

    chat: {
        no_messages: "Сообщений пока нет",
        message_placeholder: "Введите сообщение",
        message_empty: "Введите сообщение",
        send_file_to_chat: "Отправить файл в обсуждение?",
    },

    information: {
        contacts: {
            contact: "Контакты",
            address: "119017, Москва, Кадашевская набережная, д.14, к.3",
            mail: "info@centerarbitr.ru",
            phone: "7 495 797 94 77", 
            phonePlaceholder: "+74957979477" 
        },
        
        menu: {
            main: "Главная",
            name: "Сайт РАЦ",
            contacts: "Контакты",
            results: "Результаты",
            chat: "Чат",
            regulations: "Регламент"
        },

        blockVote: {
            conflict: 
            ` 
                Вы указали, что у вас конфликт интересов, \nголосование для вас недоступно.\n
    
                Если у вас нет конфликта интересов,\n пожалуйста, обратитесь в административный аппарат.
            `,
    
            abstain:
            `
                Вы воздержались, \nголосование для вас недоступно.\n
    
                Если вы не воздержались,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
    
            againstAll:
            `
                Вы проголосовали против всех, \nголосование для вас недоступно.\n
    
                Если вы ошиблись,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            voting:
            `
                Вы уже проголосовали, \nголосование для вас недоступно.
    
                Если у вы ошиблись,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            suggestCandidate:
            `
                Вы уже указали кандидата, \nголосование для вас недоступно.
    
                Если вы хотите изменить свой выбор,\n пожалуйста, обратитесь\n в административный аппарат.
            `,
    
            default:
            `
                Вы не можете голосовать
            `
        }
    },

    modalWindow: {
        abstain: "Вы уверены, что хотите воздержаться от голосования?",
        conflict: "У вас есть конфликт интересов?",
        removeFromVote: "Снять меня с голосования",
        againstAll: "Вы уверены, что хотите проголосовать против всех?",
        voting: "Расположите кандидатов в порядке приоритетности",
        voting_options_title: "Выберите вариант",

        suggestCandidate: {
            title: "Предложить кандидата",

            writeFullName: "ФИО кандидата", 
            writeFullNamePlaceholder: "Иванова Мария Семеновна",
            writeTelNumber: "Телефон кандидата", 
            writeTelNumberPlaceholder: "8 999 888 7766",
            writeEmail: "E-mail кандидата", 
            writeEmailPlaceholder: "maria.ivanova@firm.com",
            writeComments: "Комментарии",
            writeCommentsPlaceholder: "...",

            missing_fullname: "Заполните имя",
            missing_email: "Заполните E-mail",
        },

            
        choice: {
            accept: "Да",
            reject: "Нет",
            vote: "Проголосовать",
        },

        successModalWindow: "Вы уже проголосовали",

        suggest_option: {
            title: "Предложить другой вариант",
            existing_options: "Существующие варианты:",
            suggest_and_vote: "Предложить и проголосовать",
            option_label: "Ваш вариант",

            autovote_note: "Предлагая вариант вы автоматически голосуете за него",
        },
    },

    outputCases: {
        abstain: "воздержался от голосования",
        vote: "проголосовал",
        changedVote: "изменил голос",
        "against-all": "проголосовал против всех",
        conflict: "объявил о конфликте",
        question_closed_down: "Вопрос снят с голосования",
        suggest_candidate: "предложил кандидата",
        suggest_option: "предложил вариант",
        "question-launched": "Вопрос создан",
        question_closed_candidates: "Голосование завершено, выбран",
        question_closed_options: "Голосование завершено. Результат:",
    },

    feedback: {
        confirmationConfirmed: "Получено согласие от кандидата: {name}",
        confirmationRetreated: "Кандидат отказался: {name}",
        confirmationDisclosure: "Кандидат раскрыл дополнительные обстоятельства: {name}",
        changedVoice: "Изменил голос",
        emptyMessage: "Введите сообщение",
        thxForVoting: "Спасибо! Ваш голос принят",
        youVoted: "Вы проголосовали",
        youAgainstAll: "Вы проголосовали против всех",
        youAbstained: "Вы воздержались",
        youConflicted: "У вас конфликт",
        youAddCandidate: "Вы предложили кандидата",
        youDeletedFromVoting: "Вы были удалены с голосования",
        optionalCandidates: "Если Вы против выбора конкретного кандидата, \nнажмите \"не выбирать\". \nПри наличии конфликта интересов в отношении \nконкретного кандидата выберите \"конфликт\".",
    },
    
    presidium: {
        question: {
            stage: {
                discussion: "Обсуждение",
                suggesting: "Предложение кандидатур Президиумом",
                voting: "Голосование Президиума",
                confirming: "Получение согласия и назначение арбитра",
                finishing: "Подведение итогов",
            }
        }
    }
}