// get color name from here -> https://chir.ag/projects/name-that-color
const colorsBase = {
    mineShaft: "#2B2B2B",
    mineShaftShade: {
      s1: "#2B2B2B80",
      s2: "#2B2B2B60",
      s3: "#2B2B2B40",
    },
    mineShaftShadow: "#2B2B2B18",
    mineShaftShadowLighter: "#2B2B2B10",
    graySolid: "#808080",
    burntUmber: "#932A2E",
    burntUmberShade: {
      s0: "#932A2EFF",
      s1: "#932A2ECC",
      s2: "#932A2E99",
      s3: "#932A2E66",
      s4: "#932A2E33",
      s5: "#932A2E11",
    },
    burntUmberShadow: "#932A2E1A",
    burntUmberShadowLighter: "#932A2E0A",
    tundora: "#4D4D4D",
    white: "#FFFFFF",
    whiteShadow: "#FFFFFF1A",
    silver: "rgba(196,196,196, 0.11)",
    silver2: "rgba(196,196,226, 0.16)",
    black: "#000000E0",
    blackShadow: "#0000001A",
    blackApprox: "#050404",
    wellRead: "#B53333",
    bizarre: "#EEDDDE",
    alabast: "#FBFBFB",
    alabaster: "#F9F9FC",
    gallery: "#EAEAEA",
    steelBlue: "#5D83BF",
    chambray: "#365C89",
    chambrayShadow: "#365C891A",
    catskillWhite: "#E1E9F0",
    alto: "#d2d2d2",
    mercury: "#e1e1e1",
    emperor: "#525252",
    emperorShadow: "#5252520D",
    alizarinCrimson: "#e21e26",
    fairPink: "#FFEDEE",
    ebony: "#0F1828"
}

const colorsSemantic = {
    footerBackground: colorsBase.mineShaft,
    textGreyBasic: colorsBase.graySolid,
    darkRedDefault: colorsBase.burntUmber,
    darkRedShade: colorsBase.burntUmberShade,
    borderBasic: colorsBase.tundora,
    whiteDefault: colorsBase.white,
    searchBackground: colorsBase.silver2,
    blackDefault: colorsBase.black,
    questionStatus: colorsBase.blackApprox,
    warning: colorsBase.wellRead,
    arrowColor: colorsBase.bizarre,
    question_case: {
        line: colorsBase.gallery,
        descriptionBackground: colorsBase.alabaster
    },
    buttonBlueText: colorsBase.steelBlue,
    candidatesBackground: colorsBase.alabast,
    disableArrow: colorsBase.alabaster,
    downloadFile: colorsBase.emperor,
    downloadFileShadow: colorsBase.emperorShadow,
    validation: colorsBase.alizarinCrimson,
    modalBackground: colorsBase.alabaster,
    modalCardBackground: colorsBase.gallery,
}

export const ColorsTheme = { ...colorsBase, ...colorsSemantic };
export const themeColor = (c : (x: typeof ColorsTheme) => string) => c(ColorsTheme);

export const Sizes = {
  geometry: {
    borderRadius: {
      button: 4,
      pannel: 8,
    },
  },
}