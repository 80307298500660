import React from "react";
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { clearApiToken } from '../toolympus/api/core';
import { Footer } from '../events/Pages/components/Footer';
import { HeaderMenu } from '../events/Pages/components/HeaderMenu';
import { css } from "@emotion/react";
import { themeColor } from "./Theme";

export const BasePage : React.FunctionComponent<React.ReactNode> = ({children}) => {
  const history = useHistory();

  const logout = () => {
    clearApiToken();
    history.push('/login');
  }

  return <Wrapper>
      <HeaderMenu logout={logout} />
      <Content>
        {children}
      </Content>
      <Footer logout={logout} />
    </Wrapper>
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    overflow-y: hidden;
`;


export const ScrollbarStyle = css`
    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${themeColor(c => c.searchBackground)};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${themeColor(c => c.darkRedDefault)};
        border-right: 4px solid transparent;
        background-clip: padding-box;
    }
`;

const Content = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;

    ${ScrollbarStyle}
`;
