import React, { useEffect, useMemo, useState } from 'react';

export const DevMode_LS_Key = "_rp_dev_mode";

export const useIsDevMode = () => {
  const isDevMode = useMemo(() => localStorage.getItem(DevMode_LS_Key) === "t", []);
  return isDevMode;
}

export const DevModeSwitchPage = () => {
  const [isDevModeOn,setIsDevModeOn] = useState<boolean>(false);
  useEffect(() => {
    const wasOn = localStorage.getItem(DevMode_LS_Key) === "t";
    localStorage.setItem(DevMode_LS_Key, wasOn ? "f" : "t");
    setIsDevModeOn(!wasOn);
  }, []);

  return (
    <div>
      Dev mode is switched <strong>{isDevModeOn ? "ON" : "OFF"}</strong>
    </div>
  );
}
