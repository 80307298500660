import moment, { Duration, duration } from 'moment';
import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { themeColor } from '../../components/Theme';
import { dict } from '../../constants/system_reference';
import { media } from '../media';
import { utc } from '../../toolympus/components/timezone';

export const getTimeLeft = (deadline: string) => {
    // the deadline comes as timeless date and is assumed to be MSK midnight of the deadline date
    // The actual deadline is the midnight of the next day
    // Thus we need to adjust it for MSK (utc midnight-3h) and add 24h to arrive at the next midnight
    // + 24 - 3 = +21
    const targetX = utc.toLocal(deadline).add(21, "hours");
    const current = moment();
    return duration(targetX.diff(current));
}

const dateDiffStr = (diff: Duration) => `${diff.asDays().toFixed(0).padStart(2, "0")}${diff.hours().toString().padStart(2, "0")}${diff.minutes().toString().padStart(2, "0")}`

export const QuestionDeadline = ({ date }: { date: string }) => {
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(date));

    useEffect(() => {
        const interval = setInterval(() =>
            setTimeLeft(getTimeLeft(date))
            , 60_000);
        return () => clearInterval(interval);
    }, [setTimeLeft, date]);

    const timeLeftStr = dateDiffStr(timeLeft);

    return timeLeft.asMilliseconds() > 0 ? <Wrapper>
        <Warning><FormattedMessage id="service.leftToEnd" /></Warning>
        <EndTime>
            {
                date === null
                    ? <FormattedMessage id="addition.unknown" />
                    : <>
                        <Time>{timeLeftStr[0]}</Time>
                        <Time>{timeLeftStr[1]}</Time>
                        <Type><FormattedMessage id="service.d" /></Type>

                        <Time>{timeLeftStr[2]}</Time>
                        <Time>{timeLeftStr[3]}</Time>
                        <Type><FormattedMessage id="service.hr" /></Type>

                        <Time>{timeLeftStr[4]}</Time>
                        <Time>{timeLeftStr[5]}</Time>
                        <Type><FormattedMessage id="service.mn" /></Type>
                    </>
            }

        </EndTime>
    </Wrapper>
    :
    <></>;
}

const Wrapper = styled.div`
    padding: 20px 25px;
    width: 289px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 30px auto 50px auto;

    background: ${themeColor(c => c.question_case.descriptionBackground)};
    box-shadow: ${dict.spl.boxShadow.light} ${themeColor(c => c.mineShaftShadow)}, ${dict.spl.boxShadow.heavy} ${themeColor(c => c.mineShaftShadowLighter)};

    @media ${media.m} {
        padding: 30px 0;
        width: 70%;
    }
`;

const Type = styled.p`
    margin: 0 10px 0 7px;
    font: 300 20px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
`;

const Warning = styled.div`
    margin-bottom: 10px;
    text-align: center;
    font: 300 20px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
`;

const EndTime = styled.div`
    padding: 0.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${media.m} {
        width: fit-content;
        margin: auto;
    }

    @media ${media.xs} {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
`;

const Time = styled.p`
    margin: 0 0.3rem;
    font: 500 20px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
    background-color: ${themeColor(c => c.whiteDefault)};
`;
