import { useState } from "react";
import { Question } from "../../../../api/types/main"
import { apiFetch } from "../../../../toolympus/api/core";

interface Config {
    question: Question;
    setQuestion: (x: Question) => void;
    onSuggested: () => void;
}

interface State {
    option: string;
    comment: string;
}

export const useSuggestOption = ({ question, setQuestion, onSuggested }: Config) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const [state, setState] = useState<State>({
        option: "",
        comment: question.status?.comment || "",
    });

    const canSuggest = state.option.trim().length > 0
        && !isLoading;

    const suggest = () => {
        if(canSuggest) {
            setIsLoading(true);
            apiFetch<Question>(`/api/presidium-app/question/${question._id}/option`, "post", {
                option: { label: state.option },
                comment: state.comment,
            })
            .then(result => {
                setIsLoading(false);
                setIsSubmitted(true);
                setQuestion(result);
                onSuggested();
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
        }
    }

    return {
        isLoading,
        state,
        update: (changes: Partial<State>) => setState(s => ({ ...s, ...changes })),
        canSuggest,
        suggest,
        isSubmitted,
    }
}
