import { createMuiTheme } from '@material-ui/core/styles';
import { Sizes } from './components/Theme';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#932A2E',
        },
        secondary: {
            main: '#279dd5',
        }
    },
    shape: {
      borderRadius: Sizes.geometry.borderRadius.pannel,
    },
    typography: {
        fontFamily: "Ubuntu",
        button: {
            textTransform: 'none',
            fontFamily: "Ubuntu",
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: Sizes.geometry.borderRadius.button,
            },
        }
    }
});
