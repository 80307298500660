import React from "react";
import styled from '@emotion/styled';
import { media } from '../../events/media';

import StageActiveImg from './cases/stage_active.svg';
import StageInactiveImg from './cases/stage_inactive.svg';
import StageStubImg from './cases/stage_stub.svg';
import moment from 'moment';
import { QuestionStage } from '../../api/types/candidates';
import { themeColor } from '../Theme';
import { FormattedMessage } from 'react-intl';

export interface StagesProps {
    active_stage: QuestionStage,
    stages: QuestionStage[]
}

const StageStub: QuestionStage = {
    code: "confirming",
    is_finished: true,
    label: " ",
    label_id: " ",
    end_date: "2022-12-31",
    permissions: {},
    stub: true,
}

const addStubStages = (stagesInitial: QuestionStage[], activeStage: QuestionStage): QuestionStage[] => {
    return stagesInitial.length === 1
        ? [
            StageStub,
            ...stagesInitial,
            StageStub,
        ]
        : stagesInitial;
}

export const Stages = (props: StagesProps) => {
    const { active_stage, stages: stagesInitial } = props;

    const stages = addStubStages(stagesInitial, active_stage);

    const activeStageIdx = stages.findIndex(s => s.code === active_stage?.code) || 0;
    const formatDate = (date: string) => date ? moment(date).format("DD.MM") : "";

    const getStageActivity = (stage: QuestionStage, idx: number): "past" | "near" | "far" => (
        idx <= activeStageIdx
            ? "past"
            : idx === activeStageIdx + 1
                ? stage.stub && active_stage.is_finished ? "past" : "near"
                : "far"
    );

    return <Wrapper>
        <Title><FormattedMessage id="service.stages" /></Title>

        <Timeline numberOfStages={stages.length}>
            {stages.map(({ stub, code, end_date }: QuestionStage, idx: number) => (
                <LabelBase key={`stage_${code}_date_${idx}`} isActive={code === active_stage?.code}>
                    {stub ? "" : formatDate(end_date)}
                </LabelBase>))}
            
            {stages.map((s,idx) => (
                <TimelineSectionImg
                    key={`stage_${s.code}_img_${idx}`}
                    alt=" "
                    src={s.stub ? StageStubImg : s.code === active_stage?.code ? StageActiveImg :StageInactiveImg}
                    activity={getStageActivity(s, idx)}
                    />))}
            
            {stages.map((stage, idx) =>
                <LabelBase key={`stage_${stage.code}_title_${idx}`} isActive={stage.code === active_stage?.code}>
                    <FormattedMessage id={stage.label_id} />
                </LabelBase>)}
        </Timeline>


        <TimelineMobile numberOfStages={stages.length}>
            {stages.map((stage, idx) => {
                const { stub, code, end_date, label_id } = stage;
                return (
                    <React.Fragment key={`${code}_${idx}`}>
                        <LabelBase key={`stage_${code}_date`} isActive={code === active_stage?.code}>
                            {stub ? "" : formatDate(end_date)}
                        </LabelBase>
                        <TimelineSectionImg
                            key={code}
                            alt=" "
                            src={stub ? StageStubImg : code === active_stage?.code ? StageActiveImg :StageInactiveImg}
                            activity={getStageActivity(stage, idx)}
                            />
                        <LabelBase key={`stage_${code}_title`} isActive={code === active_stage?.code}>
                            <FormattedMessage id={label_id} />
                        </LabelBase>
                    </React.Fragment>
            )})}
        </TimelineMobile>

    </Wrapper>
}


const Wrapper = styled.div`
    margin-top: 100px;
`;


const Title = styled.p`
    margin: 0 0 10px 0;
    text-align: center;
    font: 400 26px "Ubuntu";
`;



const LabelBase = styled.p<{ isActive: boolean }>`
    margin: 0.5rem 0;
    font-size: 1rem;
    color: ${props => themeColor(c => props.isActive ? c.darkRedDefault : c.blackDefault)};
    font-weight: ${props => props.isActive ? 400 : 300};
    word-break: break-word;
    text-align: center;

    @media ${media.l} {
        text-align: left;
        justify-self: start;
    }
    @media ${media.xs} {
        font-size: 12px;
    }

`;

const StageActivityStatusToMask = {
    past: "linear-gradient(90deg, #000000, #000000 100%)",
    near: "linear-gradient(90deg, #000000, #00000060 50%)",
    far: "linear-gradient(90deg, #00000060, #00000060 100%)",
}

const TimelineSectionImg = styled.img<{ activity: "past" | "near" | "far"}>`
    width: 100%;
    mask-image: ${props => StageActivityStatusToMask[props.activity]};

    @media ${media.l} {
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        width: 100px;
    }
`;

const Timeline = styled.div<{ numberOfStages: number}>`
    width: 75%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(${props => props.numberOfStages},1fr);
    justify-items: center;

    @media ${media.l} {
        display: none;
    }
`;

const TimelineMobile = styled.div<{ numberOfStages: number}>`
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50px 50px 1fr;
    grid-template-rows: repeat(${props => props.numberOfStages}, 100px);
    place-items: center;

    display: none;
    @media ${media.l} {
        display: grid;
    }
`;
