import React, { CSSProperties, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';
import { ColorsTheme } from '../Theme';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { media } from '../../events/media';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & img {
        width: 300px;
    }
`;

export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return <Wrapper>
        <Logo version={2} />
        {props.children}
    </Wrapper>
}


const Band1 = styled.div`
  width: 40%;
  height: 200%;

  position: fixed;
  transform-origin: bottom left;
  transform: rotate(30deg);
  background-color: ${ColorsTheme.darkRedDefault};
  left: 0;
  bottom: 0;
  filter: drop-shadow(4px 2px 6px #00000020) drop-shadow(-4px -2px 2px #00000020);

  @media ${media.s} {
    width: 70%;
    left: -40%;
  }
`;

const Band2 = styled.div`
  width: 40%;
  height: 150%;

  position: fixed;
  transform-origin: top left;
  transform: rotate(-30deg);
  background-color: ${ColorsTheme.footerBackground};
  left: 25%;
  top: 75%;
  filter: drop-shadow(4px 2px 2px #00000020);

  @media ${media.s} {
    width: 100%;
    left: 20%;
    bottom: 0;
    top: unset;
    transform-origin: bottom left;
    transform: rotate(30deg);
  }
`;

const LogoPack = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  
  position: fixed;
  top: 30px;
  left: 60px;

  & img {
    padding: 0;
    height: 62px;
    width: auto;
  }

  & h1 {
    font-size: 2rem;
    margin-left: 75px;
    margin-top: 10px;
    padding-top: 6px;
    border-top: 3px solid ${ColorsTheme.darkRedDefault};
  }

  @media ${media.s} {
    left: 30px;
  }
`;

const MainPanel = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100svh;
  width: 100%;
  padding: 30px 60px;
  box-sizing: border-box;
  
  & .form {
    width: 300px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    height: auto;
    z-index: 2000;

    & p {
      text-align: center;
    }
  }

  @media ${media.s} {
    align-items: flex-end;
    justify-content: stretch;
    padding: 0;

    & .form {
      background: #ffffff30;
      padding: 36px 12px 24px;
      width: 100%;

      & p a {
        color: #ffffffc0;
      }
    }
  }

  @media ${media.tablet} {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 300px;
  }
`;

const Triangle = styled.div<{ back: number, grey?: boolean }>`
  width: 100%;
  height: 100%;
  background: url(/img/auth/o${props => props.back}.jpg);
  background-size: cover;
  background-position: center center;
  filter: grayscale(${props => props.grey ? 1 : 0});
`;

const Triangles = styled.div`
  display: grid;
  grid-template-columns: 15svw 15svw;
  grid-template-rows: 15svw 15svw;
  gap: 2svw;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -50%);

  & > :nth-child(1) {
    mask: url(/img/auth/tri_tl.svg);
  }
  & > :nth-child(2) {
    mask: url(/img/auth/tri_tr.svg);
  }
  & > :nth-child(3) {
    mask: url(/img/auth/tri_bl.svg);
  }

  @media ${media.tablet} {
    left: 65%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${media.s} {
    grid-template-columns: 20svw 20svw;
    grid-template-rows: 20svw 20svw;
    top: 55%;
    transform: translate(-50%, -50%);
  }

`;

const Bar = styled.span<{ isActive: boolean, cl: number }>`
  display: block;
  width: 5px;
  border-radius: 2.5px;
  background: ${props => props.isActive ? ColorsTheme.burntUmberShade.s2 : props.cl === 1 ? ColorsTheme.burntUmberShade.s4 : ColorsTheme.burntUmberShade.s5};
  transition: background 0.35s ease-in;

  @media ${media.s} {
    background: ${props => props.cl === 1 ? ColorsTheme.burntUmberShade.s4 : ColorsTheme.burntUmberShade.s5};
  }
`;

interface BarsProps {
  anchor?: CSSProperties["alignItems"];
  width?: number;
  left?: number;
  nBars?: number;
  className?: string;
}

const BarsWrapper = styled.div<BarsProps>`
  display: flex;
  flex-flow: row;
  align-items: ${props => props.anchor || "flex-start"};
  justify-content: space-between;
  
  position: fixed;
  left: ${props => props.left || 0}%;
  width: ${props => props.width || 100}svw;
  height: 100svh;
  padding: 0 30px;
  box-sizing: border-box;

  @media ${media.s} {
    &.bars-top {
      width: 100svw;
      left: 0;
      padding: 0 12px;
    }

    &.bars-bottom {
      display: none;
    }
  }

`;

const Bars = (props: BarsProps) => {
  const bars = useMemo(() => {
    const heights = new Array(props.nBars || 40).fill(0).map(() => ({
      h: Math.floor(Math.random()*30 + 10),
      color: Math.random() > 0.75 ? 1 : 0,
    }));

    return heights;
  }, [props.nBars]);

  const [activeBars, setActiveBars] = useState<number[]>([3,15,27]);
  useEffect(() => {
    const interval = setInterval(() => {
      const n = 3 + Math.floor(4*Math.random());
      const actives = new Array(n).fill(0).map(i => Math.floor(Math.random() * bars.length));
      setActiveBars(actives);
    }, 5000);

    return () => clearInterval(interval);
  }, [bars]);

  return (
    <BarsWrapper {...props}>
      {bars.map((b,i) => (
        <Bar key={i} style={{ height: `${b.h}%` }} cl={b.color} isActive={activeBars.includes(i)} />
      ))}
    </BarsWrapper>
  )
}


export const PublicPageHarness = (props: PropsWithChildren<{}>) => {
  const triImgs = useMemo(() => {
    const indices = new Array(8).fill(0).map((_,i) => i+1);
    indices.sort((a,b) => Math.random() > 0.5 ? 1 : -1);
    return indices.slice(0,3);
  }, []);

  const [showImage, setShowImage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => setShowImage(Math.floor(Math.random() * 3)), 3000);
    return () => clearInterval(interval);
  }, []);

  return <>
    <Bars className="bars-top" anchor="flex-start" left={50} nBars={20} width={50} />
    <Bars className="bars-bottom" anchor="flex-end" left={50} nBars={20} width={50} />
    <Band2 />
    <Band1 />

    <Triangles>
      {triImgs.map((backIdx, idx) => <Triangle key={idx} back={backIdx} grey={showImage !== idx} />)}
    </Triangles>

    <LogoPack>
      <Logo version={2} />
      <Typography variant="h1"><FormattedMessage id="general.board" /></Typography>
    </LogoPack>

    <MainPanel>
      {props.children}
    </MainPanel>
  </>
}