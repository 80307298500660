import React from 'react';
import isHotkey from 'is-hotkey';
import styled from '@emotion/styled';
import { List, ListItem, ListItemText, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ActionRow, ButtonOptionPicker, Dialog, DialogState, OccupyFreeSpace, Tooltip, translateHotkey } from '../../../primitives';
import { PlaceholdersData } from './usePlaceholders';

const EntityLabel = styled.span`
    opacity: 0.54;
`;

interface Props {
    data: PlaceholdersData;
    state: Pick<DialogState, "isOpen" | "close">;
    submit: (expression: string) => void;
}

export const PlaceholderDialog = ({ data, state, submit }: Props) => {
    return (
        <Dialog
            dialogTitle={<FormattedMessage id="powerdoc.plugins.placeholder.dialog_title" />}
            isOpen={state.isOpen}
            close={state.close}
            noFullscreen
            onKeyDown={e => {
                if(e.key === "Enter") {
                    if(data.mode === "field" && data.fields.length === 1) {
                        submit(data.fields[0].expression);
                        e.preventDefault();
                        return;
                    }
                    if(data.mode === "expression" && data.expression.length) {
                        submit(data.expression);
                        e.preventDefault();
                        return;
                    }
                } else if(e.key === "Escape") {
                    state.close();
                } else if(isHotkey("mod+`", e)) {
                    data.setMode(m => m === "field" ? "expression" : "field");
                }
            }}
            >
            <ActionRow>
                <OccupyFreeSpace />
                <Tooltip text={translateHotkey("mod+`")} withWrapper>
                    <ButtonOptionPicker
                        options={[
                            [<FormattedMessage id="powerdoc.plugins.placeholder.mode.field" />, "field"],
                            [<FormattedMessage id="powerdoc.plugins.placeholder.mode.expression" />, "expression"],
                        ]}
                        selected={data.mode}
                        setSelected={data.setMode} />
                </Tooltip>
            </ActionRow>
            
            <TextField
                style={{ width: "100%" }}
                label={<FormattedMessage id={data.mode === "field" ? "searchField.label" : "powerdoc.plugins.placeholder.expression"} />}
                value={data.filter.filter}
                onChange={e => data.filter.setFilter(e.target.value)}
                autoFocus
                />

            {data.mode === "field" && <List>
                {data.fields.map(f => (
                    <ListItem key={f.expression} button onClick={() => submit(f.expression)}>
                        <ListItemText
                            primary={<><EntityLabel>{f.entityLabel}:</EntityLabel> {f.label || f.expression}</>}
                            secondary={f.label ? f.expression : undefined}
                            />
                    </ListItem>
                ))}
            </List>}
        </Dialog>
    );
}

