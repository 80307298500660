import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ResetPasswordForm } from '../../../toolympus/components/auth';
import { PublicPageWrapper } from '../../Common/PublicPageWrapper';

export const ResetPasswordContainer = () => {
    return (
        <PublicPageWrapper>
            <Typography style={{ width: 300 }}><FormattedMessage id="general.reset_password_hint" /></Typography>
            <ResetPasswordForm restoreAPIPath="/api/restore-access/request" />
        </PublicPageWrapper>
    );
}
