import styled from '@emotion/styled';
import React from 'react';
import logo from '../../../events/Pages/common_icons/logo-arbitr-full.svg';
import logoEn from '../../../events/Pages/common_icons/logo-arbitr-full-en.svg';
import { media } from '../../media';
import { HeaderMenuMobile } from './HeaderMenuMobile';
import { themeColor } from '../../../components/Theme';
import { getMemberName, useAuthorizedUser } from '../../../useContext/AuthorizedUserContext';
import { dict } from '../../../constants/system_reference';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useQuestion } from '../../../useContext/QuestionProvider';
import { useLocale } from '../../../toolympus/components/localization/LocalizationProvider';

export const HeaderMenu = (props: { logout: () => void }) => {
  const user = useAuthorizedUser();
  const { selectTab } = useQuestion();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { locale, setLocale } = useLocale();

  return (
    <Wrapper>
      <ContentHeader>
        <Logo onClick={() => {
          selectTab(null);
          history.push('/'); 
        }} src={locale === "ru" ? logo : logoEn} alt={formatMessage({ id: "addition.logoTitle" })} />
        <HeaderConfiguration>
          <MenuItem onClick={() => { history.push("/news") }}>{formatMessage({ id: "history.news" })}</MenuItem>
          <MenuItem onClick={() => { history.push("/documentation/home") }}>{formatMessage({ id: "history.documentation" })}</MenuItem>
          <MenuItem aria-label={formatMessage({ id: "addition.currentLang" })} onClick = {() => setLocale(locale === "ru" ? "en" : "ru")}>
            {locale === "ru" ? "EN" : "RU"}
          </MenuItem>
          <UserInfo>
            <UserName>{getMemberName(locale, user)}</UserName>
            <Logout onClick={props.logout}>{formatMessage({ id: "service.logout" })}</Logout>
          </UserInfo>

        </HeaderConfiguration>
      </ContentHeader>

      <WrapperHeaderMenuMobile>
        <HeaderMenuMobile logout={props.logout} />
      </WrapperHeaderMenuMobile>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: ${dict.spl.boxShadow.average} ${themeColor(c => c.blackShadow)};
  z-index: 1000;

  @media ${media.l} {
    padding: 20px;
  }

  @media ${media.m} {
    padding: 15px 20px 10px;
  }

  @media ${media.xs} {
    padding: 0 5px 10px;
  }
`;


const ContentHeader = styled.div`
  padding: 10px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 400 17px 'Ubuntu';

  @media ${media.l} {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${media.m} {
    display: none;
  }
`;

const HeaderConfiguration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media ${media.l} {
    margin-right: 20px;
  }
`;

const UserName = styled.p`
  margin: 0 0 5px auto;
  color: ${themeColor(c => c.textGreyBasic)};
`;

const Logout = styled.p`
  width: fit-content;
  text-align: right;
  font-weight: 500;
  color: ${themeColor(c => c.darkRedDefault)};
  cursor: pointer;
  margin: 5px 0 0 auto;
`;


const UserInfo = styled.div`
`;

const MenuItem = styled.a`
  margin-right: 30px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;
MenuItem.defaultProps = { role: "button" };


const WrapperHeaderMenuMobile = styled.div`
    display: none;
    @media ${media.m} {
        display: block;
    }
`;

const Logo = styled.img`
  cursor: pointer;
  height: 45px;
`;

