import React, { ReactElement, useState } from 'react';
import { dict } from '../../constants/system_reference';
import { Abstain } from './case/Abstain';
import { AgainstAll } from './case/AgainstAll';
import { SuggestCandidate } from './case/SuggestCandidate';
import { Conflict } from './case/Vote/Conflict';
import { RemoveFromVote } from './case/Vote/RemoveFromVote';
import { SuggestOption } from './case/Vote/SuggestOption';
import { Vote } from './case/Vote/Vote';
import { VoteOptions } from './case/Vote/VoteOptions';
import { QuestionActionParams, VoteContentConfig } from './modal_window';

interface ModalWindowProps {
    activeModal: typeof dict.button[keyof typeof dict.button] | null;
    setActiveModal: (v: string | null) => void;
    params: QuestionActionParams;
}

export const ModalWindow = ({ activeModal, setActiveModal, params } : ModalWindowProps) => {    
    const closeModalWindow = () => setActiveModal(null);

    const [voteStatus, setVoteStatus] = useState<VoteContentConfig>({
        isConflict: !params.data.status.vote,
        isDiscuss: false,
        isSuggestOption: false,
    });

    const disableVoting = () => setVoteStatus((prevState) => ({ ...prevState, isConflict: null }));
    const makeActionForModalWindow = (type : string, fetching : boolean) => 
        setVoteStatus((prevState) => ({ ...prevState, [type]: fetching }));

    const accept = (type: string) => { makeActionForModalWindow(type, true) };
    const reject = (type: string) => { makeActionForModalWindow(type, false) };
    const redirect = (type : string, redirect : string | boolean) => setVoteStatus((prevState) => ({ ...prevState, [type]: redirect }));

    const dialogProps = {
        closeModalWindow,
        params: {...params, accept, reject, redirect, disableVoting, voteStatus },
        toSuggestOption: () => setVoteStatus(prev => ({ ...prev, isSuggestOption: true })),
        cancelSuggestOption: () => setVoteStatus(prev => ({ ...prev, isSuggestOption: false })),
    }

    const getContent = (): ReactElement => {
        switch (activeModal) {
            case dict.button.suggestCandidate:
                return <SuggestCandidate closeModalWindow={closeModalWindow} params={params} />
            case dict.button.abstain:
                return <Abstain closeModalWindow={closeModalWindow} params={params} />
            case dict.button.againstAll:
                return <AgainstAll closeModalWindow={closeModalWindow} params={params} />
            case dict.button.voting:
            case dict.button.conflict:
                switch (true) {
                    case (
                        (activeModal === dict.button.conflict && !voteStatus.isDiscuss) 
                        || (!params.data.status.vote && voteStatus.isConflict === true)
                    ):
                        return <Conflict {...dialogProps} takeConflict = {activeModal === dict.button.voting ? false : true} />
                    case voteStatus.isDiscuss === true:
                        return <RemoveFromVote {...dialogProps} />
                    case voteStatus.isSuggestOption:
                        return <SuggestOption {...dialogProps} />;
                    case params.data.is_candidates_question:
                        return <Vote {...dialogProps}  />;
                    default:
                        return <VoteOptions {...dialogProps} />;
                }
            default:
                return <></>
        }
    }

    
    return <>
        {getContent()}
    </>
}
