import styled from '@emotion/styled';
import { Button, ButtonGroup } from '@material-ui/core';
import React, { useState } from "react";
import { Sizes, themeColor } from '../../components/Theme';
import { dict } from '../../constants/system_reference';
import { media } from '../media';
import { FormattedMessage, useIntl } from 'react-intl';
import { CandidateResponse, CandidateResult } from '../../api/types/questions';
import { useLocale } from '../../toolympus/components/localization/LocalizationProvider';
import { QuestionOption } from '../../api/types/main';
import { Check } from '@material-ui/icons';
import { OptionLabel } from './OptionLabel';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { getCandidateName } from '../candidates/CandidatePerson';
import { getMemberName } from '../../useContext/AuthorizedUserContext';
import { CandidateQuestion } from '../../api/types/candidates';
import { UnvotedCandidatesParagraph } from '../modal_window/case/Vote/Vote.styles';

interface Props {
    responses: CandidateResponse[];
    candidates: CandidateResult[];
    allCandidates: CandidateQuestion[];
    options: QuestionOption[] | undefined;
}

export const QuestionResults = ({ responses, candidates, options, allCandidates }: Props) => {
    const [sortButton, setSortButton] = useState(dict.tabs.voices);

    const { formatMessage } = useIntl();

    const responsesTab = responses
        ? <CandidatesResponses responses={responses} candidates={candidates} allCandidates={allCandidates} />
        : <FormattedMessage id="service.dash" />;

    const resultsTab = options && options.length
        ? <OptionQuestionResults options={options} />
        : <CandidatesQuestionResults candidates={candidates} />;

    const resultsExplanation = (
        <Explanation>
            <MarkdownDisplay text={formatMessage({ id: options && options.length ? "results.score_options_explanation" : "results.score_candidates_explanation"})} />
        </Explanation>);


    return <Wrapper>
        <Buttons>
            {
                [dict.tabs.voices, dict.tabs.results].map((btn: string) => (
                    <ButtonFilter
                        onClick={() => setSortButton(btn)}
                        isactive={`${sortButton === btn}`}>
                        {btn === dict.tabs.voices ? <FormattedMessage id="tabs.voices" /> : <FormattedMessage id="tabs.results" />}
                    </ButtonFilter>))
            }
        </Buttons>

        <Content>
            <VotesMobile>
                {sortButton === dict.tabs.results
                    ? <>
                        <TableMobile key="results">
                            {resultsTab}
                        </TableMobile>
                        {resultsExplanation}
                    </>
                    : <TableMobile key="responses">
                        {responsesTab}
                    </TableMobile>
                }
            </VotesMobile>

            <TableWrapper>
                <Title><FormattedMessage id="results.voices" /></Title>
                <Table>
                    <Votes>
                        {responsesTab}
                    </Votes>
                </Table>
            </TableWrapper>

            <ResultsOuter>
                <TableWrapper>
                    <Title><FormattedMessage id="results.results" /></Title>

                    <Table>
                        {resultsTab}
                    </Table>
                </TableWrapper>
                {resultsExplanation}
            </ResultsOuter>

        </Content>
    </Wrapper>
}

const CandidatesResponses = ({ responses, candidates, allCandidates }: Pick<Props, "responses" | "candidates" | "allCandidates">) => {
    const { locale } = useLocale();

    const defineChoiceType = (response: CandidateResponse) => {
        switch(true) {
            case response.is_abstained:
                return <FormattedMessage id="results.abstain" />;
            case response.is_against_all:
                return <FormattedMessage id="results.against_all" />;
            case response.is_conflict:
                return <FormattedMessage id="results.conflict" />;
            case response.is_voted && (response.response?.vote?.length || 0) > 0:
                return (<>
                    <ol>
                        {(response?.response?.vote || []).map((cid: string) => {
                            const candidate = candidates.find(c => c._id === cid);
                            return <li key={cid}>{candidate ? getCandidateName(locale, candidate) : ""}</li>
                            })}
                    </ol>
                    {!!response?.response?.candidates_conflict?.length &&
                      <UnvotedCandidatesParagraph>
                        <span><FormattedMessage id="voting.candidates.options.conflict" />:</span> {(response?.response?.candidates_conflict || []).map(cId => allCandidates.find(c => c._id === cId)).filter(x => !!x).map(c => getCandidateName(locale, c as CandidateResult)).join(", ")}
                      </UnvotedCandidatesParagraph>}
                  </>)
            case !!(response.is_voted && response?.response?.option?.label):
                return <OptionLabel option={response?.response?.option} />;
            default:
                return <FormattedMessage id="service.dash" />;
        }
    }

    return <>
        {responses.map((response: CandidateResponse) => (
            <Vote key={response.member_id}>
                <MemberName>
                    {getMemberName(locale, response.member)}
                </MemberName>

                <VoteType>
                    {defineChoiceType(response)}
                </VoteType>
            </Vote>
            ))
        }
    </>
};

const CandidatesQuestionResults = ({ candidates }: { candidates: CandidateResult[] }) => {
    const { locale } = useLocale();

    return (
        <>
            <Titles>
                <p key="order">
                    <FormattedMessage id="results.caseNumber" />
                </p>

                <TitlesName>
                    <FormattedMessage id="results.lnsCandidate" />
                </TitlesName>

                <p key="score">
                    <FormattedMessage id="results.points" />
                </p>
            </Titles>

            {
                candidates
                    ? candidates.map((cnd: CandidateResult, id: number) => 
                            <Result key={id}>
                                <Order>{id+1}</Order>
                                <Name>{getCandidateName(locale,cnd)}</Name>
                                <Points>{cnd.score || <FormattedMessage id="service.dash" />}</Points>
                            </Result>)
                    : <FormattedMessage id="service.dash" />
            }
        </>);
}

const OptionQuestionResults = ({ options }: { options: QuestionOption[] }) => {
    const { locale } = useLocale();

    return (
        <>
            <Titles>
                <p key="order">
                    <FormattedMessage id="results.caseNumber" />
                </p>

                <TitlesName>
                    <FormattedMessage id="results.option" />
                </TitlesName>

                <p key="score">
                    <FormattedMessage id="results.points" />
                </p>
            </Titles>

            {options
                ? options.map((o: QuestionOption, id: number) => 
                        <Result key={id}>
                            <Order>{id+1}</Order>
                            <Name>{locale === "en" ? o.label_en : o.label} {o.win && <Check htmlColor={themeColor(c => c.darkRedDefault)} />}</Name>
                            <Points>{o.score === undefined ? <FormattedMessage id="service.dash" /> : o.score}</Points>
                        </Result>)
                : <FormattedMessage id="service.dash" />
            }
        </>);
}

const Wrapper = styled.div`
    @media ${media.l} {
        width: 80%;
        margin: auto;   
    }

    @media ${media.m} {
        width: 100%;
    }

    @media ${media.s} {
        width: 100%;
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    column-gap: 50px;

    @media ${media.l} {
        grid-template-columns: 1fr;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
`;

const TableShadow = `${dict.spl.boxShadow.average} rgba(0, 0, 0, 0.1), ${dict.spl.boxShadow.average} rgba(0, 0, 0, 0.1)`;

const Votes = styled.div`
    margin: 0;
    height: min-content;
    display: flex;
    flex-direction: column;
    box-shadow: ${TableShadow};

    @media ${media.l} {
        display: none;
    }
`;

const TableWrapper = styled.div`
    margin: 0;
    height: min-content;
    display: flex;
    flex-direction: column;
    box-shadow: ${TableShadow};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    overflow: hidden;

    @media ${media.l} {
        display: none;
    }
`;


const ResultsOuter = styled.div`
    @media ${media.l} {
        display: none;
    }
`;

const Title = styled.p`
    text-align: center;
    font-size: 20px;
    font-weight: 500;
`;

const TableMobile = styled.div`
    display: none;

    @media ${media.l} {
        display: block;
    }
`;

const Table = styled.div``;

const MemberName = styled.p`
    margin: 0;
    padding: 10px 20px;
    width: 50%;
    border-right: 1px solid ${themeColor(c => c.question_case.line)};
`;

const Vote = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:nth-child(odd) {
        background-color: ${themeColor(c => c.alabaster)};
    }
`;

const VoteType = styled.div`
    padding: 0 20px;
    width: 50%;

    & ol {
        padding: 0;
        padding-left: 16px;
    }
`;

const Result = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:nth-child(odd) {
        background-color: ${themeColor(c => c.alabaster)};
    }

    @media ${media.l} {
        border-bottom: 1px solid ${themeColor(c => c.question_case.line)};
    }
`;

const Explanation = styled.div`
    font-size: 0.85em;
    color: ${themeColor(c => c.textGreyBasic)};

    @media ${media.l} {
        padding: 0 10px;
    }
`;

const Order = styled.div`
    width: 50px;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    word-break: break-word;
`;

const Name = styled.p`
    margin: 10px 0;
    width: 266px;
    text-align: left;
    font-size: 17px;
    font-weight: 400;
`;

const Points = styled.p`
    margin: 0;
    width: 50px;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    word-break: break-word;
`;

const Titles = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    > p {
        &:first-child {
            width: 50px;
            text-align: center;
            word-break: break-word;
        }

        font-size: 17px;
        font-weight: 500;
    }
`;

const TitlesName = styled.p`
    width: 266px;
`;

const Buttons = styled(ButtonGroup)`
    display: none;

    @media ${media.l} {
        display: flex;
    }
`;

const ButtonFilter = styled(Button) <{ isactive: string }>`
  margin: 0 1px;
  width: 100%;
  height: 64px;
  background-color: ${props => themeColor(c => props.isactive === "true" ? `${c.burntUmberShadow}` : `${c.whiteShadow}`)};
  color: ${props => themeColor(c => props.isactive === "true" ? c.darkRedDefault : c.textGreyBasic)};
  border: none;
  font: 500 1rem 'Ubuntu';
  cursor: pointer;
  box-shadow: ${dict.spl.boxShadow.average} rgba(0, 0, 0, 0.05), ${dict.spl.boxShadow.ultraLight} rgba(0, 0, 0, 0.05);

  &.MuiButton-text {
    /* ste */
    text-transform: capitalize;
    &:hover {
      background-color: ${props => themeColor(c => props.isactive === "true" ? `${c.burntUmberShadow}` : `${c.whiteShadow}`)};
    }
  }

  @media ${media.m} {
    width: 50%;
    height: 52px;
  }

  @media ${media.xs} {
    &:first-child {
      margin-bottom: 4px;
    }

    width: 100%;
  }
`;

const VotesMobile = styled.div`
    display: none;

    @media ${media.l} {
        display: block;
    }
`;
