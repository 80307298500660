import styled from '@emotion/styled';
import { themeColor } from '../../components/Theme';
import { media } from '../media';

export const EntityText = styled.p`
    margin: 0;
    text-align: right;
    font: 300 1rem "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};

    &:nth-child(even) {
        word-break: break-word;
        font-weight: 400;
        color: ${themeColor(c => c.blackDefault)};
    }
    
    &:first-child {
        width: auto;
        text-align: left;
    }

    &:last-child {
        margin: 0;
        width: auto;
        justify-content: flex-end;
        display: flex;
    }

    @media ${media.m} {
        font-size: 14px;
    }
`;

export const Title = styled.div<{ margin?: string, font?: string, color?: string }>`
    margin: ${ props => props.margin };
    color: ${ props => themeColor(c => props.color === "grey" ? c.textGreyBasic : c.blackDefault ) };
    font: ${ props => props.font }px "Ubuntu";
`;

export const QuestionWrapper = styled.div`
    padding: 2rem;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
`;

export const Time = styled.p`
    margin: 5px 0 0 0;
    font-weight: 300;
    font-size: 10px;
`;