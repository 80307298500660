import React, { Fragment } from "react";
import styled from '@emotion/styled';
import { themeColor } from '../components/Theme';
import { media } from '../events/media';
import { Link } from 'react-router-dom';

type historyI = { t: string, path?: string, notPath?: boolean, link?: string, isClick?: boolean, arrow?: boolean };

export const History = ({path}: { path: historyI[] }) => {
    const createPath = (history: historyI[]): historyI[] =>
        history.map((nav: historyI, idx: number) =>
            idx >= history.length - 1 ? nav : [nav, { t: "→", notPath: true, arrow: true }]).flat();

    return <Wrapper>
        <Content>
            {createPath(path).map((currentPath: Omit<historyI, "link">, idx: number) =>
                <Fragment key={idx}>
                    {
                        currentPath.arrow 
                        ? <Arrow>{currentPath.t}</Arrow>
                        : currentPath.isClick === false ?
                            <span>{currentPath.t}</span>
                            : currentPath.path ?
                            <LinkHistory to={currentPath.path} isClick={currentPath.isClick} key={idx} isArrow={currentPath.notPath}>
                                {currentPath?.notPath === true ? currentPath.t : currentPath.t}
                            </LinkHistory>
                            : currentPath.t
                    }
                </Fragment>
            )}
        </Content>
    </Wrapper>
}

const Wrapper = styled.div`
    @media ${media.m} {
        display: none;
    }
`;

const Content = styled.div`
    width: 100%;
    padding: 10px 0;
    margin: auto;
    font: 300 1rem "Ubuntu";
    line-height: 1.3em;
    color: ${themeColor(c => c.textGreyBasic)};

    @media ${media.l} {
        width: 90%;
        margin: auto;
    }
`;

const LinkHistory = styled(Link)<{ isArrow: undefined | boolean, isClick: undefined | boolean }>`
    margin: ${props => props.isArrow ? "0 5px" : "auto"};
    color: ${themeColor(c => c.textGreyBasic)};
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px dotted ${themeColor(c => c.textGreyBasic)};
`;

const Arrow = styled.span`
    margin: 0 4px;
`;