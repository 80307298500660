import { useState } from "react";
import { Question } from "../../../../api/types/main"
import { apiFetch } from "../../../../toolympus/api/core";

interface Config {
    question: Question;
    setQuestion: (x: Question) => void;
    onVoted: () => void;
}

interface VoteStatus {
    selected_option_id: number | null;
    comment: string;
    isChangingVote: boolean;
}

export const useOptionVote = ({ question, setQuestion, onVoted }: Config) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    let existingVote = question?.status?.option?.id === undefined ? null : question?.status?.option?.id;

    const [voteState, setVoteState] = useState<VoteStatus>({
        selected_option_id: existingVote,
        comment: question.status?.comment || "",
        isChangingVote: question.status.is_voted,
    });

    const canCastVote = voteState.selected_option_id !== null
        && voteState.selected_option_id !== existingVote
        && !isLoading;

    const castVote = () => {
        if(canCastVote) {
            setIsLoading(true);
            apiFetch<Question>(`/api/presidium-app/question/${question._id}/action/vote`, "put", {
                option: { id: voteState.selected_option_id },
                comment: voteState.comment,
            })
            .then(result => {
                setIsLoading(false);
                setIsSubmitted(true);
                setQuestion(result);
                onVoted()
            })
            .catch(e => {
                setIsLoading(false);
                throw e;
            });
        }
    }

    return {
        isLoading,
        voteState: voteState,
        selectOption: (optionId: number | null) => setVoteState(x => ({ ...x, selected_option_id: optionId })),
        setComment: (comment: string) => setVoteState(x => ({ ...x, comment })),
        canCastVote,
        castVote,
        isSubmitted,
    }
}
