import React from 'react';
import styled from '@emotion/styled';
import arrow from '../../../events/Pages/common_icons/arrow_question_active.svg';
import warning from '../../../events/Pages/common_icons/warning.svg';
import warning_disable from '../../../events/Pages/common_icons/arrow_question_disable.svg';
import { QuestionWrapper, Title } from '../Questions.style';
import { Sizes, themeColor } from '../../../components/Theme';
import { media } from '../../media';
import { dict } from '../../../constants/system_reference';
import { Question, QuestionItem } from '../../../api/types/main';
import { FormattedMessage } from 'react-intl';
import { useFormats } from '../../../toolympus/components/schemed';
import { getTimeLeft } from '../../candidates/QuestionDeadline';



const QuestionInfo = styled(QuestionWrapper)`
    width: 100%;
    word-break: break-word;
`;

const QuestionTitle = styled(Title)<{disableQuestion: boolean}>`
    width: 100%;
    color: ${ props => themeColor((c) => props.disableQuestion ? c.textGreyBasic : c.questionStatus) };
`;

QuestionTitle.defaultProps = { margin: '0 0 10px 0', color: 'black', font: '500 20' };

const QuestionStatus = styled.p<{disableQuestion: boolean}>`
    margin: 0 0 10px 0;
    min-height: 50px;
    font: 400 16px 'Ubuntu';
    color: ${ props => themeColor((c) => props.disableQuestion ? c.textGreyBasic : c.questionStatus) };
`;

const QuestionWarning = styled.p<{ isVoting?: boolean }>`
    margin: 0;
    display: flex;
    font: ${(props) => (props.isVoting ? 400 : 300)} 20px 'Ubuntu';
    color: ${(props) => themeColor((c) => (props.isVoting ? c.warning : c.textGreyBasic))};

    min-height: 1.15em;

    @media ${media.l} {
        font-size: 14px;
    }
`;

export const QuestionArrow = styled.div<{ isActive?: boolean }>`
    padding: 0 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => themeColor((c) => (props.isActive ? c.arrowColor : c.disableArrow))};
    border-top-right-radius: ${Sizes.geometry.borderRadius.pannel}px;
    border-bottom-right-radius: ${Sizes.geometry.borderRadius.pannel}px;

    @media ${media.m} {
        display: none;
    }
`;

const ArrowIcon = styled.img`
    margin: auto;
`;

const WarningIcon = styled.img`
    margin-right: 5px;
`;

export const isQuestionActionDueSoon = (q: Question): boolean => {
    return !!q.active_stage
        && !q.is_finished
        && [dict.spl.voting, dict.spl.suggesting].includes(q.active_stage.code)
        && getTimeLeft(q.active_stage.end_date).asDays() <= 3;
}

const QuestionStatusLabels = {
    [dict.spl.voting]: "addition.voteBefore",
    [dict.spl.suggesting]: "addition.suggestBefore",
    [dict.spl.confirming]: "addition.freeForActive",
    [dict.spl.finishing]: "addition.finishing",
}

const StatusActivityLabels = {
    youVoted: "feedback.youVoted",
    youAbstained: "feedback.youAbstained",
    youAgainstAll: "feedback.youAgainstAll",
    youConflicted: "feedback.youConflicted",
};

interface QuestionStatusData {
    label?: string;
    isActive?: boolean;
    showWarning?: boolean;
    showFullContent?: boolean;
}

export const QuestionItemCurrent = ({ question }: QuestionItem) => {
  const { formatDate } = useFormats();
  
  const questionStatus = question.active_stage?.code;

  const isActionableStage = [dict.spl.voting, dict.spl.suggesting].includes(questionStatus);
  

  const getStatusData = (): QuestionStatusData => {
    switch (true) {
      case question.status.is_voted && (question.status.vote?.length || question.status.option?.id !== undefined) ? true : false:
        return { label: StatusActivityLabels.youVoted };
      case question.status.is_abstained:
        return { label: StatusActivityLabels.youAbstained };
      case question.status.is_against_all:
        return { label: StatusActivityLabels.youAgainstAll };
      case question.status.is_conflict:
        return { label: StatusActivityLabels.youConflicted };
      default:
        return { showFullContent: true, showWarning: isQuestionActionDueSoon(question), isActive: isActionableStage };
    }
  };

  const { showFullContent, showWarning, isActive, label} = getStatusData();

  return (
    <>
      <QuestionInfo>
        <QuestionTitle disableQuestion={question.status.is_voted}>{question.title}</QuestionTitle>
        
        <QuestionStatus disableQuestion={question.status.is_voted}><FormattedMessage id={question.active_stage?.label_id || "addition.empty"} /></QuestionStatus>
        
        <QuestionWarning isVoting={showWarning}>
            {showFullContent
                ? (
                    <>
                      {showWarning && <WarningIcon src={warning} alt="" />}
                      {isActionableStage ? <FormattedMessage id={QuestionStatusLabels[questionStatus] || QuestionStatusLabels[dict.spl.finishing]} /> : <span />} {isActionableStage ? formatDate(question.active_stage.end_date) : null}
                    </>
                )
                : label ? <FormattedMessage id={label} /> : ""}
        </QuestionWarning>
      </QuestionInfo>

      <QuestionArrow isActive={isActive}>
        <ArrowIcon src={isActive ? arrow : warning_disable} />
      </QuestionArrow>
    </>
  );
};

