import React from 'react';
import styled from '@emotion/styled';
import { Modal } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Feedback, ModalWindowButton, ModalWindowButtons } from '../../../candidates/Candidate.style';
import { ModalWindowCaseSettingsExt } from '../../modal_window';
import { BoxWrapper, Buttons, Title } from './Vote.styles';
import { Loader } from '../../../../common/Loader';
import { dict } from '../../../../constants/system_reference';
import { useSuggestOption } from './useSuggestOption';
import { TextareaComments, TextInput, CloseModalWindow } from '../../Controls';
import { useLocale } from '../../../../toolympus/components/localization/LocalizationProvider';

const OptionsWrapper = styled.div`
    text-align: center;
    margin: 1rem 0;
    
    & p:first-child {
    }

    & ul {
        padding: 0;
        list-style: none;
    }
`;

interface Props extends ModalWindowCaseSettingsExt {
    cancelSuggestOption: () => void;
}

export const SuggestOption = ({ params, closeModalWindow, cancelSuggestOption }: Props) => {
    const { formatMessage } = useIntl();
    const {
        state,
        update,
        canSuggest,
        suggest,
        isLoading,
        isSubmitted,
    } = useSuggestOption({
        question: params.data,
        setQuestion: params.setData,
        onSuggested: () => {
            params.disableVoting();
            params.hideCandidateButtons(dict.button.voting);
        }
    });

    const { locale } = useLocale();

    const alreadyVoted = params.data.status.is_voted;

    return (
        <Modal
            open={true}
            onClose={closeModalWindow}>
            <BoxWrapper>
                <CloseModalWindow closeModalWindow={closeModalWindow} />
                <Title>
                    <FormattedMessage id="modalWindow.suggest_option.title" />
                </Title>

            <OptionsWrapper>
                <p><FormattedMessage id="modalWindow.suggest_option.existing_options" /></p>
                <ul>
                    {(params.data.options || []).map(option => <li key={option.id}>{locale === "en" ? option.label_en : option.label}</li>)}
                </ul>
            </OptionsWrapper>

            <Buttons>
                <TextInput
                    fullWidth
                    onChange={e => update({ option: e.target.value})}
                    placeholder={formatMessage({ id: "modalWindow.suggest_option.option_label" })} />

                <TextareaComments
                    disabled={isLoading || isSubmitted}
                    rows={3}
                    value={state.comment}
                    onChange={e => update({ comment: e.target.value })}
                    margin={"10px 0"}
                    placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeCommentsPlaceholder" })}
                />

                <ModalWindowButtons voted={alreadyVoted}>
                    <ModalWindowButton
                        isTransparent={true}
                        onClick={isSubmitted ? closeModalWindow : cancelSuggestOption}>
                            <FormattedMessage id="service.back" />
                    </ModalWindowButton>

                    {!isSubmitted && <ModalWindowButton
                        isNotReady={!canSuggest}
                        disabled={!canSuggest}
                        onClick={suggest}>

                        {isLoading
                            ? <Loader type="button" />
                            : formatMessage({ id: "modalWindow.suggest_option.suggest_and_vote" })
                        }
                    </ModalWindowButton>}
                </ModalWindowButtons>

                {!isSubmitted && <Feedback isVisible={true}>
                    <FormattedMessage id="modalWindow.suggest_option.autovote_note" />
                </Feedback>}

                {isSubmitted && <Feedback isVisible={alreadyVoted}>
                    <FormattedMessage id="feedback.thxForVoting" />
                </Feedback>}
            </Buttons>

            </BoxWrapper>
        </Modal>
    );
}
