import React, { createContext, ReactNode, useContext } from "react";
import { useQuestionFeed } from '../events/questions/hook/useQuestionFeed';
import { useAuthorizedUser } from './AuthorizedUserContext';
import { useFeedSocket } from "./FeedContext";
import { useQuestion } from './QuestionProvider';

const QuestionChatContext = createContext<any>({
    sendMessage: () => "",
});

interface Props {
    children: ReactNode;
}

export const QuestionChatProvider = ({ children } : Props) => {
    const feedSocket = useFeedSocket();

    const { questionInfo } = useQuestion();
    const { _id } = useAuthorizedUser();
    const { questionChat, sendMessage } = useQuestionFeed(feedSocket, questionInfo._id, _id);

    return <QuestionChatContext.Provider value={{
        questionChat, sendMessage
    }}>
        {children}
    </QuestionChatContext.Provider>
}

export const useQuestionChat = () => useContext(QuestionChatContext);