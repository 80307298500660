import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { QuestionCasePlaintiffsProps, Tin } from '../../../api/types/questions';
import { themeColor } from '../../../components/Theme';
import { Title } from '../Questions.style';
import { QuestionCaseEntityLine } from './QuestionCaseEntityLine';

export const QuestionCasePlaintiffs = ({ case_info, type }: QuestionCasePlaintiffsProps) => {
    const { formatMessage } = useIntl();
    return <Wrapper>
        <ContentTitle margin = {"20px auto 15px 0"} color = {themeColor(c => c.blackDefault)} font = {"500 20"}>
            {formatMessage({ id: `question.${type}`})}
        </ContentTitle>

        {
            case_info[type].length >= 1 
            ?   (case_info[type] as Tin[]).map(
                (item: Tin, id: number) => <QuestionCaseEntityLine key={id} title={item.title} inn={item.inn} />)
            : formatMessage({ id: "addition.empty" })
        }

    </Wrapper>
}

const Wrapper = styled.div`
    width: 100%;
`;

const ContentTitle = styled(Title)`
    text-align: center;
`;
