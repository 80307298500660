import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router-dom';
import { Question } from '../../api/types/main';
import { QuestionPageInstance } from '../../constants/instance';
import { media } from '../media';
import { dict } from '../../constants/system_reference';
import { Loader } from '../../common/Loader';
import { useLoadedData } from '../../toolympus/hooks/useLoadedData';
import { QuestionDescription } from '../questions/QuestionDescription';
import { QuestionTabs } from '../questions/QuestionCase/QuestionTabs';
import { QuestionResults } from '../questions/QuestionResults';
import { History } from '../../common/History';
import { useIntl } from 'react-intl';
import { QuestionChat } from '../questions/QuestionChat';
import { useQuestion } from '../../useContext/QuestionProvider';
import { history } from '../../constants/history_reference';

export const QuestionPage = ({ match }: RouteComponentProps<{ id: string }>) => {
    const { setQuestionInfo, questionTab, selectTab } = useQuestion();
    const { data, setData } = useLoadedData<Question>(`/api/presidium-app/question/${match.params.id}`, QuestionPageInstance, true);

    useEffect(() => {
        selectTab(dict.tabs.description);
        setQuestionInfo({
            _id: data._id,
            casenbr: data.case_info?.casenbr,
            active_stage: data.active_stage?.code
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data._id ]);


    const { formatMessage } = useIntl();
    
    return (
        <Wrapper>
            <HistoryWrapper>
                <History path={[
                    history.votingQuestions(formatMessage({ id: "history.votingQuestions" })),
                    history.caseTitle(data.title)
                ]} />
            </HistoryWrapper>

            <Tabs>
                <QuestionTabs questionTab={questionTab} changeQuestionTab={selectTab} noResults={data.question_type === "no_vote_question"} />
            </Tabs>
            
            {data._id === ''
                ? <Loader type={"default"} />
                : <Content>
                    {questionTab === dict.tabs.description &&
                        <QuestionDescription data={data} setData={setData} />}
                    {questionTab === dict.tabs.discussion &&
                        <QuestionChat question={data} />}
                    {questionTab === dict.tabs.results &&
                        <QuestionResults responses={data.responses} candidates={data.result?.candidates} allCandidates={data.candidates} options={data.result?.options} />}
                </Content>
            }
        </Wrapper>
    );
};

export const Wrapper = styled.div`
    width: 100%;
    margin: 25px auto;

    @media ${media.l} {
        width: 100%;
    }

    @media ${media.m} {
        margin: 0 auto 40px auto;
    }
`;

const Tabs = styled.div`
    margin: auto;
    width: 80%;

    @media ${media.m} {
        width: 90%;
    }

    @media ${media.s} {
        display: none;
    }
`;

const Content = styled.div``;

export const HistoryWrapper = styled.div`
    margin: auto;
    width: 80%;

    @media ${media.l} {
        margin-left: 0;
        width: 100%;
    }
`;