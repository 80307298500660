import { Badge as MuiBadge, Button, Dialog, Divider, IconButton, List, ListItem, Popover, Tooltip } from '@material-ui/core';
import React from 'react';
import { Header, Body, Content, Time, Link, BadgeMark, Container } from './Notifications.style';
import { Close, Notifications as NotificationsIcon } from '@material-ui/icons';
import { Align } from './types';
import { useFormats } from '../schemed/';
import { FormattedMessage } from 'react-intl';
import { utc } from '../timezone';
import { useNotificationsComponent } from './useNotifications';

type NotificationsProps = Align & {
    data: ReturnType<typeof useNotificationsComponent>,
    viewDefaultButton?: boolean,
}

const isMobile = () => document.body.clientWidth <= 600;

export const Notifications = ({data, viewDefaultButton}: NotificationsProps) => {
    const { formatDatetimeShort } = useFormats()
    const { setAnchorRef, notifications, noRead, anchorRef, read, open, readAll } = data;
    
    const view = <Container>
        <Header>
            <FormattedMessage id="notifications.title"/>
            <IconButton onClick={() => setAnchorRef(null)}><Close /></IconButton>
        </Header>
        <Body>
            <List>
                {notifications.map(item => {
                    const component = <React.Fragment key={item._id}>
                        <ListItem>
                            <Content>
                                <Header subTitle>
                                    <div>
                                        {!item.is_read && 
                                            <Tooltip title={<FormattedMessage id="notifications.markasread"/>}>
                                                <MuiBadge 
                                                    anchorOrigin={{vertical: 'top',horizontal: 'left'}} 
                                                    variant="dot" color="secondary" 
                                                    onClick={e => {e.preventDefault(); read(item._id)}}
                                                >
                                                    <BadgeMark></BadgeMark>
                                                </MuiBadge>
                                            </Tooltip>
                                        }
                                        
                                        {item.title}
                                    </div>
                                </Header>
                                {item.description}
                                <Time>
                                    {formatDatetimeShort(utc.toLocal(item.time))}
                                </Time>
                                
                            </Content>
                        </ListItem>
                        <Divider />
                    </React.Fragment>

                    if (!item.link) {
                        return component;
                    }

                    return <Link href={item.link}> {component} </Link>;
                })}
            </List>
        </Body>
        {noRead.length > 0 && <Button color="primary" fullWidth onClick={readAll}><FormattedMessage id="notifications.markallasread"/> </Button>}
    </Container>
    
    const { Badge, callOpen } = data

    return <div>
        {viewDefaultButton && <Button onClick={callOpen} size="small"><Badge><NotificationsIcon /></Badge></Button>}
       {isMobile() ? 
            <Dialog fullScreen open={open}>
                {view}
            </Dialog>
            :
            <Popover
                open={open}
                anchorEl={anchorRef}
                onClose={() => setAnchorRef(null)}
            >
                {view}
            </Popover>
        }
    </div>
}