import styled from '@emotion/styled';
import React from "react";
import magnifier from '../../../events/Pages/search_icons/magnifier.svg';
import cross from '../../../events/Pages/search_icons/cross.svg';
import { media } from '../../media';
import { ColorsTheme, Sizes, themeColor } from '../../../components/Theme';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { dict } from '../../../constants/system_reference';

const Wrapper = styled.div`
    margin: 0 0 2rem 0;
    padding: 2rem 0;
    background-color: ${ColorsTheme.searchBackground};
    box-shadow: ${dict.spl.boxShadow.averageBottom} inset ${themeColor(c => c.blackShadow)};

    @media ${media.l} {
        padding: 1rem;
    }

    @media ${media.m} {
        margin: 0 0 14px 0;
    }
`;

const Content = styled.div`
    margin: auto;
    padding: 1rem 1.5rem;
    width: 728px;
    box-sizing: border-box;
    background-color: ${themeColor(c => c.whiteDefault)};
    box-shadow: ${dict.spl.boxShadow.average} ${themeColor(c => c.blackShadow)};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    

    @media ${media.l} {
        padding: 1rem 2rem;
        width: 90%;
    }

    @media ${media.m} { 
        padding: 0.5rem 1rem;
    }
`;

const SearchField = styled(TextField)`
    width: 90%;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }

    & .MuiInputBase-input {
        padding: 0;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`;

const IconTip = styled.img`
    position: relative;
    width: 16px;
    height: 16px;
    transition: 0.5s;
    cursor: pointer;
`;

const ScreenReader = styled.label`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: 400 17px 'Ubuntu';
    color: ${themeColor(c => c.textGreyBasic)};
`;


export const Search = ({ searchText, updateText }: { searchText : string, updateText: (x: string) => void }) => {
    const { formatMessage } = useIntl();
    return <Wrapper>
        <Content>
            <ScreenReader aria-label={formatMessage({ id: "addition.search" })}>
                <SearchField
                    value={searchText}
                    onChange={({ target }) => updateText(target.value)}
                    variant="outlined"
                    placeholder={`${formatMessage({ id: "addition.search" })}…`}
                    />
                {
                    searchText.length === 0
                        ? <IconTip src={magnifier} alt={formatMessage({ id: "addition.search" })}  />
                        : <IconTip src={cross} alt={formatMessage({ id: "addition.search" })} onClick = { () => updateText("") } />
                }

            </ScreenReader>
        </Content>
    </Wrapper>
}