import { Box, Popper, Select } from '@material-ui/core';
import styled from '@emotion/styled';
import { media } from '../../../media';
import { ColorsTheme, Sizes, themeColor } from '../../../../components/Theme';
import WarningAmberIcon from '@material-ui/icons/Warning';
import { dict } from '../../../../constants/system_reference';

export const getPositionColor = (position: number): string => {
  return ({
    "0": ColorsTheme.darkRedShade.s0,
    "1": ColorsTheme.darkRedShade.s1,
    "2": ColorsTheme.darkRedShade.s2,
    "3": ColorsTheme.darkRedShade.s3,
  } as Record<string, string>)[position] ?? ColorsTheme.darkRedShade.s4
};

export const BoxWrapper = styled(Box)`
  position: absolute;
  padding: 20px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${Sizes.geometry.borderRadius.pannel}px;
  background-color: ${themeColor(c => c.modalBackground)};
  color: ${themeColor(c => c.blackDefault)};
  word-break: break-word;

  @media ${media.l} {
    width: 350px;
  }

  @media ${media.m} {
    padding: 30px;
    width: 60%;
  }

  @media ${media.s} {
    width: 70%;
    max-height: 95svh;
    overflow: auto;
  }

  @media ${media.s} {
    padding: 20px;
    width: 75%;
    max-height: 95svh;
    overflow: auto;
  }
`;

export const ClearCandidates = styled.p<{ isVisible: boolean }>`
  margin: 5px 0 20px auto;
  width: max-content;
  text-align: right;
  color: ${themeColor(c => c.darkRedDefault)};
  cursor: pointer;
  visibility: ${props => props.isVisible ? "visible" : "hidden"};
`;

export const CandidateBox = styled.div<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  align-items: inherit;
  border: none;
  cursor: ${props => props.isDisabled ? "not-allowed" : "pointer"};
`;

export const CandidateName = styled.span`
  padding: 0.75rem 0;
`;

export const CandidateBox2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid ${ColorsTheme.alto};
  gap: 0.5rem;

  &:first-child {
    border-top: 1px solid ${ColorsTheme.alto};
  }

  & .MuiInput-root {
    
  }
`;

export const CandidatePositionSelect = styled(Select)<{ position?: string }>`
  padding-left: 0.5rem;
  background: ${props => isNaN(+(props.position || "dontvote")) ? "inherit" : getPositionColor(+(props.position || 0))};
  color: ${props => isNaN(+(props.position || "dontvote")) ? "inherit" : ColorsTheme.white};
  
  min-width: 130px;
  text-align: right;
  align-self: stretch;

  &:before, &:after {
    border: none;
  }
  &:hover {
    &:before, &:after {
      border: none !important;
    }
  }

  & .MuiSelect-select:focus {
    background: transparent;
  }
`;

export const CanidatesList = styled.div<{ isDisabled: boolean }>`
  border-top: 1px solid ${themeColor(c => c.alto)};
  border-bottom: 1px solid ${themeColor(c => c.alto)};
  ${props => props.isDisabled && "filter: grayscale(1)"}
`;

export const CanidatesList2 = styled.div<{ isDisabled?: boolean }>`
  ${props => props.isDisabled && "filter: grayscale(1)"}
`;

export const UnvotedCandidatesParagraph = styled.p`
  & > span:first-child {
    color: ${ColorsTheme.textGreyBasic};

  }
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const CandidatePositionNbr = styled.div<{ position: number }>`
  background-color: ${(props) => getPositionColor(props.position)};
  width: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  text-align: center;
  color: ${ColorsTheme.whiteDefault};
  font: 400 1rem 'Ubuntu';
`;

export const Order = styled.div<{ order: string }>`
  background-color: ${(props) => props.order};
  width: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${themeColor(c => c.whiteDefault)};
  font: 400 1rem 'Ubuntu';
`;

export const Title = styled.div<{ isVote?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px;
  padding: 0 25px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font: 500 20px 'Ubuntu';
  color: ${themeColor(c => c.blackDefault)};
  white-space: pre-line;

  @media ${media.m} {
    margin: 0 0 10px;
  }

  @media ${media.s} {
    margin: 0 0 10px;
    font-size: 18px;
  }
`;

export const CandidateChoose = styled.div`
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid ${themeColor(c => c.alto)};
`;

export const Name = styled.p<{ isDisabled: boolean, isChoosen: boolean }>`
  width: 70%;
  margin: 0;
  padding: 10px;
  display: flex;
  height: inherit;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font: ${props => props.isChoosen ? 500 : 300} 18px 'Ubuntu';
  cursor: ${props => props.isDisabled ? "not-allowed" : "pointer"};
  color: ${props => themeColor(c => !!props.isChoosen ? c.blackDefault : c.textGreyBasic)};
  border: none;
  outline: none;
  appearance: none;

  @media ${media.m} {
    padding: 10px 0;
    font-size: 16px;
  }

  @media ${media.s} {
    width: 70%;
    margin: 10px;
    padding: 0;
    font-size: 16px;
  }
`;

export const Buttons = styled.div`
  margin: auto;
`;

export const ChooseCandidate = styled(Popper)`
  z-index: 10001;
  top: 100%;
  position: absolute;
  width: 500px;
  height: auto;
  
  background-color: ${themeColor(c => c.alabaster)};
  border: 1px solid ${themeColor(c => c.alto)};
  
  @media ${media.l} {
    width: 350px;
  }

  @media ${media.s} {
    width: 70%;
  }
`;

export const Prompt = styled.p<{ isHidden?: boolean }>`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  color: ${themeColor(c => c.textGreyBasic)};  
  white-space: pre-line;
  visibility: ${props => props.isHidden ? "hidden" : "visible"};

  @media ${media.l} {
    margin: 10px 0;
  }

  @media ${media.m} {
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
  }

  @media ${media.s} {
    white-space: normal;
  }
`;


export const ChooseCandidateName = styled.p`
  position: relative;
  margin: auto 0;
  padding: 10px 0;
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  font: 500 1rem 'Ubuntu';
  word-break: break-word;

  @media ${media.l} {
    margin: auto 0;
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const ChooseCandidateNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${themeColor(c => c.alto)};
  cursor: pointer;
`;

export const Circumstances = styled.p`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 300;
  white-space: pre-wrap;

  @media ${media.m} {
    font-size: 13px;
  }
`;

export const DisclosureCandidate = styled.span`
  display: block;
  margin: 0.5rem 0;
  color: ${themeColor(c => c.darkRedDefault)};
  font-weight: 500;
`;

export const DownloadDescription = styled.p`
    margin: 0.5rem 0 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;

    font-weight: 400;
    color: ${themeColor(c => c.darkRedDefault)};
    cursor: pointer;
`;

export const DisclosureCard = styled.div`
    padding: 0.75rem;
    border-radius: 8px;
    background: ${themeColor(c => c.modalCardBackground)};
    font-size: 16px;
    margin-bottom: 1rem;
    box-shadow: ${dict.spl.boxShadow.ultraLightTop} ${themeColor(c => c.mineShaftShade.s3)};

    & > span:first-child {
        display: block;
        font-size: 14px;
    }

    
    & > div > p:first-child {
        margin-top: 0;
    }
    & > div > p:last-child {
        margin-bottom: 0;
    }
`;

export const Warning = styled(WarningAmberIcon)`
  margin: auto 10px auto 10px;
`;

export const Warning2 = styled(WarningAmberIcon)`
  fill: ${themeColor(c => c.darkRedDefault)};
`;

export const WarningWrapper = styled(Warning)`
  left: -40px;
  position: absolute;
  fill: ${themeColor(c => c.darkRedDefault)};
`;


export const WarningCandidate = styled(Warning) <{ hidden?: boolean }>`
  visibility: ${props => props.hidden ? "hidden" : "visible"};
  fill: ${themeColor(c => c.darkRedDefault)};

  @media ${media.s} {
    margin: auto 4px;
  }
`;