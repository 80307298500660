import { useEffect, useState } from 'react';
import { News, FeedEvent, FeedEventSocket } from '../../../api/types/main';
import { WebSocketHandle } from '../../../toolympus/hooks/useWebsocket';
import { usePrepFeedEvent } from '../../questions/hook/usePrepareFeedEvent';

export const useNews = (feedSocket: WebSocketHandle) => {
    const { send, socket, open } = feedSocket;
    
    const [news, setNews] = useState<News>({
        start: 0,
        news: [],
        feedLength: 0
    });

    const prepEvent = usePrepFeedEvent();

    const isEventHidden = (e: FeedEvent) => e.event_type === "message";

    const onMessage = (env: MessageEvent) => {
        const data = JSON.parse(env.data);
        if(data._type === "feed-full") {
            if(news.feedLength !== data.feed.length) {
                setNews((prevState) => ({
                    ...prevState,
                    news: [
                        ...news.news,
                        ...(data?.feed || [])
                            .map((event: FeedEventSocket) => prepEvent(event))
                            .filter((e: FeedEvent) => !isEventHidden(e)),
                    ],
                    feedLength: data.feed?.length || 0,
                }));
            }

        } else if(data._type === "event") {
            const eventProcessed = prepEvent(data.event);
            if(isEventHidden(eventProcessed)) {
                return;
            }

            setNews((prevState) => ({
                ...prevState,
                news: [
                    eventProcessed,
                    ...prevState.news,
                ],
                feedLength: 0
            }));
        }
    }

    useEffect(() => {
        if(socket) {
            send({ _type: "feed-full", start: news.start });
            socket.addEventListener("message", onMessage);
            return () => socket.removeEventListener("message", onMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, news.start, socket]);

    return {
        news: news.news.filter(nw => nw !== null),
        start: news.start,
        expandNews: () => {
            setNews((prevState) => ({ ...prevState, start: news.start + 100, feedLength: 0 }))
        }
    }
}