import React, { createContext, ReactNode, useContext, useState } from "react";
import { FeedEvent } from '../api/types/main';
import { QuestionTab } from '../api/types/questions';
import { useNews } from '../events/news/hook/useNews';
import { useFeedSocket } from "./FeedContext";

interface Props {
    children: ReactNode;
}

export interface QuestionContextConfig {
    questionInfo: Record<"_id" | "casenbr", null | string>,
    setQuestionInfo: React.Dispatch<React.SetStateAction<Record<"_id" | "casenbr", null | string>>>,
    news: FeedEvent[],
    expandNews: () => void,
    questionTab: QuestionTab | null,
    selectTab: (tab: QuestionTab) => void
}

const QuestionContext = createContext<QuestionContextConfig | any>({
    setQuestionId: () => "",
    questionId: ""
});

export const QuestionProvider = ({ children }: Props) => {
    const [questionInfo, setQuestionInfo] = useState<{ _id: null | string,  casenbr: null | string}>({
        _id: "",
        casenbr: ""
    });
    const feedSocket = useFeedSocket();
    const { news, expandNews } = useNews(feedSocket);
    const [questionTab, setQuestionsTab] = useState<QuestionTab | null>(null);
    
    return <QuestionContext.Provider value={{
        questionInfo, setQuestionInfo, news, expandNews, questionTab, selectTab: (tab: QuestionTab) => setQuestionsTab(tab)
    }}>
        {children}
    </QuestionContext.Provider>
}

export const useQuestion = () => useContext(QuestionContext);