import { MenuItem, Modal } from '@material-ui/core';
import React from 'react';
import { Loader } from '../../../../common/Loader';
import { ModalWindowCaseSettingsExt } from '../../modal_window';
import { Feedback, ModalWindowButton, ModalWindowButtons } from '../../../candidates/Candidate.style';
import { dict } from '../../../../constants/system_reference';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseModalWindow, TextareaComments } from '../../Controls';
import { MarkdownDisplay } from '../../../../toolympus/components/schemed/Markdown';
import { useVoteState } from './useVoteState';
import { getCandidateName } from '../../../candidates/CandidatePerson';
import { useLocale } from '../../../../toolympus/components/localization';
import { GetAppOutlined } from '@material-ui/icons';
import {
  BoxWrapper,
  Buttons,
  CandidateBox2,
  CandidateName,
  CandidatePositionSelect,
  CanidatesList2,
  Circumstances,
  ClearCandidates,
  DisclosureCandidate,
  DisclosureCard,
  DownloadDescription,
  Prompt,
  Title,
  Warning2,
} from './Vote.styles';
import { OccupyFreeSpace } from '../../../../toolympus/components/primitives';
import { CandidateVotePreview } from './CandidateVotePreview';

export const VoteCandidateNew = ({ params, closeModalWindow }: ModalWindowCaseSettingsExt) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();

  const {
    voteConfig,
    candidatePositionOptions,
    castVote,
    startRevote,
    candidatePositions,
    chooseCandidate2,
    clearCandidates,
    hasSelectedCandidates,

    updateComment,

    downloadDisclosureDocument,

    candidateHasDisclosure,
    getCandidateNameById,
    hasSelectedSomething,
    canGoToPreview,
    isPreview,
    goToPreview,
    cancelPreview,
  } = useVoteState({ params, closeModalWindow });

  const isViewExistingVote = voteConfig.isVote;


  return <Modal
    open
    onClose={closeModalWindow}>
    <BoxWrapper>
      <CloseModalWindow closeModalWindow={closeModalWindow} />
      <Title isVote={isViewExistingVote}>
        {isViewExistingVote
            ? <FormattedMessage id="feedback.youVoted" />
            : <FormattedMessage id="modalWindow.voting" />}
        </Title>

      {!(isViewExistingVote || isPreview) && <Prompt>
        <FormattedMessage id="feedback.optionalCandidates" />
      </Prompt>}

      <Circumstances>
        {params.candidates
            .filter(candidate => candidate.confirmation_status === dict.disclosure)
            .map(candidate =>
              <DisclosureCard key={candidate._id}>
                <span><FormattedMessage id="service.circumstances" /></span>

                <DisclosureCandidate>{getCandidateName(locale, candidate)} </DisclosureCandidate>
                
                {candidate.disclosure_details && <MarkdownDisplay text={candidate.disclosure_details} />}


                {candidate.disclosure_document_id && <DownloadDescription onClick={() => downloadDisclosureDocument(candidate)}>
                    <GetAppOutlined /> <FormattedMessage id="service.downloadDisclosureDescription" />
                </DownloadDescription>}
              </DisclosureCard>
            )
        }
      </Circumstances>

      {params.candidates.length <= 1 && <p><FormattedMessage id="addition.emptyCandidates" /></p>}
      {!isPreview && !isViewExistingVote &&
        <CanidatesList2 isDisabled={isViewExistingVote}>
            {voteConfig.candidates.map((candidate,idx) => (
              <CandidateBox2 key={candidate}>
                <CandidateName>
                  {getCandidateNameById(candidate)}
                </CandidateName>
                {candidateHasDisclosure(candidate) && <Warning2 />}

                <OccupyFreeSpace />

                <CandidatePositionSelect
                  position={candidatePositions[candidate] || "empty"}
                  value={candidatePositions[candidate] || "empty"}
                  onChange={e => chooseCandidate2(candidate, e.target.value as string)}
                  >
                    <MenuItem value="empty" disabled><FormattedMessage id="voting.candidates.options.empty" /></MenuItem>
                  {candidatePositionOptions.map(([position, label]) => (
                    <MenuItem key={position} value={position}>{label}</MenuItem>
                  ))}
                </CandidatePositionSelect>
              </CandidateBox2>
            ))}
        </CanidatesList2>}

        {isPreview &&
          <CandidateVotePreview
            data={voteConfig}
            getCandidateNameById={getCandidateNameById}
            candidateHasDisclosure={candidateHasDisclosure}
            />}

        {isViewExistingVote &&
          <CandidateVotePreview
            data={voteConfig}
            getCandidateNameById={getCandidateNameById}
            candidateHasDisclosure={candidateHasDisclosure}
            />}

      <Buttons>
        {!isViewExistingVote && !isPreview &&
          <ClearCandidates
            isVisible={hasSelectedSomething}
            onClick={() => clearCandidates()}>
                <FormattedMessage id="service.clean" />
            </ClearCandidates>
        }

        {!isViewExistingVote &&
            <TextareaComments
                rows={3}
                value={voteConfig.comment}
                onChange={e => updateComment(e.target.value)}
                margin={"10px 0"}
                placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeComments" })}
            />
        }

        {voteConfig.isSubmitted && <Feedback isVisible>
          <FormattedMessage id="feedback.thxForVoting" />
        </Feedback>}

        <ModalWindowButtons voted={isViewExistingVote}>
          {isViewExistingVote && <>
            <ModalWindowButton
              isDeleted
              isTransparent
              onClick={() => startRevote()}>
              {voteConfig.isFetching
                ? <Loader type="button" />
                : <FormattedMessage id="service.revote" />}
            </ModalWindowButton>

            {voteConfig.isSubmitted && 
              <ModalWindowButton isTransparent onClick={() => closeModalWindow()}>
                  <FormattedMessage id="addition.close" />
              </ModalWindowButton>}
          </>}

          {!isViewExistingVote && !isPreview && <>
            <ModalWindowButton
              isTransparent={!canGoToPreview}
              isNotReady={!canGoToPreview}
              onClick={() => canGoToPreview && goToPreview()}>

              {voteConfig.isFetching
                ? <Loader type="button" />
                : <FormattedMessage id="modalWindow.choice.vote" />}
            </ModalWindowButton>

            {voteConfig.isSubmitted && 
              <ModalWindowButton isTransparent onClick={() => closeModalWindow()}>
                  <FormattedMessage id="addition.close" />
              </ModalWindowButton>}
              
              <ModalWindowButton isTransparent isHidden={isViewExistingVote} onClick={closeModalWindow}>
                  <FormattedMessage id="service.back" />
              </ModalWindowButton>
          </>}

          {!isViewExistingVote && isPreview && <>
            <ModalWindowButton
              isNotReady={!hasSelectedCandidates}
              onClick={() => castVote()}>
              {voteConfig.isFetching
                ? <Loader type="button" />
                : <FormattedMessage id="voting.confirm" />}
            </ModalWindowButton>

            {voteConfig.isSubmitted && 
              <ModalWindowButton isTransparent onClick={() => closeModalWindow()}>
                  <FormattedMessage id="addition.close" />
              </ModalWindowButton>}
              
              <ModalWindowButton isTransparent onClick={cancelPreview}>
                  <FormattedMessage id="service.back" />
              </ModalWindowButton>
          </>}
        </ModalWindowButtons>

      </Buttons>

    </BoxWrapper>
  </Modal>
};
