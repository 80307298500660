import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { FilterQuestion } from '../../../api/types/questions';
import { dict } from '../../../constants/system_reference';
import { media } from '../../media';
import { QuestionsList } from '../QuestionsList';
import { QuestionListData } from '../hook/useQuestionList';
import { TabTitle, TabsWrapper } from '../QuestionCase/QuestionTabs';

export const Questions = ({ data }: { data: QuestionListData }): React.ReactElement => {
  const { formatMessage } = useIntl();

  return <Wrapper>
    {data.searchText.length === 0 && (
      <TabsWrapper widthFactor={2} showOnSmall>
        {[
          [dict.current, formatMessage({ id: "service.current" })],
          [dict.past, formatMessage({ id: "service.past" })],
        ].map(([link,t], id : number) =>
            <TabTitle
              key={id}
              onClick={() => {
                if (!(data.isLoading || data.view as FilterQuestion === link)) {
                  data.setView(link as "current" | "past")
                }
              }}
              isactive={link === data.view as FilterQuestion}>
                {t}
            </TabTitle>)
        }
      </TabsWrapper>
    )}

    <QuestionsList data={data} />
  </Wrapper>
}


const Wrapper = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media.l} {
    width: calc(100% - 20px);
  }

  @media ${media.m} {
    padding: 0;
  }
`
