import { CandidateName, CandidateQuestion, QuestionStage } from './candidates';
import { CandidateResponse, FileQuestion, QuestionCaseInfo, QuestionsListProps, QuestionStatus, Tin } from './questions';

export interface SearchSettings {
    searchText: string,
    searchTextApplied: string,
}

export interface QuestionResult {
    candidates: [];
    options?: QuestionOption[];
}

export interface QuestionOption {
    id: number;
    label: string;
    label_en: string;
    score?: number | null;
    win?: boolean;
}

export interface Question {
    _id: string,
    question_type: string;
    is_candidates_question: boolean;
    candidates_can_suggest_options?: boolean;
    stages: QuestionStage[],
    is_closed: boolean,
    candidates: CandidateQuestion[],
    case_info?: QuestionCaseInfo | null,
    options?: QuestionOption[] | null | undefined;
    comments: string,
    active_stage: QuestionStage,
    title: string,
    start_date: string;
    vote_end_date: string,
    vote_start_date: string,
    end_date: string;
    documents: FileQuestion[],
    is_finished: boolean,
    status: QuestionStatus,
    positions: number,
    responses: CandidateResponse[],
    result: QuestionResult,
    related_questions: any,
    notifications_mode?: string;
}

export const QuestionType_Jury = "arbitration_jury_formation";
export const QuestionType_NoVote = "no_vote_question";
export const QuestionType_Other = "other";

export type QuestionsPresentation = QuestionsListProps & {
    isLoading: boolean,
    changeTypeOfFilter: (button: Tin) => void,
}

export interface QuestionItem {
    question: Question,
}

export interface QuestionItemTimeTable {
    content: string,
    time: string,
}

export type FeedEventType =
    "abstain" |
    "vote" |
    "against-all" |
    "conflict" |
    "question_closed_down" |
    "suggest_candidate" |
    "question-launched" |
    "message" |
    "candidate-confirmation-status" |
    "admin-message" |
    "question-closed" |
    "suggest_option";

export interface FeedEventSocket {
    data: any | null,
    event_type: FeedEventType,
    question_id: string,
    source_display_name: string,
    source_display_name_en?: string,
    source?: CandidateName,
    source_id: string | null,
    source_user_id: string,
    source_type: "member" | "employee" | "system",
    time: string,
    visiblity: any | null,
    _id: string,
    selected: {
        middlename: string,
        firstname: string,
        lastname: string
    }[],
    is_require_attention: boolean,
    question_title: string
}

export interface FeedEvent {
    _id: string;
    question_id: string;
    question_title: string;
    time: string;
    text: string;
    attachment_file_id?: string;
    display_text: null | string;
    isMyMessage: boolean;
    sender: string;
    event_type: FeedEventType;
    isEvent: boolean;
    isWarning: boolean;
    candidates?: string[] | null;
}

export interface News {
    start: number,
    news: FeedEvent[] | [],
    feedLength: number
}
