import React, { useState } from "react";
import styled from "@emotion/styled";
import { getMemberName, useAuthorizedUser } from '../../../useContext/AuthorizedUserContext';
import { media } from '../../media';
import { themeColor } from '../../../components/Theme';
import { useIntl } from 'react-intl';
import { dict } from "../../../constants/system_reference";
import { useLocale } from "../../../toolympus/components/localization";

export const Footer = (props: { logout: () => void }) => {
    const user = useAuthorizedUser();
    const [contact, setContact] = useState(false);
    const { formatMessage } = useIntl();
    const { locale } = useLocale();
    
    return <Wrapper>
        <Content>
            <Contacts>
                <Text>{formatMessage({ id: "information.contacts.address" })}</Text>
                <Text href={`mailto:${formatMessage({ id: "information.contacts.mail" })}`} target='_blank' rel="nofollow noopener">
                    {formatMessage({ id: "information.contacts.mail" })}
                </Text>
                
                <Text href={`tel:${formatMessage({ id: "information.contacts.phonePlaceholder" })}`}>
                    {formatMessage({ id: "information.contacts.phone" })}
                </Text>
            </Contacts>

            <Other>

                <Navigation>
                    <NavigationDefault>
                        <Text href="/">{formatMessage({ id: "information.menu.main" })}</Text>
                        <Text href="https://centerarbitr.ru/" target='_blank' rel='noopener noreferrer'>{formatMessage({ id: "information.menu.name" })}</Text>
                        <Text href="https://centerarbitr.ru/text/" target='_blank' rel='noopener noreferrer'>{formatMessage({ id: "information.menu.regulations" })}</Text>
                    </NavigationDefault>

                    <NavigationMobile>
                        <Text href="/">{formatMessage({ id: "information.menu.main" })}</Text>
                        <Text onClick={() => {
                            setContact(true);
                        }}>{formatMessage({ id: "information.menu.contacts" })}</Text>
                        <Text href="https://centerarbitr.ru/" target='_blank' rel='noopener noreferrer'>{formatMessage({ id: "information.menu.name" })}</Text>
                        <Text href="https://centerarbitr.ru/text/" target='_blank' rel='noopener noreferrer'>{formatMessage({ id: "information.menu.regulations" })}</Text>
                        <Text>{formatMessage({ id: "information.menu.results" })}</Text>
                        <Text>{formatMessage({ id: "information.menu.chat" })}</Text>
                    </NavigationMobile>
                </Navigation>

                <Functionality>
                    <UserName>{getMemberName(locale, user)}</UserName>
                    <Logout onClick={props.logout}>{formatMessage({ id: "service.logout" })}</Logout>
            
                    <CopyRight>
                        {formatMessage({ id: "service.copyright"}).replace("{YEAR}", new Date().getFullYear().toString())}
                    </CopyRight>
                </Functionality>

            </Other>
        </Content>

        <NavigationMenuMobile active = {contact}>
            <Text>{formatMessage({ id: "information.contacts.address" })}</Text>
            <Text href={`mailto:${formatMessage({ id: "information.contacts.mail" })}`} target='_blank' rel="nofollow noopener">
                {formatMessage({ id: "information.contacts.mail" })}
            </Text>
            <Text href={`tel:${formatMessage({ id: "information.contacts.phonePlaceholder" })}`}>
                {formatMessage({ id: "information.contacts.phone" })}
            </Text>
        </NavigationMenuMobile>
    </Wrapper>
}

const Wrapper = styled.div`
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    background-color: ${themeColor(c => c.footerBackground)};
    box-shadow: ${dict.spl.boxShadow.heavyUp} ${themeColor(c => c.blackShadow)};

    @media ${media.s} {
        display: none;
    }
`;

const NavigationMenuMobile = styled.div<{ active: boolean }>`
    height: 0;
    overflow: hidden;

    @media ${media.m} {
        margin: 0 0 ${props => props.active ? 20 : 0}px 0;
        width: calc(95% - 40px);
        height: ${props => props.active ? "100%" : 0};
        transition: 0.6s;
    }
`;

const Content = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    width: 80%;
    
    @media ${media.l} {
        width: 95%;
    }
`;

const Contacts = styled.div`
    margin-right: 1%;
    width: 50%;

    @media ${media.m} {
        display: none;
    }
`;

const Other = styled.div`
    padding-left: 1rem;
    height: 100%;
    width: 50%;
    display: flex;

    border-left: 1px solid ${themeColor(c => c.borderBasic)};

    @media ${media.m} {
        padding: 0;
        margin: auto;
        width: 90%;
        justify-content: space-around;
        border: none;
    }

    @media ${media.xs} {
        display: flex;
        flex-direction: column;
        padding-left: 5%;
    }
`;

const Functionality = styled.div`
    width: 50%;
    text-align: right;

    @media ${media.s} {
        & >:nth-child(n+2) {
            display: none;
        }
    }

    @media ${media.xs} {
        text-align: left;
    }
`;

const UserName = styled.a`
    display: block;
    width: 100%;
    font: 400 17px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;
`;

const Navigation = styled.div`
    width: 50%;
`;

const Text = styled.a<{ cursor?: string }>`
    width: auto;
    display: block;
    font: 400 17px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    cursor: ${ props => props.cursor === "true" ? "default" : "pointer" };
    text-decoration: none;

    &:not(:first-child) {
        margin-top: 10px;
    }
`;

const Logout = styled(Text)`
    font-weight: 500;
    color: ${themeColor(c => c.darkRedDefault)};
`;

const CopyRight = styled.span`
    font: 400 12px 'Ubuntu';
    color: ${themeColor(c => c.textGreyBasic)};
    margin-top: 10px;
    display: block;
`;

const NavigationDefault = styled.div`
    @media ${media.m} {
        display: none;
    }
`;

const NavigationMobile = styled.div`  
    display: none;
    @media ${media.m} {
        display: flex;
        flex-direction: column;
        border-right: 1px solid ${themeColor(c => c.borderBasic)};
    }

    @media ${media.s} {
        width: 90%;

        & >:nth-child(n+2) {
            display: none;
        }
    }

    @media ${media.xs} {
        width: auto;
        border: none;
    }
`;