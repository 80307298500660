import React from 'react';
import styled from "@emotion/styled";
import { TextareaAutosize, TextField } from "@material-ui/core";
import { themeColor } from "../../components/Theme";
import { media } from "../media";
import { dict } from "../../constants/system_reference";

import CloseIconX from '../Pages/modal_window_icons/close_mw.svg';
import { useIntl } from 'react-intl';

export const CloseIcon = CloseIconX;

export const TextInput = styled(TextField)<{ fullWidth?: boolean }>`
    margin-top: 5px;
    padding: 10px;
    width: ${props => props.fullWidth ? "100%" : "initial"};
    box-sizing: border-box;
    border: 0.5px solid ${themeColor(c => c.catskillWhite)};
    border-radius: 1px;
    font: 300 18px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    box-shadow: ${dict.spl.boxShadow.light} ${themeColor(c => c.chambrayShadow)},${dict.spl.boxShadow.heavy} ${themeColor(c => c.chambrayShadow)};
    background: ${themeColor(c => c.candidatesBackground)};

    @media ${media.l} {
        margin-top: 5px;
    }

    & > .MuiInput-underline {
        &:before {
            border: none;
        }

        &:after {
            border: none;
        }

        &:hover {
            border: none;
            
            &:before {
                border: none;
            }
        }
    }
`;

export const TextareaComments = styled(TextareaAutosize)<{ margin?: string }>`
    display: flex;
    width: 100%;
    ${props => props.margin ? `margin: ${props.margin};` : ""}
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    font: 300 16px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
    background: ${themeColor(c => c.candidatesBackground)};
    box-shadow: ${dict.spl.boxShadow.ultraLight} ${themeColor(c => c.mineShaftShade.s3)} inset, ${dict.spl.boxShadow.ultraLightTop} ${themeColor(c => c.mineShaftShade.s2)} inset;

    &:focus, &:active {
      box-shadow: ${dict.spl.boxShadow.ultraLight} ${themeColor(c => c.mineShaftShade.s1)} inset, ${dict.spl.boxShadow.ultraLightTop} ${themeColor(c => c.burntUmberShadowLighter)} inset;
    }

    border: 0.5px solid ${themeColor(c => c.catskillWhite)};
    outline: none;
    resize: none;

    &::placeholder {
        padding: 0;
        margin: auto;
        color: ${themeColor(c => c.textGreyBasic)};
        font: 300 1rem "Ubuntu";
    }
`;


export const CloseButton = styled.img`
    position: absolute;
    right: 25px;
    top: 25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    @media ${media.l} {
      top: 15px;
      right: 15px;
    }

    @media ${media.xs} {
      right: 15px;
      top: 15px;
    }
`;
CloseButton.defaultProps = { src: CloseIcon };

export const CloseModalWindow = ({ closeModalWindow } : { closeModalWindow : () => void }) => {
    const { formatMessage } = useIntl();
    
    return <CloseButton alt={formatMessage({ id: "addition.close" })} onClick={closeModalWindow} />
}
