import React from 'react';
import { QuestionOption } from '../../api/types/main';
import { useLocale } from '../../toolympus/components/localization/LocalizationProvider';

interface Props {
    option?: QuestionOption;
}

export const OptionLabel = ({ option }: Props) => {
    const { locale } = useLocale();

    return <>{(option?.label && (locale === "en" ? option.label_en : option.label)) || ""}</>;
}
