import React from 'react';
import styled from '@emotion/styled';
import LogoImg from '../../logo.svg';
import LogoImgEn from '../../logo_en.svg';
import LogoImg2 from '../../logo_w.svg';
import { useLocale } from '../../toolympus/components/localization';

export const LogoX = styled.img<{version?: 1 | 2}>`
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 1.5rem;
`;

export const Logo = (props: {version?: 1 | 2} & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { locale } = useLocale();
  return (
    <LogoX version={props.version} src={props.version === 2 ? (locale === "en" ? LogoImgEn : LogoImg) : LogoImg2} alt="RAC" {...props} />
    )
}
