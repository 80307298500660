import React, { useState } from 'react';
import { decodeToken, getApiToken } from './toolympus/api/core';
import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { BasePage } from './components/BasePage';
import { MainPage } from './events/Pages/MainPage';
import { Route } from 'react-router-dom';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { AuthorizedUserProvider } from './useContext/AuthorizedUserContext';
import { QuestionPage } from './events/Pages/QuestionPage';
import { NewsPage } from './events/Pages/NewsPage';
import { QuestionChatProvider } from './useContext/QuestionChatProvider';
import { QuestionProvider } from './useContext/QuestionProvider';
import { FeedProvider } from './useContext/FeedContext';
import { useLocale } from './toolympus/components/localization';
import { DevModeSwitchPage } from './DevModeSwitchPage';
import { DocumentationPage } from './Documentation';

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });
    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { locale } = useLocale();
    
    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <AuthorizedUserProvider>
                <SchemaProvider>
                    <FeedProvider>
                        <QuestionProvider>
                            <QuestionChatProvider>
                                <DictionariesProvider apiPath="/api/dictionary" lang={locale}>
                                    <AppWrapper>
                                        
                                        <BasePage>
                                            <Route exact path={"/"} component={MainPage} />
                                            <Route exact path={"/news"} component={NewsPage} />
                                            <Route exact path={"/question/:id"} component={QuestionPage} />
                                            <Route exact path={"/documentation/:slug"} component={DocumentationPage} />
                                            <Route exact path="/devmode" component={DevModeSwitchPage} />
                                        </BasePage>
                                    </AppWrapper>
                                </DictionariesProvider>
                            </QuestionChatProvider>
                        </QuestionProvider>
                    </FeedProvider>
                </SchemaProvider>
            </AuthorizedUserProvider>
        </UserContext.Provider>
    );
};

export default AppRouter;
