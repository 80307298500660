import React from 'react';
import styled from '@emotion/styled';
import { Modal } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { Feedback, ModalWindowButton, ModalWindowButtons } from '../../../candidates/Candidate.style';
import { ModalWindowCaseSettingsExt } from '../../modal_window';
import { BoxWrapper, Buttons, Title } from './Vote.styles';
import { Loader } from '../../../../common/Loader';
import { themeColor } from '../../../../components/Theme';
import { Check } from '@material-ui/icons';
import { useOptionVote } from './useOptionVote';
import { dict } from '../../../../constants/system_reference';
import { CloseModalWindow, TextareaComments } from '../../Controls';
import { useLocale } from '../../../../toolympus/components/localization/LocalizationProvider';

const OptionButton = styled.div<{ isSelected?: boolean }>`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 0.5rem;
    margin: 0.25rem 0;
    min-height: 1.5rem;
    
    background: ${props => props.isSelected ? themeColor(c => c.darkRedDefault) : "transparent"};
    color: ${props => props.isSelected ? themeColor(c => c.whiteDefault) : themeColor(c => c.blackDefault)};
    border: 2px solid ${themeColor(c => c.darkRedDefault)};
    border-radius: 4px;

    font-weight: 400;
    cursor: pointer;

`;

interface Props extends ModalWindowCaseSettingsExt {
    toSuggestOption: () => void;
}

export const VoteOptions = ({ params, closeModalWindow, toSuggestOption }: Props) => {
    const { formatMessage } = useIntl();
    const {
        voteState,
        selectOption,
        setComment,
        canCastVote,
        castVote,
        isLoading,
        isSubmitted,
    } = useOptionVote({
        question: params.data,
        setQuestion: params.setData,
        onVoted: () => {
            params.disableVoting();
            params.hideCandidateButtons(dict.button.voting);
        }
    });

    const { locale } = useLocale();

    const alreadyVoted = params.data.status.is_voted;

    return (
        <Modal
            open={true}
            onClose={closeModalWindow}>
            <BoxWrapper>
                <CloseModalWindow closeModalWindow={closeModalWindow} />
                <Title isVote={alreadyVoted}>
                    {alreadyVoted ? <FormattedMessage id="feedback.youVoted" /> : <FormattedMessage id="modalWindow.voting_options_title" />}
                </Title>

            {(params.data.options || []).map(option => (
                <OptionButton
                    key={option.id}
                    isSelected={option.id === voteState.selected_option_id}
                    onClick={() => !(isLoading || isSubmitted) && selectOption(option.id === voteState.selected_option_id ? null : option.id)}>
                    {locale === "en" ? option.label_en : option.label}
                    {option.id === voteState.selected_option_id && <Check />}
                </OptionButton>
            ))}

            <Buttons>
                {<TextareaComments
                    disabled={isLoading || isSubmitted}
                    rows={3}
                    value={voteState.comment}
                    onChange={e => setComment(e.target.value)}
                    margin={"10px 0"}
                    placeholder={formatMessage({ id: "modalWindow.suggestCandidate.writeCommentsPlaceholder" })}
                />
                }

                <ModalWindowButtons voted={alreadyVoted}>
                    {!isSubmitted && params.data.active_stage?.permissions?.suggest &&
                        <ModalWindowButton
                            isTransparent
                            onClick={toSuggestOption}>
                            <FormattedMessage id="modalWindow.suggest_option.title" />
                        </ModalWindowButton>}

                    {!isSubmitted &&
                        <ModalWindowButton
                            isNotReady={!canCastVote}
                            disabled={!canCastVote}
                            onClick={castVote}>

                            {isLoading
                                ? <Loader type="button" />
                                : alreadyVoted ? <FormattedMessage id="service.revote" /> : <FormattedMessage id="modalWindow.choice.vote" />
                            }
                        </ModalWindowButton>}

                    {isSubmitted &&
                        <ModalWindowButton
                            isTransparent
                            onClick={closeModalWindow}>
                                <FormattedMessage id="service.back" />
                        </ModalWindowButton>}
                </ModalWindowButtons>

                {isSubmitted && <Feedback isVisible={isSubmitted}>
                    <FormattedMessage id="feedback.thxForVoting" />
                </Feedback>}
            </Buttons>

            </BoxWrapper>
        </Modal>
    );
}
