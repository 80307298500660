import React from "react";
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { media } from '../events/media';
import { themeColor } from '../components/Theme';
import { useIntl } from 'react-intl';

type LoaderStyles = { type: string, size: number, bg: string, border: string, borderTop: string };
export const Loader = ({ type } : { type: "button" | "default" }) =>  {
    const { formatMessage } = useIntl();
    const defineLoaderType = (type : string) : LoaderStyles => {
        switch(type) {
            case "button":
                return {
                    type,
                    size: 20,
                    bg: `transparent`,
                    border: `2px solid ${themeColor(c => c.whiteDefault)}`,
                    borderTop: `${themeColor(c => c.burntUmber)}`
                }
 
            default:
                return {
                    type,
                    size: 90,
                    bg: `${themeColor(c => c.whiteDefault)}`,
                    border: `6px solid ${themeColor(c => c.burntUmber)}`,
                    borderTop: `${themeColor(c => c.white)}`
                }
        }
    }

    return <>
        <CircleWrapper  
        aria-label={formatMessage({ id: "addition.loader"})}
        type = { defineLoaderType(type) }></CircleWrapper>
    </>
}


const spin = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`

const CircleWrapper = styled.div<{ type: LoaderStyles }>`
    margin: ${ props => props.type.type === "default" ? "5% auto 30% auto" : "auto"};
    width: ${props => props.type.size }px;
    height: ${props => props.type.size }px;
    background-color: ${ props => props.type.bg };
    border: ${ props => props.type.border };
    border-top-color: ${ props => props.type.borderTop };
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;

    @media ${media.m} {
        margin-bottom: ${ props => props.type.type === "default" ? "100%" : "auto"};
        width: ${props => props.type.size }px;
        height: ${props => props.type.size }px;
    }
`;