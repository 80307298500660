import React, { useState } from "react";
import styled from '@emotion/styled';
import logo from "../../../events/Pages/common_icons/arrow_down.svg";
import { themeColor } from '../../../components/Theme';
import { media } from '../../media';
import { FormattedMessage } from 'react-intl';
import { MarkdownDisplay } from '../../../toolympus/components/schemed/Markdown';

export const UncoverQuestionDescription = ({ comments, breakPoint }: { comments: string, breakPoint: number }) => {
    const [isShowExcerpt, setFullDescription] = useState(true);

    const findFirstSpace = (breakPoint: number): number =>
        breakPoint <= comments.length && /\s/.test(comments[breakPoint]) ? breakPoint : findFirstSpace(++breakPoint);

    const excerpt = comments.slice(0, findFirstSpace(breakPoint)) + "...";

    return <Description>
        <MarkdownDisplay text={isShowExcerpt ? excerpt : comments} />
        <MoreButton onClick={() => setFullDescription(x => !x)}>
            <FormattedMessage id={isShowExcerpt ? "service.more" : "service.hide" } />
            <img src={logo} alt="" style={{ transform: isShowExcerpt ? undefined : "rotate(180deg)"}} />
        </MoreButton>
    </Description>
}

const Description = styled.p`
    font: 300 1rem "Ubuntu";
    word-break: break-word;
    color: ${themeColor(c => c.blackDefault)};

    @media ${media.m} {
        font-size: 14px;
    }
`;

const MoreButton = styled.button`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: transparent;
    color: ${themeColor(c => c.buttonBlueText)};
    font: 300 14px "Ubuntu";
    cursor: pointer;
`;