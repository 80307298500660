import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Tin } from '../../../api/types/questions';
import { themeColor } from '../../../components/Theme';
import { EntityText } from '../Questions.style';

export const QuestionCaseEntityLine = ({title, inn} : Tin) => {
    const { formatMessage } = useIntl();

    return <Content>
        <Line>
            <EntityText>
                {formatMessage({ id: "question.claimantsTitle" })}
            </EntityText>
            
            <EntityText>
                {title}
            </EntityText>
        </Line>

        <Line>
            <EntityText>
                {formatMessage({ id: "question.inn" })}
            </EntityText>

            <EntityText>
                {inn}
            </EntityText>
        </Line>
    </Content>
}


const Content = styled.div`
    width: 100%;
`;

const Line = styled.div`
    margin-bottom: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:nth-child(2) {
        padding: 0 0px 10px 0;
        border-bottom: 1px solid ${themeColor(c => c.question_case.line)};
    }
`;
