import React from "react";
import styled from '@emotion/styled';
import { Sizes, themeColor } from '../../../components/Theme';
import { UncoverQuestionDescription } from './UncoverQuestionDescription';
import { media } from '../../media';
import { DownloadFile } from '../../../common/DownloadFile';
import { QuestionCaseDescriptionProps } from '../../../api/types/questions';
import { FormattedMessage } from 'react-intl';
import { MarkdownDisplay } from '../../../toolympus/components/schemed/Markdown';

const BREAK_POINT_FOR_DESCRIPTION = 245;

export const QuestionCaseDescription = ({ comments, documents, questionId }: QuestionCaseDescriptionProps) => {
    return <Wrapper>
        {!!comments &&
            <Content>
                <Title><FormattedMessage id="service.description" /></Title>
                <DescriptionFull>
                    <MarkdownDisplay text={comments} />
                </DescriptionFull>

                <Description>
                    {(comments?.length || 0) - 1 <= BREAK_POINT_FOR_DESCRIPTION 
                        ? <MarkdownDisplay text={comments} /> 
                        : <UncoverQuestionDescription comments={comments} breakPoint={BREAK_POINT_FOR_DESCRIPTION} /> 
                    }
                </Description>
            </Content>}

        <Files>
            {documents.map(d => <DownloadFile file={d} questionId={questionId} key={d._id} />)}
        </Files>

    </Wrapper>
}

const Wrapper = styled.div`
    width: 60%;
    @media ${media.l} {
        width: 100%;
    }
`;

const Title = styled.p`
    margin: 0;
    font: 400 20px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};

    @media ${media.m} {
        text-align: center;
    }
`;

const Description = styled.p`
    display: none;
    font: 300 1rem "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};

    @media ${media.m} {
        display: block;
        font-size: 14px;
    }
`;

const Files = styled.div`
    margin: 0;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @media ${media.l} {
        gap: 20px;
    }

    @media ${media.m} {
        padding: 5%;
        width: auto;
        gap: 5px 10px;
        background-color: ${themeColor(c => c.question_case.descriptionBackground)};
    }

    @media ${media.s} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${media.xs} {
        grid-template-columns: 1fr;
    }
`;

const Content = styled.div`
    width: auto;
    margin: 0 0 20px 0;
    padding: 5%;
    background-color: ${themeColor(c => c.question_case.descriptionBackground)};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    word-break: break-word;

    @media ${media.m} {
        margin: 2rem 0 0 0;
    }
`;

const DescriptionFull = styled.p`
    font: 300 1rem "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};

    @media ${media.m} {
        display: none;
    }
`;