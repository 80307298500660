import { useIntl } from "react-intl";
import { CandidateQuestion } from "../../../api/types/candidates";
import { FeedEvent, FeedEventSocket } from "../../../api/types/main";
import { PresidiumMember } from "../../../api/types/questions";
import { useLocale } from "../../../toolympus/components/localization/LocalizationProvider";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { getMemberName } from "../../../useContext/AuthorizedUserContext";
import { getCandidateName } from "../../candidates/CandidatePerson";

export type Candidate = { [x: string]: Pick<CandidateQuestion, "firstname" | "middlename" | "lastname" | "firstname_en" | "lastname_en" | "middlename_en"> }

const inParensIfPresent = (v: string | null | undefined) => v ? `(${v})` : "";

const CandidateStatusMessages: Record<string, string> = {
    retreated: "feedback.confirmationRetreated",
    confirmed: "feedback.confirmationConfirmed",
    disclosure: "feedback.confirmationDisclosure",
}

export const getCandidateConfirmationStatus = (locale: string, message: FeedEventSocket): { id: string, values?: Record<string, any> } => {
    const messageId = CandidateStatusMessages[message.data?.confirmation_status as string || ""];
    if(messageId) {
        const cnd = message.data.candidate;
        return {
            id: messageId,
            values: { name: getCandidateName(locale, cnd) },
        };
    }
    return { id: "unknown" };
}


export const usePrepFeedEvent = () => {
    const { formatMessage } = useIntl();
    const { user } = useUser();
    const { locale } = useLocale();


    const getFilteredCandidates = (orderById: string[], candidates: Candidate) => {
        const newOrder: string[] = [];
        orderById?.forEach((id: string) => {
            /*
             * this condition for old cases when we had a problem with api
             * { "": {} } we fix that but however i will leave it here 
            */
            if (candidates[id as keyof Candidate]) {
                newOrder.push(`\t${getCandidateName(locale, candidates[id])}`);
            }
        });

        return newOrder;
    }

    const getSourceDisplayName = (message: FeedEventSocket) => {
        if(message.source?.lastname) {
            return getMemberName(locale, message?.source as PresidiumMember);
        }
        
        return (locale === "en" && message.source_display_name_en) || message.source_display_name;
    }

    const defineOutputType = (message: FeedEventSocket) => {
        switch(message.event_type) {
            case "candidate-confirmation-status":
                const descriptor = getCandidateConfirmationStatus(locale, message);
                return formatMessage({ id: descriptor.id }, descriptor.values);
            case "against-all":
                return `${getSourceDisplayName(message)} ${formatMessage({ id: "outputCases.against-all" })}`;
            case "abstain":
                return `${getSourceDisplayName(message)} ${formatMessage({ id: "outputCases.abstain" })}`;
            case "conflict":
                return `${getSourceDisplayName(message)} ${formatMessage({ id: "outputCases.conflict" })}`;
            case "question-launched":
                return formatMessage({ id: "outputCases.question-launched" });
            case "question_closed_down":
                return formatMessage({ id: "outputCases.question_closed_down" });
            case "admin-message":
                return message.data?.text;
            case "suggest_candidate":
                return `${getSourceDisplayName(message) || formatMessage({ id: "addition.unknownUser" })} ${formatMessage({ id: "outputCases.suggest_candidate" })}: ${message.data?.fullname}`;
            case "suggest_option":
                return `${getSourceDisplayName(message) || formatMessage({ id: "addition.unknownUser" })} ${formatMessage({ id: "outputCases.suggest_option" })}: ${message.data?.option?.label}`;
            case "vote":
                return `${getSourceDisplayName(message)} ${formatMessage({ id: `outputCases.${message.data?.is_vote_update ? "changedVote" : "vote"}`})}: ${message.data?.response?.vote ? getFilteredCandidates(message.data?.response?.vote, message.data?.candidates) : (locale === "en" ? message.data?.response?.option?.label_en : message.data?.response?.option?.label) || "-"} ${inParensIfPresent(message.data?.comment)}`;
            case "question-closed":
                return message.data?.selected
                ? `${formatMessage({ id: "outputCases.question_closed_candidates" })} ${getCandidateName(locale, message.data.selected[0])}`
                : `${formatMessage({ id: "outputCases.question_closed_options" })} ${(locale === "en" ? message.data?.selected_option?.label_en : message.data?.selected_option?.label) || "-"}`;
            
            case "message":
            default:
                return null;
        }
    }
    
    return (message: FeedEventSocket): FeedEvent => ({
        _id: message._id,
        question_id: message.question_id,
        question_title: message.question_title,
        event_type: message.event_type,
        time: message.time,
        text: message.data?.text,
        display_text: defineOutputType(message),
        
        sender: getSourceDisplayName(message),
        isMyMessage: message.source_user_id === user?._id,
        isEvent: message.event_type !== "message",
        isWarning: message.data?.is_require_attention,
        attachment_file_id: message.data?.attachment_file_id,
        
        candidates: message.data?.response?.vote?.length && Object.keys(message.data.response).length ? getFilteredCandidates(message.data.response?.vote, message.data.candidates) : null,
    });
}
