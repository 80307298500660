import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGrid, LoadingIndicator } from '../../../toolympus/components/primitives';
import { FormControl } from '../../../toolympus/components/schemed';
import { FieldSchema, FieldType } from '../../../toolympus/hooks/useSchema';
import { NotificationSettings } from './useNotificationSettings';

interface Props {
    data: NotificationSettings;
}

const NotificationsModeBaseSchema: FieldSchema = {
    label_id: "notifications.profile_notifications_mode",
    type: FieldType.select,
    values: [
        { value: "only-important-events", label: "" },
        { value: "all", label: "" },
    ]
};

const ControlsGrid = styled(FormGrid)`
    & .MuiSelect-root {
        width: 100%;
        white-space: break-spaces;
    }
`;


export const NotificationSettingsPopup = (props: Props) => {
    const { data } = props;
    return (
        <Dialog fullWidth maxWidth="sm" open={data.dialog.isOpen} onClose={data.dialog.close}>
            <DialogTitle><FormattedMessage id="notifications.settings_dialog_title" /></DialogTitle>
            <DialogContent>
                <ControlsGrid columns="1fr">
                    <Typography><FormattedMessage id="notifications.notifications_mode_section" /></Typography>
                
                    <FormControl
                        field="mode"
                        row={{ mode: data.questionNotificationsMode.value }}
                        onChange={(o,c) => data.questionNotificationsMode.update(c.mode)}
                        schema={{
                            ...NotificationsModeBaseSchema,
                            label_id: "notifications.question_notifications_mode",
                        }}
                        extraProps={{
                            labelIdPrefix: "notifications.notifications_mode",
                            controlProps: data.questionNotificationsMode.isUpdating ? { startAdornment: <LoadingIndicator sizeVariant="s" /> } : undefined,
                        }}
                        />
                    <FormControl
                        field="mode"
                        row={{ mode: data.profileNotificationsMode.value }}
                        onChange={(o,c) => data.profileNotificationsMode.update(c.mode)}
                        schema={{
                            ...NotificationsModeBaseSchema,
                            label_id: "notifications.profile_notifications_mode",
                        }}
                        extraProps={{
                            labelIdPrefix: "notifications.notifications_mode",
                            controlProps: data.profileNotificationsMode.isUpdating ? { startAdornment: <LoadingIndicator sizeVariant="s" /> } : undefined,
                        }}
                        />

                    <br />

                    <FormControl
                        field="language"
                        row={{ language: data.profileLanguage.value }}
                        onChange={(o,c) => data.profileLanguage.update(c.language)}
                        schema={{
                            type: FieldType.select,
                            label_id: "notifications.language",
                            values: [
                                { value: "ru", label: "" },
                                { value: "en", label: "" },
                            ]
                        }}
                        extraProps={{
                            labelIdPrefix: "notifications.notifications_language",
                            controlProps: data.profileLanguage.isUpdating ? { startAdornment: <LoadingIndicator sizeVariant="s" /> } : undefined,
                        }}
                        />

                </ControlsGrid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={data.dialog.close}>
                    <FormattedMessage id="addition.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
