import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { FeedEvent } from '../../api/types/main';
import { useListAutoexpander } from '../../toolympus/components/primitives/useListAutoexpander';
import { useQuestion } from '../../useContext/QuestionProvider';
import { NewsEventItem } from './NewsArticle';

export const News = () => {
    const { news, expandNews } = useQuestion();
    const autoExpander = useListAutoexpander(news, 10, 10);
    const events = useRef<any>(null);

    const latestEventId = news[0]?._id;

    useEffect(() => {
        if(latestEventId) {
            (Array.from(events.current.children)[0] as HTMLElement)?.scrollIntoView(({ behavior: "smooth" }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestEventId]);

    useEffect(() => {
        if(autoExpander.anchor === null && autoExpander.visibleItems.length) {
            expandNews();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoExpander.visibleItems.length,]);

    return <Wrapper ref={events}>
        {autoExpander.visibleItems.map((event: FeedEvent | any) => <NewsEventItem key={(event as FeedEvent)._id} {...event} />)}

        {autoExpander.anchor}
    </Wrapper>
}

const Wrapper = styled.div`
    margin: auto;
    width: 100%;
`;