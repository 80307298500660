import { Modal } from '@material-ui/core';
import React, { useState } from "react";
import styled from '@emotion/styled';
import { apiFetch, FetchTypes } from '../../../toolympus/api/core';
import { media } from '../../media';
import { Loader } from '../../../common/Loader';
import { Question } from '../../../api/types/main';
import { themeColor } from '../../../components/Theme';
import { ModalWindowCaseSettings, ModalWindowConfigByDefault } from '../modal_window';
import { Feedback, ModalWindowBoxWrapper, ModalWindowButton, ModalWindowButtons } from '../../candidates/Candidate.style';
import { useIntl } from 'react-intl';
import { dict } from '../../../constants/system_reference';
import { CloseModalWindow } from '../Controls';

export const Abstain = ({ params, closeModalWindow }: ModalWindowCaseSettings) => {
    const { formatMessage } = useIntl();
    
    const [abstainConfig, setAbstainConfig] = useState<ModalWindowConfigByDefault>({
        isVote: false,
        isLoading: false,
    });

    const AbstainConfirm = () => {
        setAbstainConfig((prevState) => ({ ...prevState, isLoading: true }));
        apiFetch<Question>(`/api/presidium-app/question/${params.id}/action/abstain`, FetchTypes.POST, {
            id: params.id,
        }).then(response => {
            params.setData(response);
            setAbstainConfig((prevState) => ({ ...prevState, isLoading: false, isVote: true }));
            params.hideCandidateButtons(dict.button.abstain);
        });
    }

    return <Modal
        open={true}
        onClose={closeModalWindow}>
        <ModalWindowBoxWrapper>
            <CloseModalWindow closeModalWindow={closeModalWindow} />
            <Title>
                {
                    abstainConfig.isVote 
                    ? formatMessage({ id: "modalWindow.successModalWindow" }) 
                    : formatMessage({ id: "modalWindow.abstain" })
                }
            </Title>

            <ModalWindowButtons voted={abstainConfig.isVote}>

                <ModalWindowButton onClick={AbstainConfirm} isHidden={abstainConfig.isVote}>
                    {abstainConfig.isLoading ? <LoaderWrapper type={"button"} /> : formatMessage({ id: "modalWindow.choice.accept" })}
                </ModalWindowButton>

                <ModalWindowButton onClick={closeModalWindow}>
                    {abstainConfig.isVote ? formatMessage({ id: "feedback.youAbstained" }) : formatMessage({ id: "modalWindow.choice.reject" })}
                </ModalWindowButton>

            </ModalWindowButtons>

            <Feedback margin = {"10px 0 0 0"} padding = {"0 0 10px 0"} isVisible = {abstainConfig.isVote}>
                {formatMessage({ id: "feedback.thxForVoting" })}
            </Feedback>
        </ModalWindowBoxWrapper>
    </Modal>
}


const LoaderWrapper = styled(Loader)`
    position: absolute;
`;

const Title = styled.p`
    margin: auto;
    width: 80%;
    height: auto;
    min-height: 80px;
    padding-bottom: 10px;
    font: 500 22px "Ubuntu";
    color: ${themeColor(c => c.blackDefault)};
    text-align: center;

    word-break: break-word;

    @media ${media.m} {
        margin-bottom: 2px;
        width: 100%;
    }

    @media ${media.s} {
        padding: 0;
        margin: 0;
        word-break: break-word;
    }
`;

