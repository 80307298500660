import React from 'react';
import { RestorePasswordForm } from '../../../toolympus/components/auth';
import { PublicPageWrapper } from '../../Common/PublicPageWrapper';

export const RestorePasswordContainer = () => {
    return (
        <PublicPageWrapper>
            <RestorePasswordForm restoreAPIPath="/api/restore-access/reset" successRedirect="/login" successRedirectTimeout={5000} />
        </PublicPageWrapper>
    );
}
