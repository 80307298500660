import { useEffect, useState } from 'react';
import { FeedEvent } from '../../../api/types/main';
import { WebSocketHandle } from '../../../toolympus/hooks/useWebsocket';
import { usePrepFeedEvent } from './usePrepareFeedEvent';

export interface MessageExtraData {
  attachment_file_id?: string;
}

export const useQuestionFeed = (feedSocket: WebSocketHandle, question_id: string, user_id: string) => {
    const { send, socket, open } = feedSocket;
    const [questionChat, setQuestionChat] = useState<FeedEvent[]>([]);

    const prepareEvent = usePrepFeedEvent();

    const getMessagesHistory = (data: any) => data?.feed?.map(prepareEvent);

    const onMessage = (env: MessageEvent) => {
        const data = JSON.parse(env.data);
        if (data.question_id === question_id) {
            if(data._type === "feed-question") {
                setQuestionChat(getMessagesHistory(data));
            } else if(data._type === "event") {
                setQuestionChat([prepareEvent(data.event), ...questionChat]);
            }
        }
    }

    useEffect(() => {
        if(socket) {
            socket.addEventListener("message", onMessage);

            if(question_id) {
                send({ _type: "feed-question", question_id, });
            }

            return () => socket.removeEventListener("message", onMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question_id, questionChat.length, open]);

    return {
        questionChat: [...questionChat].reverse(),
        sendMessage: (text: string, extra?: MessageExtraData) => send({ _type: "message", question_id, text, ...(extra || {}) })
    }
}
