import styled from '@emotion/styled';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Sizes, themeColor } from '../../components/Theme';
import SendIcon from '@material-ui/icons/Send';
import WarningIcon from "../Pages/common_icons/warning_deactivate_red.svg";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, Typography } from '@material-ui/core';
import { useQuestionChat } from '../../useContext/QuestionChatProvider';
import { useSnackbar } from 'notistack';
import { Tooltip } from '../../toolympus/components/primitives/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { media } from '../media';
import { Time } from './Questions.style';
import { useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';
import { ScrollbarStyle } from '../../components/BasePage';
import { FeedEvent, Question } from '../../api/types/main';
import { NotificationSettingsPopup } from './NotificationSettings/NotificationSettingsPopup';
import { useNotificationSettings } from './NotificationSettings/useNotificationSettings';
import { AttachFileOutlined, NotificationsOutlined } from '@material-ui/icons';
import { downloadFile } from '../../toolympus/api/core';
import { openFileUploader } from '../../toolympus/api/files';
import download_file from "../Pages/common_icons/download_file.svg";
import { Buttons } from '../../toolympus/components/primitives';
import { ChatAttachmentFileApiPath, useCreateAttachment } from './useCreateAttachment';

interface Props {
    question: Question;
}

export const QuestionChat = (props: Props) => {
    const [chatValue, setChatValue] = useState("");
    const { questionChat, sendMessage } = useQuestionChat();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const { formatDatetimeShort, formatDatetime } = useFormats();
    const messages = useRef<HTMLDivElement>(null);

    const notificationSettings = useNotificationSettings(props.question);
    const attachment = useCreateAttachment(sendMessage);

    const doSendMessage = () => {
        if(chatValue.length <= 0) {
            enqueueSnackbar(formatMessage({ id: "chat.message_empty" }), { variant: "error", autoHideDuration: 2000 })
        } else {
            sendMessage(chatValue);
            setChatValue("");
        }
    }

    useEffect(() => {
        (Array.from(messages.current?.children as HTMLCollection | undefined[])
            .slice(-1)[0] as HTMLElement)?.scrollIntoView(({ behavior: "smooth" }));
    }, [ questionChat.length ]);

    const AllMessages = () => <>
            {questionChat.map((message: FeedEvent) => {
                return <React.Fragment key={message._id}>
                    {message.isEvent
                        ? <>
                            {
                                <Event isWarning={message.isWarning}>
                                    {message.isWarning && <Warning key="warning-head" src={WarningIcon} />}
                                    {message.display_text}
                                    {message.isWarning && <Warning key="warning-tail" src={WarningIcon} />}
                                </Event>
                            }
                        </>
                        : message.attachment_file_id
                            ? (
                              <Tooltip text_id={formatDatetime(utc.toLocal(message.time))}>
                                  <Message isMy={message.isMyMessage}>
                                      <MessageHeadLine side={message.isMyMessage ? "right" : "left"}>
                                        {!message.isMyMessage && <UserName>{message.sender}</UserName>}
                                        {!message.isMyMessage && <div className="occupy" />}
                                        <Time>{formatDatetimeShort(utc.toLocal(message.time))}</Time>
                                      </MessageHeadLine>

                                      <TextLink onClick={() => downloadFile(`${ChatAttachmentFileApiPath}/${message.attachment_file_id}`, message.text)}><AttachmentFileIcon size="small" /> {message.text}</TextLink>
                                  </Message>
                              </Tooltip>
                            )
                            : (
                              <Tooltip text_id={formatDatetime(utc.toLocal(message.time))}>
                                  <Message isMy={message.isMyMessage}>
                                      <MessageHeadLine side={message.isMyMessage ? "right" : "left"}>
                                        {!message.isMyMessage && <UserName>{message.sender}</UserName>}
                                        {!message.isMyMessage && <div className="occupy" />}
                                        <Time>{formatDatetimeShort(utc.toLocal(message.time))}</Time>
                                      </MessageHeadLine>

                                      <Text>{message.text}</Text>
                                  </Message>
                              </Tooltip>
                            )
                    }
                </React.Fragment>
                })
            }
        </>

    const questionChatMessages = useMemo(() => <AllMessages />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [questionChat.length]);

    return <Wrapper>
        <Messages ref={messages}>
            {!questionChat?.length && formatMessage({ id: "chat.no_messages" })}
            {questionChatMessages}
        </Messages>


        <UserInput>
            <NotificationsIcon onClick={notificationSettings.dialog.open} />
            <AttachmentIcon onClick={() => openFileUploader(f => attachment.saveFile(f))} />
            <InputMessageWrapper
                onChange={e => setChatValue(e.target.value)}
                value={chatValue}
                onKeyUp={(e) => e.ctrlKey && e.key === "Enter" && doSendMessage()}
                placeholder={formatMessage({ id: "chat.message_placeholder" })}
                />
            <SendIconWrapper onClick={() => doSendMessage()} />
        </UserInput>
        <NotificationSettingsPopup data={notificationSettings} />

        <Dialog fullWidth maxWidth="sm" open={!!attachment.activeFile} onClose={() => attachment.cancel()}>
            <DialogTitle><FormattedMessage id="chat.send_file_to_chat" /></DialogTitle>
            <DialogContent>
              <Buttons>
                <AttachmentFileIcon />
                <Typography>{attachment.activeFile?.filename}</Typography>
              </Buttons>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { attachment.cancel(); }}>
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button variant="contained" color="primary" onClick={() => { attachment.complete(); }}>
                    <FormattedMessage id="chat.send" />
                </Button>
            </DialogActions>
        </Dialog>
    </Wrapper >
}

const AttachmentFileIcon = styled.img<{ size?: "small" | "medium" }>`
  width: ${props => props.size === "small" ? 14 : 20}px;
  height: ${props => props.size === "small" ? 21 : 30}px;
`;
AttachmentFileIcon.defaultProps = { src: download_file, alt: "" };

const Wrapper = styled.div`
    padding: 40px 19px;
    width: 750px;
    margin: auto;
    position: relative;
    box-sizing: border-box;
    background-color: ${themeColor(c => c.alabaster)};

    @media ${media.l} {
        padding: 40px 5px;
        width: 100%;
    }
`;

const UserInput = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: ${themeColor(c => c.whiteDefault)};
`;

const NotificationsIcon = styled(NotificationsOutlined)`
    cursor: pointer;
`;
NotificationsIcon.defaultProps = { role: "button", color: "action" }

const AttachmentIcon = styled(AttachFileOutlined)`
    cursor: pointer;
`;
AttachmentIcon.defaultProps = { role: "button", color: "action" }


const InputMessageWrapper = styled(TextareaAutosize)`
    padding: 8px 12px;
    flex: 1 0 auto;
    height: 35px;
    outline: none;
    resize: none;

    background-color: ${themeColor(c => c.alabaster)};
    border-radius: ${Sizes.geometry.borderRadius.button}px;
    border: none;
    font-family: "Ubuntu";
    font-size: 18px;
    font-weight: 300;
`;

const Message = styled.div<{ isMy: boolean }>`
    margin: ${props => props.isMy ? "30px 0 30px auto" : "30px 0"};
    padding: 6px 10px;
    width: fit-content;
    border-radius: 10px 10px ${props => props.isMy ? 0 : 10}px ${props => props.isMy ? 10 : 0}px;
    background-color: ${props => themeColor(c => props.isMy ? c.fairPink : c.whiteDefault)};
    word-break: break-word;
`;

const UserName = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: ${themeColor(c => c.darkRedDefault)};
`;

const MessageHeadLine = styled.div<{ side: "left" | "right" }>`
  display: flex;
  flex-flow: ${props => props.side === "right" ? "row-reverse" : "row"};
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  
  & > ${Time} {
    margin: 0;
    white-space: nowrap;
  }

  & > .occupy {
    min-width: 3rem;
    flex: 1 0 auto;
  }
`;

const Text = styled.p`
    margin: 4px 0 0 0;
    font-size: 20px;
    font-weight: 400;
    color: ${themeColor(c => c.ebony)};
    white-space: pre-line;
`;

const TextLink = styled.a`
    margin: 4px 0 0 0;
    font-size: 20px;
    font-weight: 400;
    color: ${themeColor(c => c.ebony)};
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img:first-child {
      margin-right: 0.5rem;
    }
`;

const Messages = styled.div`
    padding: 0 5px;
    height: calc(100vh - 80px - 200px - 37px - 64.5px - 118.5px);
    overflow-y: auto;
    margin-bottom: 30px;

    @media ${media.s} {
        /* - <paddings from wrapper> - user input - header - slack */
        height: calc(100vh - 80px - 64.5px - 61px - 10px);
    }
    
    ${ScrollbarStyle}
`;

const SendIconWrapper = styled(SendIcon)`
    cursor: pointer;
    fill: ${themeColor(c => c.darkRedDefault)};
`;

const Event = styled.p<{ isWarning?: boolean }>`
    margin: 20px auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: ${props => themeColor(c => props.isWarning ? c.darkRedDefault : c.blackDefault)};
    white-space: pre-line;
`;

const Warning = styled.img`
    margin: 0 6px;
`;