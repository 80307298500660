import React, { useState } from "react";
import logo from '../../../events/Pages/common_icons/logo-arbitr-mobile.svg';
import graph from "../../../events/Pages/header_icons/graph.svg";
import message from "../../../events/Pages/header_icons/message.svg";
import news from "../../../events/Pages/header_icons/news.svg";
import styled from '@emotion/styled';
import { media } from '../../media';
import { themeColor } from '../../../components/Theme';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuestion } from '../../../useContext/QuestionProvider';
import { dict } from '../../../constants/system_reference';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from "../common_icons/menu.svg";
import CloseIcon from "../../../events/Pages/search_icons/cross.svg";
import { Typography } from "@material-ui/core";
import { getMemberName, useAuthorizedUser } from "../../../useContext/AuthorizedUserContext";
import { OccupyFreeSpace } from "../../../toolympus/components/primitives";
import DocumentIcon from "../common_icons/document.svg";
import { useLocale } from "../../../toolympus/components/localization";


const Content = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${media.xs} {
        margin: auto 0;
    }
`;

const Functionality = styled.div`
    display: flex;
    align-items: center;
`;

const Logout = styled.p`
  font: 500 17px 'Ubuntu';
  color: ${themeColor(c => c.darkRedDefault)};
  cursor: pointer;
`;

const MenuIconImg = styled.img`
    cursor: pointer;
    margin: 0 20px 0 0;
    
    @media ${media.xs} {
        margin: 0 20px 0 0;
    }
`;
MenuIconImg.defaultProps = { role: "button" };

const Logo = styled.img`
    padding: auto 20px;
    cursor: pointer;

    @media ${media.xs} {
        padding: 0 0 0 5px;
    }
`;


const MenuButton = styled.a`
    text-decoration: none;

    display: inline-block;
    mask: url(${MenuIcon});
    mask-size: cover;
    width: 32px;
    height: 32px;
    background: ${themeColor(c => c.footerBackground)};
    flex-shrink: 0;
`;

const MenuLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    padding: 10px 0;
`;

const MenuLinkHref = styled.a`
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    padding: 10px 0;
`;

const MenuContactLink = styled.a`
    text-decoration: none;
    color: ${themeColor(c => c.darkRedDefault)};
    padding: 10px 0;
`;

const ContactSection = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 20px;
`;

const MenuText = styled(Typography)`
`;

const UserSection = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const CloseButton = styled.a`
    text-decoration: none;
    display: inline-block;
    mask: url(${CloseIcon});
    mask-size: cover;
    width: 24px;
    height: 24px;
    background: ${themeColor(c => c.textGreyBasic)};
    flex-shrink: 0;
    position: absolute;
    top: 30px;
    right: 20px;
`;

const PopupMenu = styled.div<{ isActive?: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    max-width: ${props => props.isActive ? 100 : 0}vw;
    max-height: 100vh;
    overflow-x: hidden;
    box-sizing: border-box;
    background: ${themeColor(c => c.footerBackground)};
    color: ${themeColor(c => c.textGreyBasic)};
    
    display: none;
    @media ${media.m} {
        display: flex;
        transition: all 0.2s ease;
        flex-flow: column;
        padding: ${props => props.isActive ? 20 : 0}px;
        font-size: 16px;
    }
`;

export const HeaderMenuMobile = (props: { logout: () => void }) => {
    const { formatMessage } = useIntl();
    const { selectTab, questionTab } = useQuestion();
    const history = useHistory();
    const user = useAuthorizedUser();
    const { locale } = useLocale();

    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    const clearQuestionTab = () => selectTab(null);

    return <>
        <Content>
            <Logo onClick={() => {
                history.push("/");
                clearQuestionTab();
            }} src={logo} alt={formatMessage({ id: "addition.logoTitle" })} />

            <Functionality>
                {
                    questionTab !== null
                        ? <>
                            <MenuIconImg src={DocumentIcon} onClick={() => selectTab(dict.tabs.description)} alt={formatMessage({ id: "addition.questions" })} />
                            <MenuIconImg src={graph} onClick={() => selectTab(dict.tabs.results)} alt={formatMessage({ id: "information.menu.results" })} />
                            <MenuIconImg src={message} onClick={() => selectTab(dict.tabs.discussion)} alt={formatMessage({ id: "addition.messanger" })} />
                        </>
                        : <MenuIconImg src={news} onClick={() => {
                            selectTab(null);
                            history.push("/news");
                        }} alt={formatMessage({ id: "addition.news" })} />
                }
                <MenuButton onClick={() => setIsMenuVisible(x => !x)} />
            </Functionality>

            <PopupMenu isActive={isMenuVisible} onClick={() => setIsMenuVisible(false)}>
                <CloseButton />

                <MenuLink to="/" onClick={clearQuestionTab}><FormattedMessage id="information.menu.main" /></MenuLink>
                <MenuLink to="/news" onClick={clearQuestionTab}><FormattedMessage id="history.news" /></MenuLink>
                <MenuLink to="/documentation/home" onClick={clearQuestionTab}><FormattedMessage id="history.documentation" /></MenuLink>
                <MenuLinkHref href="https://centerarbitr.ru/" target='_blank' rel='noopener noreferrer'><FormattedMessage id="information.menu.name" /></MenuLinkHref>
                <MenuLinkHref href="https://centerarbitr.ru/text/" target='_blank' rel='noopener noreferrer'><FormattedMessage id="information.menu.regulations" /></MenuLinkHref>

                <OccupyFreeSpace />

                <MenuText><FormattedMessage id="information.contacts.address" /></MenuText>
                <ContactSection>
                    <MenuContactLink href={`mailto:${formatMessage({ id: "information.contacts.mail" })}`}>
                        <FormattedMessage id="information.contacts.mail" />
                    </MenuContactLink>
                    <MenuContactLink href={`tel:${formatMessage({ id: "information.contacts.phonePlaceholder" })}`}>
                        <FormattedMessage id="information.contacts.phone" />
                    </MenuContactLink>
                </ContactSection>
                

                <UserSection>
                    <MenuText>{getMemberName(locale, user)}</MenuText>

                        <Logout onClick={() => {
                            selectTab(null);
                            props.logout();
                        }}>
                        <FormattedMessage id="service.logout" />
                    </Logout>
                </UserSection>
            
                <MenuText>
                  {formatMessage({ id: "service.copyright"}).replace("{YEAR}", new Date().getFullYear().toString())}
                </MenuText>
            </PopupMenu>
        </Content>
    </>
}
