import React, { useState } from "react";
import styled from '@emotion/styled';
import { dict } from '../../constants/system_reference';
import { media } from '../media';
import { Question } from '../../api/types/main';
import { Button } from './Candidate.style';
import { CandidateQuestion } from '../../api/types/candidates';
import { themeColor } from '../../components/Theme';
import { ModalWindow } from '../modal_window/ModalWindow';
import { FormattedMessage } from 'react-intl';
import { useQuestion } from '../../useContext/QuestionProvider';
import { QuestionCaseInfo } from "../../api/types/questions";

interface CandidateButton {
    t: string;
    link: keyof QuestionCaseInfo;
    isHighlighted?: boolean;
}


const getActiveButtons = (question: Question): CandidateButton[] => {
    const { active_stage, is_candidates_question, status } = question;

    const canVote = active_stage?.permissions?.vote && !status.is_conflict;
    const canSuggest = active_stage?.permissions?.suggest && !status.is_conflict;

    return [
        (status.is_voted && canVote) && { t: "button.changeVote", link: dict.button.voting },
        (!status.is_voted && canVote) && { t: "button.voting", link: dict.button.voting, isHighlighted: true },
        (canSuggest && is_candidates_question) && { t: "button.suggestCandidate", link: dict.button.suggestCandidate },
        
        (canVote || canSuggest) && { t: "button.conflict", link: dict.button.conflict },
        (canVote && !status.is_voted ) && { t: "button.abstain", link: dict.button.abstain },        
        { t: "button.discuss", link: dict.button.discuss },

    ].filter(x => !!x) as CandidateButton[];
}



const Wrapper = styled.div<{ count?: number }>`
    margin: auto;
    width: 99%;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    @media ${media.l} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media ${media.m} {
        width: 90%;
        margin: auto;
    }

    @media ${media.xs} {
        display: flex;
    }
`;

const BlockVote = styled.p`
    width: 500px;
    margin: auto auto 50px auto;
    font: 300 16px "Ubuntu";
    color: ${themeColor(c => c.textGreyBasic)};
    white-space: pre-line;
    text-align: center;

    @media ${media.m} {
        width: 70%;
    }
`;

interface Props {
    id: string,
    data: Question,
    candidates: CandidateQuestion[],
    setData: (x: Question) => void;
}


export const QuestionButtons = (props: Props) => {
    const { selectTab } = useQuestion();

    const [activeModal, setActiveModal] = useState<string | null>(null);
    
    const openModal = (btn?: CandidateButton) => {
        setActiveModal(btn?.link || dict.button.voting);
    }
    
    const { status } = props.data;
    const buttons = getActiveButtons(props.data);
    
    return <>
        <ModalWindow activeModal={activeModal} setActiveModal={setActiveModal} params={{
            ...props,
            hideCandidateButtons: () => {}
        }} />

        {!status.is_conflict && (
            <Wrapper count={buttons.length}> 
                {getActiveButtons(props.data).map(btn => 
                    <Button
                        key={btn.t}
                        onClick={() => btn.link === dict.button.discuss ? selectTab(dict.tabs.discussion) : openModal(btn)}
                        count={buttons.length}
                        isHighlighted={btn.isHighlighted}
                        >
                    <FormattedMessage id={btn.t} />
                </Button>)}
            </Wrapper>)}

        {status.is_conflict && (
            <BlockVote><FormattedMessage id="information.blockVote.conflict" /></BlockVote>)}
    </>
}
