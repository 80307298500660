import { dict } from './system_reference';

export const history = {
    votingQuestions: (title: string) => ({
        link: dict.hx.votingQuestions,
        path: "/",
        t: title,
    }),

    caseTitle: (title: string) => ({
        t: title,
        isClick: false
    }),

    home: (title: string) => ({
        link: dict.hx.home,
        path: "/",
        t: title,
    }),

    news: (title: string) => ({
        t: title,
        isClick: false
    })
}