import React from "react";
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { QuestionItem, QuestionItemTimeTable } from '../../../api/types/main';
import { themeColor } from '../../../components/Theme';
import { media } from '../../media';
import { QuestionWrapper, Title } from '../Questions.style';
import { useFormats } from '../../../toolympus/components/schemed';

export const QuestionItemPast = ({ question }: Omit<QuestionItem, "questionStatus" | "isVoted">) => {
    const { formatMessage } = useIntl();
    const timeTable: QuestionItemTimeTable[] = [
        { content: formatMessage({ id: "service.start" }), time: question.start_date, },
        { content: formatMessage({ id: "service.end" }), time: question.end_date }];

    const { formatDate } = useFormats();

    return <Question>
        <Title margin={"0 0 20px 0"} font={"500 20"}> {question.title} </Title>
        <Schedule>
            {timeTable.map(({ content, time }: QuestionItemTimeTable, key: number) =>
                <ScheduleTime key={key}>
                    <ScheduleItem>{`${content}: `}</ScheduleItem>
                    <ScheduleItem>
                        {time === null ? formatMessage({ id: "addition.unknown" }) : formatDate(time)}
                    </ScheduleItem>
                </ScheduleTime>)}
        </Schedule>
    </Question>
}

const Question = styled(QuestionWrapper)`
    width: 100%;
    text-decoration: none;
`;

const Schedule = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const ScheduleTime = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @media ${media.xs} {
      flex-direction: column;
    }
    
`;

const ScheduleItem = styled.p`
    color: ${themeColor(c => c.textGreyBasic)};
    font: 300 17px "Ubuntu";
    margin: 0;

    @media ${media.m} {
        font: 300 16px "Ubuntu";
    }
`;