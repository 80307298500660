import React, { useState } from "react";
import styled from '@emotion/styled';
import { media } from '../../events/media';
import logo from "../../events/Pages/common_icons/arrow_down.svg";
import { CandidateField, CandidateName, CandidateQuestion } from '../../api/types/candidates';
import { dict } from '../../constants/system_reference';
import { FieldType, Schema } from '../../toolympus/hooks/useSchema';
import { Title } from '../questions/Questions.style';
import { ColorsTheme, Sizes, themeColor } from '../../components/Theme';
import { useIntl } from 'react-intl';
import WarningAmberIcon  from '@material-ui/icons/Warning';
import { downloadFile } from '../../toolympus/api/core';
import { TableDisplay } from "../../toolympus/components/schemed/TableDisplay";
import { MarkdownDisplay } from "../../toolympus/components/schemed/Markdown";
import { useLocale } from "../../toolympus/components/localization";


const CandidateTitle = styled(Title)`
    &:nth-child(1) {
        margin: 0;
    }
`;

CandidateTitle.defaultProps = { color: "grey", margin: "20px 0 0 0", font: "400 17" }

const Info = styled.div`
    width: 40%;
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 3px solid ${themeColor(c => c.question_case.descriptionBackground)};

    @media ${media.l} {
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
    }
`;

const Details = styled.div<{ isMobileHidden: boolean }>`
    width: 51%;
    @media ${media.l} {
        width: 100%;
        display: ${props => props.isMobileHidden ? "none" : "block"};
    }
`;

const SubTitle = styled.p`
    margin: 10px 0 0 0;
    color: ${themeColor(c => c.blackDefault)};
`;

const Name = styled.p`
    margin: 0 0 10px 0;
    color: ${themeColor(c => c.blackDefault)};
    font: 500 1rem "Ubuntu";
`;

const Skills = styled.div``;

const Location = styled.p`
    margin: 0;
    color: ${themeColor(c => c.blackDefault)};
`;

const Description = styled.p<{ isMobileHidden?: boolean }>`
    margin-top: 20px;
    width: 100%;
    color: ${themeColor(c => c.blackDefault)};
    word-break: break-word;

    @media ${media.l} {
        display: ${props => props.isMobileHidden ? "none" : "block"};
    }
`;

const Wrapper = styled.div`
    margin: 20px auto 20px auto;
    padding: 1rem 1rem 2rem 1rem;
    width: 70%;
    display: flex;
    justify-content: center;
    font-weight: 300;
    background: ${ColorsTheme.white};
    box-shadow: ${dict.spl.boxShadow.average} ${themeColor(c => c.blackShadow)}, ${dict.spl.boxShadow.ultraLight} ${themeColor(c => c.blackShadow)};
    border-radius: ${Sizes.geometry.borderRadius.pannel}px;
    word-break: break-word;

    @media ${media.l} {
        padding: 3% 3% 2% 3%;
        margin: 3% auto 3% auto;
        width: 90%;
        flex-direction: column;
    } 
`;

const DownloadDescription = styled.p<{ color: string, isMobileHidden?: boolean }>`
    margin: 10px 0 0 0;
    width: max-content;
    font-weight: 400;
    color: ${props => themeColor(c => props.color === "default" ? c.buttonBlueText : c.darkRedDefault)};
    cursor: pointer;
    
    @media ${media.l} {
        display: ${props => props.isMobileHidden ? "none" : "block"};
    }
`;

const Download = styled.p`
    display: none;
    color: ${themeColor(c => c.buttonBlueText)};
    font: 300 1rem "Ubuntu";
    cursor: pointer;

    @media ${media.l} {
        display: block;
    }
`;

const ButtonsMobile = styled.div`
    display: none;
    justify-content: space-between;

    @media ${media.l} {
        display: flex;
    }
`;

const Collapse = styled.p`
    font: 300 1rem "Ubuntu";
    color: ${themeColor(c => c.buttonBlueText)};
    cursor: pointer;
`;

const CollapseImage = styled.img<{ isHidden: boolean }>`
    margin-left: 5px;
    vertical-align: bottom;
    transform: rotate(${props => props.isHidden ? "0deg" : "180deg"});
    cursor: pointer;
`;

const Circumstances = styled.div`
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
`;

const CircumstancesIntro = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;

    & > :first-child {
        margin-right: 10px;
    }
`;

const CircumstancesDescription = styled.p`
    margin: 0;
`;

const CandidateSchema: Schema = {
    language: {
        type: FieldType.dictionarySelectMulti,
        dictionary: "Language",
    },
    specialization_site: {
        type: FieldType.dictionarySelectMulti,
        dictionary: "ArbitratorSpecSite",
    },
    area_site: {
        type: FieldType.dictionarySelectMulti,
        dictionary: "ArbitratorArea",
    },
    country: {
        type: FieldType.dictionarySelect,
        dictionary: "Country"
    },
    city: {
        type: FieldType.dictionarySelect,
        dictionary: "City"
    },
}

const CandidateInfoDisplay = ({ field, candidate, emptyLabel }: { field: CandidateField, candidate: CandidateQuestion, emptyLabel?: string }) => (
    <TableDisplay
        field={field}
        schema={CandidateSchema[field]}
        row={candidate}
        config={{ emptyLabel }}
    />
)

export const getCandidateName = (locale: string, candidate: CandidateName) => locale === "ru"
    ? `${candidate.firstname} ${candidate.lastname}`
    : `${candidate.firstname_en || candidate.firstname} ${candidate.lastname_en || candidate.lastname}`;

export const CandidatePerson = ({ candidate, questionId }: { candidate: CandidateQuestion, questionId: string }) => {
    const [isHidden, setIsHidden] = useState(true);
    const { formatMessage } = useIntl();       
    
    const downloadDocument = (docId?: string | null) => docId && downloadFile(`/api/presidium-app/question/${questionId}/document/${docId}`);

    const { locale } = useLocale();

    const [firstname,lastname] = locale === "en"
        ? [candidate.firstname_en || candidate.firstname, candidate.lastname_en || candidate.lastname]
        : [candidate.firstname, candidate.lastname];

    return <Wrapper>
        <Info>
            <Skills>
                <Name>{firstname} {lastname}</Name>
                <Location>
                    <CandidateInfoDisplay
                        candidate={candidate}
                        key="country"
                        field="country"
                        emptyLabel={formatMessage({ id: "service.none" })}
                        />

                    {candidate.city === null ? null : <>
                        {", "}
                        <CandidateInfoDisplay
                            candidate={candidate}
                            key="city"
                            field="city"
                            emptyLabel={formatMessage({ id: "service.none" })}
                            />
                    </>}
                </Location>

                <CandidateTitle key="language">
                    {formatMessage({ id: "candidate.langs" })}
                    <SubTitle>
                        <CandidateInfoDisplay
                            candidate={candidate}
                            field="language"
                            emptyLabel={formatMessage({ id: "addition.emptyContent" })}
                            />
                    </SubTitle>
                </CandidateTitle>

                <CandidateTitle key="specialization_site">
                    {formatMessage({ id: "candidate.specialization" })}
                    <SubTitle>
                        <CandidateInfoDisplay
                            candidate={candidate}
                            field="specialization_site"
                            emptyLabel={formatMessage({ id: "addition.emptyContent" })}
                            />
                    </SubTitle>
                </CandidateTitle>

                <CandidateTitle key="area_site">
                    {formatMessage({ id: "candidate.sphere" })}
                    <SubTitle>
                        <CandidateInfoDisplay
                            candidate={candidate}
                            field="area_site"
                            emptyLabel={formatMessage({ id: "addition.emptyContent" })}
                            />
                    </SubTitle>
                </CandidateTitle>

            </Skills>
        </Info>

        <Details isMobileHidden={false}>

            {candidate.confirmation_status === dict.disclosure && <>
                <Circumstances>
                    <CircumstancesIntro>
                        <WarningAmberIcon style={{ fill: themeColor(c => c.darkRedDefault) }} />
                        {formatMessage({ id: "service.circumstances" })}:
                    </CircumstancesIntro>
                    <CircumstancesDescription>
                        <MarkdownDisplay text={candidate.disclosure_details || ""} />
                    </CircumstancesDescription>
                </Circumstances>
                <DownloadDescription color={"warning"} onClick={() => downloadDocument(candidate.disclosure_document_id)}>
                    {formatMessage({ id: "service.downloadDisclosureDescription" })}
                </DownloadDescription>
                </>}

            <Description isMobileHidden={isHidden}>
                <MarkdownDisplay text={(locale === "en" ? candidate.info_en : candidate.info) || ""} />
                <MarkdownDisplay text={candidate.description || ""} />
            </Description>

            {candidate.document_id !== null &&
                <DownloadDescription isMobileHidden={true} color="default" onClick={() => downloadDocument(candidate.document_id)}>
                    {formatMessage({ id: "service.downloadDescription" })}
                </DownloadDescription>
            }

        </Details>

        <ButtonsMobile>
            {candidate.document_id !== null && <Download onClick={() => downloadDocument(candidate.document_id)}>
                {formatMessage({ id: "service.downloadDescription" })}
            </Download>}

            <Collapse onClick={() => setIsHidden(!isHidden)}>
                {isHidden ? formatMessage({ id: "service.more" }) : formatMessage({ id: "service.collapse" })}
                <CollapseImage isHidden={isHidden} src={logo} alt={formatMessage({ id: "service.collapse" })} />
            </Collapse>
        </ButtonsMobile>

    </Wrapper >
}
