export const ruMessages = {
    error: {
        general: "Произошло что-то нехорошее",
        validation: "Некоторые поля содержат ошибки",
        noRights: "У вас нет прав на выполнение этого действия",

        files: {
            content_type_not_allowed: "Недопустимый тип файла. Разрешены: {allowed_types}",
            file_too_large: "Файл слишком большой. Максимальный размер файла: {max_size_kb}кб",
            alias_occupied: "Алиас уже занят",
        },

        auth: {
            login_taken: "Пользователь с таким email уже зарегистрирован",
            password_too_weak: "Пароль должен состоять минимум из 8 символов и включать большие и маленькие латинские буквы и цифры",
            failed_login: "Указан неверный логин или пароль",
            code_expired: "Срок действия кода истек/ Чтобы получить код повторно, перезагрузите страницу",
            code_invalid: "Неверный код авторизации. Чтобы получить код повторно, перезагрузите страницу",
            invite_code_invalid: "Код приглашения не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            confirmation_code_invalid: "Код подтверждения не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            reset_token_invalid: "Код сброса пароля не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            user_already_confirmed: "Email пользователя уже подтвержден",
            user_doesnt_need_confirmation: "Email пользователя не требует подтверждения",
            user_blocked: "Пользователь заблокирован. Это могло произойти, если вы несколько раз ввели неправильный пароль. В таком случае вход блокируется на {minutes} минут. Если вы считаете, что произошла ошибка, пожалуйста свяжитесь с администратором",
            passwords_dont_match: 'Пароли не совпадают',
        },

        dictionary: {
            cant_update_synthetic_dictionary: "Нельзя обновить синтетический справочник - обновите значение в исходном справочнике",
        },

        export: {
            invalid_code: "Конфигурация экспорта не найдена",
        },
    },
    success: {
        clipboard: "Значение скопировано в буфер обмена"
    },
    login: {
        email: "Email",
        password: "Пароль",
        code: "Код",
        code_hint: "Мы отправили вам 5-значный код. Пожалуйста введите его, чтобы завершить авторизацию",
        submit: "Войти",
        errorcreds: "Введен неправильный логин или пароль. Пожалуйста, попробуйте снова или воспользуйтесь восстановлением пароля"
    },
    signup: {
        errorMessage: "Мы не смогли вас зарегистрировать. Возможно срок действия приглашения истек или что-то еще пошло не так. Пожалуйста, свяжитесь с нами",
        successMessage: "Запрос отправлен. Вы получите доступ в систему, как только его утвердит администратор",
        email: "Email",
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Зарегистрироваться"
    },
    signupConfirmation: {
        defaultMessage: "Подтверждаем email...",
        successMessage: "Email успешно подтвержден. Спасибо!",
        errorMessage: "Что-то пошло не так. Пожалуйста, свяжитесь с нами."
    },
    resetPassword: {
        email: "Email",
        submit: "Сбросить пароль",
        success: "Мы отправили вам email со ссылкой. Пожалуйста, перейдите по этой ссылке, чтобы задать новый пароль.",
    },
    restorePassword: {
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Сменить пароль",
        success: "Пароль успешно изменен. Авторизуйтесь с новым паролем на странице входа",
    },
    emailConfirmation: {
        confirming: "Подтверждаем Email...",
        success: "Email успешно подтвержден, спасибо!",
        failure: "Произошла ошибка. Пожалуйста, попробуйте перезагрузить страницу.",
    },
    searchModal: {
        title: "Поиск",
        searchFieldLabel: "Найти...",
        pickItem: "Выбрать",
        emptyState: "Ничего не удалось найти. Попробуйте другой запрос."
    },
    searchField: {
        label: "Поиск...",
        buttonLabel: "Загрузить",
    },
    userManagement: {
        title: "Пользователи",
        header: {
            addUser: "Добавить пользователя",
            sendInvites: "Отправить приглашения",
            sendInvintesTip: "Приглашения будут отправлены только новым пользователям, без повторов",
            export_users: "Скачать список пользователей",
        },
        columns: {
            email: "Email",
            lastLogin: "Последний вход",
            blocked_until: "Блокировка",
            blocked_until_explanation: "Пользователь заблокирован и не сможет войти в систему до указанного времени",
            unblock: "Разблокировать пользователя",
            block: "Заблокировать пользователя",
            invited: "Приглашен",
            confirmed: "Подтвержден",
            two_factor_auth: "Двухфакторная аутентификация",
            resendConfirmRequest: "Отправить повторный запрос подтверждения",
            repeatedConfirmRequestNever: "Повторный запрос не отправлялся",
            repeatedConfirmRequestTime: "Повторный запрос отправлялся {time}",
            invite: "Пригласить",
            signInAs: "Авторизоваться",
            sendPasswordResetToken: "Отправить код для изменения пароля",
            change_password: "Сменить пароль",
        },
        userPopup: {
            title: "Добавить пользователей",
            email: "Email",
            submit: "Создать",
            hint: "укажите email'ы создаваемых пользователей через пробел или точку с запятой"
        },
        changeEmailPopup: {
            title: "Изменить Email",
            oldEmail: "Текущий Email",
            newEmail: "Новый Email",
        },
        change_password_popup: {
            title: "Изменить пароль пользователя",
            new_password: "Новый пароль",
            success: "Пароль успешно сменен",
        },
        two_factor_auth_popup: {
            title: "Двухфакторная авторизация",
            medium: "Второй фактор",
            two_factor_user_id: "ID пользователя",
            two_factor_user_id_hint: "ID или адрес в приложении - втором факторе",
        },
        password_reset_email_sent: "Письмо с кодом для смены пароля отправлено пользователю",
        copy_email: "Скопировать Email",
        copy_id: "Скопировать ID",
    },
    auth: {
      apitokens: {
        title: "API Токены",
        create: "Создать токен",
        token_created_hint: "Сохраните созданный токен - он показывается только один раз",

        block_token: "Заблокировать токен",
        block_token_hint: "Если заблокировать токен, приложения использующие его для доступа к системе незамедлительно утратят доступ. Восстановить заблокированный токен невозможно, но вы всегда можете создать новый.",
        block: "Заблокировать",

        fields: {
          _id: "ID",
          email: "E-mail",
          roles: "Роли",
          created_datetime: "Создан",
          is_blocked: "Заблокирован",
          token: "Токен",
        },
      },
    },

    emails: {
        screen_title: "Шаблоны писем",
        fields: {
            code: "Шаблон",
            base_template: "Базовый шаблон",
            title: "Название",
            description: "Описание",
            subject: "Тема",
            body: "Тело",
            body_html: "Тело HTML",
            autowrap_html_body: "Автовставка тегов html, head, body",
            active: "Активен",
        },

        popup: {
            title: "Редактировать шаблон",
            tab_content: "Содержимое",
            tab_settings: "Адресаты и настройки",
            emailContent: "Содержимое письма",
            testEmail: "Отправить тестовое сообщение",
            testEmailTextTip: "Параметры в формате JSON",
            powertemplate_hint: "Использовать новый редактор",
            to: "Адрес",
            send: "Отправить",
            delete: "Удалить шаблон?",
            textHint: "Текст",
            htmlHint: "HTML",
            preview_html: "Предпросмотр HTML",
            scriptable_block: "Блок",
        },

        error: {
          email_template_not_found: "Шаблон письма не найден",
        },
        
        action_send_email: {
          title: "Отправка письма",
          description: "Отправить письмо по шаблону. В качестве адресата можно использовать синтаксис подстановки {{field.in.data}}",
          params: {
            template: "Шаблон",
            to: "Адресат",
          },
        },
    },
    dictionaries: {
        title: "Справочники",
        statusSaved: "Сохранено",
        newRecordLabel: "Код новой записи",
        addRecord: "Добавить",
        selectDictionary: "Справочник",
        import_success: "Импорт прошел успешно",

        remove_title: "Удалить запись",
        remove_hint: "Запись будет удалена из справочника. Если она уже используется, удаление может привести к нарушению целостности данных и работоспособности системы.",

        fields: {
            code: "Код",
            label: "Название",
            label_lang: "Название ({lang})",
            label_translations: "Переводы названий",
            sortorder: "Порядок",
            comment: "Комментарий",
            disabled: "Отключено",
        }
    },
    queryconsole: {
        screen_title: "Консоль запросов",
        title: "Название запроса",
        query: "Запрос",
        run: "Выполнить",
        history: "История",
    },

    eventlog: {
        screen_title: "События",
        labels: {
            _id: "ID",
            kind: "Тип",
            message: "Событие",
            created_at: "Время",
            time_from: "С",
            time_to: "По",
            total_rows: "Найдено событий:",
        },

        actions: {
          event_log_action: {
            title: "Event log",
            description: "Создает запись в логе событий, выбирая указанные поля из данных",
            kind: "Тип события",
            keys: "Поля",
          },
        },
    },

    schemas: {
        title: "Схемы UI",
    },

    localization: {
        messages: {
            title: "Локализуемые строки",
            add_language: "Добавить язык",
            language_code: "Код языка",
            json_error: "Ошибка в форматировании JSON",
            add_message: "Добавить строку",

            show_empty_only: "Показывать только пустые",

            pull_messages_from_other_lang: "Добавить строки",
            pull_messages_from_other_lang_hint: "из другого языка",
            pull_messages_from_other_lang_select_lang: "Выберите язык, из которого нужно добавить строки",

            mode: {
                strings: "строки",
                json: "json",
            },

            table: {
                key: "Код",
                message: "Строка",
            },
        },
    },

    common: {
        cancel: "Отмена",
        close: "Закрыть",
        add: "Добавить",
        delete: "Удалить",
        edit: "Редактировать",
        replace: "Заменить",
        save: "Сохранить",
        change: "Заменить",
        yes: "Да",
        no: "Нет",
        select: "Выбрать",
        select_all: "Выбрать все",
        deselect: "Убрать",
        copy: "Копировать",
        paste: "Вставить",
        clear: "Сбросить",
        download: "Скачать",
        upload: "Загрузить",
        create: "Создать",
        reload: "Обновить",
        expand_all: "Раскрыть все",

        show_details: "показать детали",
        hide_details: "скрыть детали",

        fullscreen: "Полноэкранный режим",

        invalid_date_format: "Неправильный формат даты",

        copy_as_tsv: "Копировать TSV (для Excel)",
        copy_as_md_table: "Копировать как таблицу markdown",
        unsaved_changes_prompt: "На странице есть несохраненные изменения. Если продолжить, они потеряются.",

        menu: {
          title: "Название",
          url: "Ссылка",
        },
    },
    currency_rates: {
        date: "Дата",
        rate: "Курс",
        title: "Курсы валют",
    },
    formcontrol: {
        markdown: {
            write: 'Редактирование',
            preview: 'Предпросмотр',
        }
    },
    fieldssettings: {
        title: "Настройки полей",
        available: "Доступные поля",
        active: "Активные поля",
        reset: "Сбросить",
    },
    fieldsfilters: {
        title: "Фильтры",
        emptyState: 'Нажмите здесь или на кнопку "+" выше, чтобы добавить фильтр',
        add: 'Добавить фильтр',
    },
    files: {
        pageTitle: "Файлы",
        
        filename: "Имя файла",
        filetype: "Тип",
        meta: "Свойства",
        alias: "Алиас",
        comments: "Комментарии",

        copyLink: "Скопировать ссылку",

        removeConfirmation: "Удалить файл?",
        editDialogTitle: "Файл",
        drop_or_click_to_upload: "Перетащите файл сюда или кликните, чтобы выбрать",
    },
    medialib: {
        title: "Медиафайлы",
        select: "Выберите файл",

        label: {
            space_id: "Пространство",
            _id: "ID",
            filename: "Имя файла",
            contenttype: "Тип",
            created_at: "Создан",
            url: "URL",
        },

        browser: {
            no_files: "Файлов пока нет.",
            no_files_found: "Таких файлов не найдено.",
            to_upload: "Чтобы загрузить файл кликните здесь или просто перетащите файл сюда.",

            link_copied: "Ссылка скопирована в буфер обмена",
            remove_file_confirmation: "Удалить файл?",

            download: "Скачать",
            remove: "Удалить",
            replace: "Заменить",
            details: "Информация о файле",
        },

        error: {
            dont_have_access_to_space: "У вас нет доступа к этой библиотеке файлов",
            filetypes_dont_match: "Для замены файла с сохранением имени типы файлов должны совпадать",
            file_load_error: "Ошибка при загрузке выбранного файла. Попробуйте выбрать другой",
        },
    },
    statusActions: {
        action: 'Действия',
        cancel: 'Отмена'
    },
    notifications: {
        title: "Уведомления",
        markasread: "Пометить как прочитанное",
        markallasread: "Отметить все как прочитанные",

        task: {
            title: "Название / тема",
            user_id: "Пользователь",
            email: "Email",
            kind: "Тип",
            tags: "Теги",
            display_name: "Имя",
            info: "Дополнительная информация",
            text: "Текст уведомления / письма",
            message_html: "HTML уведомления / письма",
            attachments: "Файлы",
            email_template: "Шаблон письма",
            link: "Ссылка (для внутренних уведомлений)",
            comment: "Комментарий",
            targets: "Адресаты",
            is_executed: "Отправлено",
            executed_time: "Отправлено",

            controls: {
                title: "Уведомления",
                title_one: "Уведомление",
                show_executed: "Показывать отправленные",
                execute: "Отправить",
                execute_confirmation: "Отправить уведомление?",
                test_execute: "Протестировать",
                test_execute_hint: "На указанный адрес будет отправлено тестовое сообщение с подстановкой параметров первого адресата",
                test_execute_email_label: "Email",
                save_to_edit_targets: "Сохраните уведомление, чтобы редактировать адресатов",
                upload_targets: "Загрузить адресатов",
                download_targets_upload_example: "Скачать пример",
                filter_by_tags: "Фильтр по тегам:",
                filter_by_tags_mode_hint: "Режим фильтрации: ИЛИ - показывать адресатов, у которых есть хотя бы один из выбранных тегов; И - показывать адресатов, у которых есть все выбранные теги.",
                filter_by_tags_mode_or: "ИЛИ",
                filter_by_tags_mode_and: "И",
                filter_by_tags_mode_not: "НЕ",
                substitutions_hint: "Доступные подстановки: ",
                email_template_hint: "Текст письма будет подставлен вместо {{_content_}} и {{_content_html_}} в шаблоне. Нажмите здесь, чтобы открыть шаблоны писем.",
                add_attachment: "Добавить файл",
                fs_path_not_configured: "Недоступен путь к файлу. Пожалуйста, свяжитесь с администратором, чтобы сконфигурировать библиотеку файлов",
            },

            target: {
                all: "Все",
                add_filtered: "Добавить отфильтрованных",
                remove_filtered: "Убрать отфильтрованных",
                remove_all: "Убрать всех",
                noone: "Добавить адресатов",
                count: "Всего: {count} адресатов",
            },

            actions: {
              copy_and_send: {
                title: "Скопировать и отправить уведомление",
                description: "Скопировать и отправить уведомление с указанным ID. Адресаты будут взяты из указанного поля в данных (может быть список или один адресат).",
                notification_task_id: "ID уведомления",
                targets: "Адресаты",
                dont_save: "Не сохранять копию",
              },
            },

            calendar_event: {
              create_dialog_title: "Создать iCal",

              fields: {
                start: "Начало",
                end: "Окончание",
                summary: "Название",
                id: "ID",
                timezone: "Часовой пояс",
                description: "Описание",
                location: "Место",
                organizer_name: "Имя организатора",
                organizer_email: "Email организатора",
              },
            },

            errors: {
              notification_task_id_missing: "Не указан ID уведомления для копирования",
              cant_execute_executed: "Уведомление уже отправлено",
              cant_delete_executed: "Отправленное уведомление нельзя удалить",
              emailing_not_configured: "Отправка email не сконфигурирована",
              no_targets: "Пустой список адресатов",
              targets_without_emails: "У адресатов не указаны email-адреса",
            },
        }
    },
    chat: {
        send: 'Отправить',
        edit: 'Отредактировать',
        typeMessage: 'Сообщение...',
    },
    errorPasswordsDontMatch: 'Пароли не совпадают',
    comments: {
        title: "Комментарии",
        newComment: "Добавить комментарий (Ctrl+Enter для отправки)",
        deleteConfirmation: "Удалить комментарий?",
    },

    entity: {
        actions: {
            action_id: "ID",
            entity: "Сущность",
            record: "Запись",
            action: "Действие",
            parameters: "Параметры",
            track: " ",
            user_id: "Пользователь",
            executed_at: "Время",
        },
    },
    dashboard: {
        update: 'Обновить',
        errors: {
            value_not_configured: 'Для виджета не выбрано поле - значение',
            query_not_configured: 'Для виджета не сконфигурирован запрос',
            details_query_not_configured: 'Для виджета не сконфигурирован запрос деталей',
            substitution_not_found_for_param: "Не заданы параметры или подстановки: {names}",
            unknown: 'Произошла ошибка',
        },
        screen: {
            dash: 'Дэшборд',
            widgets: 'Виджеты',
            edit: 'Редактировать дэшборды',
            create: 'Создать дэшборд',
            params: 'Параметры и подстановки',
            type: 'Тип',
            add: "Создать виджет",
            addexisting: "Добавить существующий",
            pie: 'Круговая диаграмма',
            bar: 'Столбчатая диаграмма',
            counter: 'Счётчик',
            placeholder: 'Заглушка',
            save: "Сохранить",
            title: 'Название',
            code: 'Код',
            widgettype: 'Тип виджета',
            history_title: "Дэшборд {title}",
        },
        widget: {
            dialog: 'Настройки дэшборда',
            title: 'Название',
            duplicate: 'Дублировать',
            subtitle: 'Подзаголовок',
            resultfields: 'Поля результатов',
            query: 'Запрос',
            detailsresultfields: 'Поля деталей',
            test_query: 'Тест',
            label: 'Метка',
            name: 'Название',
            link: 'Ссылка',
            links_by_labels: 'Ссылки',
            links_by_labels_hint: 'Общая ссылка или несколько строк в формате "значение: ссылка"',
            empty_state_message: 'Сообщение при пустом состоянии',
            empty_state_message_hint: 'Отображается, если нет записей соответствующих запросу',
            counter_colors: "Цвета счетчика",
            counter_colors_hint: 'HTML код цвета или несколько строк в формате "максимальное-значение: код-цвета"',

            displaySettings: 'Настройки отображения',
            show_pct: 'Показать в процентах',
            show_table: 'Показать таблицу',
            show_chart: 'Показать диаграмму',
            total_row: 'Total строка',
            value_field: 'Поле - значение',
            label_field: 'Поле - метка',
            query_substitutions: 'Подстановки для строки запроса',
            query_substitutions_hint: "Формат: let NAME = ... endlet; Далее можно вставлять в запросы в виде ::NAME::",
            query_string: 'Строка запроса',
            details_query_string: 'Строка запроса деталей',
            remove: 'Удалить виджет',
            params: 'Параметры',

            param: {
                label: 'Метка',
                default: 'Значение по умолчанию',
                code: 'Название',
                fieldtype: 'Тип поля',
                fieldtypes: {
                    number: 'Число',
                    text: 'Текст',
                }
            },

            select_res_field: {
                fieldtype: 'Результат - простое значение',
                entity: 'Результат - значение из словаря',
                fieldtype_label: 'Тип поля',
                number: 'Число',
                text: 'Текст',
                percent: 'Проценты',
                entity_label: 'Значение из словаря',
                entity_field: 'Поле значения',
            }
        }
    },
    pages: {
        pageListTitle: "Страницы",
        editPageTitle: "Редактировать страницу",
        componentListTitle: "Компоненты",
        components_guide: {
            title: "Наполнение страниц",
            general: `Каждая страница состоит из компонентов - блоков содержимого со своими правилами отображения. Вы можете создать любое число компонентов на странице, чтобы разбить ее содержимое на логические блоки и упростить работу с ними.
            Компоненты на страницe отображаются в порядке возрастания значений поля 'Порядок'.
            Если тип компонента не указан, то его содержимое считается текстом в формате Markdown и при отображении страницы будет отформатировано соответствующим образом. С краткой справкой по Markdown можно ознакомиться по ссылке:`,

            md_guide_url: "https://paulradzkov.com/2014/markdown_cheatsheet/",
            md_guide_text: "Описание формата Markdown",

            part2: `Отображение и требования к содержимому других компонентов зависит от типа. Для использования в системе доступны следующие компоненты:`,

            type_descriptions: {
                markdown: "текст в формате Markdown (см. выше)",
                documents: "ссылки для скачивания документов, отображаются с иконками. Каждая строка содержимого компонента должна быть представлена в следующем формате (без кавычек): 'текст ссылки; ссылка на файл; имя файла при скачивании'",
                conference: "кнопка для подключения к конференции по ссылке. Первая строка содержимого должна быть ссылкой, вторая (опционально) - текстом на кнопке",
                tabs: "вкладки с произвольным контентом. Содержимое должно состоять из блоков текста, определяющих вкладки в формате: 1я строка - ::tab, 2я строка - название вкладки, 3я и далее - содержимое вкладки в формате markdown",
            },

            part3: `Если для компонента указан неизвестный системе тип, то он не будет отображаться на странице. Эту особенность можно использовать, чтобы временно скрыть часть страницы.
            Когда компонент должен быть скрыт, укажите произвольный текст в качестве его типа, а чтобы снова показать его, замените этот текст на нужный тип.`,
        },

        editComponentTitle: "Редактировать компонент",
        deleteConfirmation: "Удалить страницу?",
        page: {
            slug: "Slug",
            language: "Язык",
            styles: "Стили",
            properties: "Свойства"
        },
        component: {
            order: "Порядок",
            contenttext: "Содержимое",
            subtypetext: "Тип",
            properties: "Свойства"
        }
    },

    images: {
        upload_image_hint: "Нажмите, чтобы загрузить изображение",
        upload: "Загрузить",
        delete: "Удалить",
    },

    contact_form: {
        title: "Сообщения",

        labels: {
            message: "Сообщение",
            user_id: "Пользователь",
            contact: "E-mail",
            is_processed: "Обработано",
            is_discarded: "Спам",
            is_important: "Важное",
            created_datetime: "Дата",
        },
    },

    tags: {
        labels: {
            label: "Тег",
            color: "Цвет",
            label_plural: "Теги",
            count: "Число использований",
        },

        create: "Создать тег",
        duplicate: "Дублировать тег",
        edit: "Редактировать тег",
        remove: "Удалить тег?",
        remove_in_use: "Тег используется. При удалении он будет удален у всех записей.",
        tag_usage: "Использования тега ",
        collect_tags: "Собрать теги",

        merge: {
            select_source: "Выберите тег, с которым нужно соединить",
            confirmation: "Соединить теги?",
            execute: "Соединить",
        },

        error: {
            cant_merge_no_tags: "Не выбраны теги для соединения",
        },
    },

    system: {
        snapshots: {
            labels: {
                name: "Название",
                created_datetime: "Создан",
            },

            title: "Архивы",
            create_snapshot: "Создать архив",
            switch_to_snapshot: "Переключиться",
        },
    },

    contests: {
        scoring: {
            sheets_list_title: "Оценочные листы",
            sheet_edit_title: "Оценочный лист: {title}",
            create_sheet: "Создать оценочный лист",
            sheet_preview: "Предпросмотр",
            sheet_numeric_total: "Всего баллов: {total}",

            fields: {
                sheet: {
                    code: "Код",
                    title: "Название",
                    description: "Описание",
                    criteria: "Критерии",
                },
                criteria: {
                    name: "Название",
                    comment: "Комментарий/подсказка",
                    type: "Тип",
                    min_value: "Минимум",
                    max_value: "Максимум",
                    options: "Варианты",
                    is_required: "Обязательное поле",
                    is_optional: "Необязательное",
                    reverse_values_order: "Обратный порядок",

                    custom: "Специальный тип",
                    use_chips: "Кнопки",
                },
            },

            checkbox: {
                yes: "Да",
                no: "Нет",
            },
        },

        rounds: {
            config: {
                title: 'Раунды',
                labels: {
                    configuration: "Конфигурация",
                    code: "Код",
                    has_playoff: "Раунды на вылет",
                    playoff_stages_cnt: "Число стадий",
                    playoff_teams_required: "Требуется команд",
                    playoff_team_matching_rule: "Способ матчинга команд",
                    playoff_has_third_place_game: "Игра за 3-е место",
                    playoff_round_title_final: "Название финального раунда",
                    playoff_round_title_third: "Название раунда за 3-е место",
                    playoff_round_title_po: "Название раундов на вылет",
                    
                    has_rating_rounds: "Рейтинговые раунды",
                    rating_group_kind: "Тип группы",
                    rating_group_kind_values: {
                        pairs_stable: "Пары постоянные",
                        pairs_shift: "Пары сдвигаемые",
                        triplets: "Тройки",
                    },

                    rating_grouping_rule: "Способ формирования групп",
                    rating_grouping_rule_values: {
                        random: "Случайный",
                        sequential: "Последовательный",
                        inward: "Снаружи внутрь",
                        halves: "Половинами",
                    },
                    rating_player_rounds_cnt: "Число раундов на команду",
                    rating_round_title: "Название рейтингового раунда",
                    players_accepted_cnt: "Число допускаемых команд",
                    rating_rounds_cnt: "Число рейтинговых раундов",
                    round_title_hint: "Допустимые подстановки: {stage} {number}",

                    avoid_player_conflicts: "Избегать конфликтов между командами",

                    arbiter_access_url: "Формат ссылки для арбитра",
                    player_access_url: "Формат ссылки для команды",
                    access_url_hint: "Доступные подстановки: {round_id}, {party_id}, {access_code}",

                    is_launched: "Запущены",
                    launch: "Запустить",
                    cancel: "Отменить",
                    cancel_confirmation_title: "Отменить раунды?",
                    cancel_confirmation_explanation: "Все созданные стадии, раунды, назначения и поставленные оценки будут удалены. Продолжить?",

                    scoring_configuration: {
                      title: "Конфигурация оценок",
                      uses_default: "Используется исходная конфигурация",
                      reset: "Сбросить к исходной",
                      modify: "Изменить",
                      default_config: "Базовая конфигурация (для всех стадий)",
                      enable_other_stages: "Добавить конфигурацию для конкретной стадии:",

                      stage: {
                        result_pick: "Результат по",
                        result_aggregate: "Агрегация",
                        winner_pick: "Победитель по",
                        winner_aggregate: "Агрегация",
                      },

                      score: {
                        key: "Код",
                        label: "Название",
                        type: "Тип",
                        sheet: "Лис оценки",
                        extract_pick: "Поля",
                      },
                    },
                },

                stages: {
                    launch: "Запустить",
                    launch_confirmation: "Стадия будет запущена, команды и арбитры получат доступ к раундам",
                    launch_forced_confirmation: "В раундах есть проблемы. Запустить несмотря на это?",
                    unlaunch: "Отменить запуск",
                    unlaunch_confirmation: "Запуск стадии будет отменен, доступ команд и арбитров к раундам будет отозван.",
                    finish: "Завершить",
                    calc_results: "Подсчитать результаты",
                    export: "Выгрузить данные",
                    finish_confirmation: "Стадия будет завершена, простановка оценок заблокируется, команды будут отправлены в следующую стадию в соответствии с результатами раундов. Продолжить?",
                    finish_forced_confirmation: "В раундах есть проблемы. Завершить несмотря на это?",
                    unfinish: "Отменить завершение",
                    unfinish_confirmation: "Стадия будет возобновлена, обновление прогресса участников будет отменено. Продолжить?",
                    validate: "Проверить",
                    clear_problems: "Очистить проблемы",
                    assign_players: "Назначить команды",
                    assign_players_confirmation: "Существующие назначения будут перезаписаны. Продолжить?",
                    assign_arbiters: "Назначить арбитров",
                    assign_arbiters_confirmation: "Существующие назначения будут перезаписаны. Продолжить?",
                    include_tags: "Включить по тегам",
                    exclude_tags: "Исключить по тегам",

                    clear_arbiters: "Очистить арбитров",
                    clear_arbiters_confirmation: "Будут удалены все назначения арбитров в этой стадии. Продолжить?",
                    clear_players: "Очистить команды",
                    clear_players_confirmation: "Будут удалены все назначения команд в этой стадии. Продолжить?",
                    clear_stage: "Пересоздать",
                    clear_stage_confirmation: "Будут удалены все назначения и вся информация по раундам этой стадии. Продолжить?",

                    qr_codes: "Получить QR коды для доступа к оценкам",

                    edit_rounds: {
                        title: "Редактировать раунды",
                        rounds_selected: "Раундов выбрано: {count} ({nbrs})",
                        success_message: "Раунды сохранены",
                    },


                    view: "Вид",
                    view_short: "Краткий",
                    view_detailed: "Полный",
                    view_results: "Результаты",

                    timezone: {
                      switch_label: "Показывать время",
                      local: "местное",
                      utc: "UTC / без зоны",
                    },

                    names: {
                        po_final: "Финал",
                        po_1_2: "Полуфинал",
                        po_1_4: "Четвертьфинал",
                        po_1_8: "Одна восьмая",
                        po_1_16: "Одна шестнадцатая",
                        rating: "Рейтинговые",
                    },

                    validation: {
                        rounds_with_problems: "Есть проблемы в раундах №№ {value}",
                        players_unassigned: "Команды не назначены",
                        arbiters_unassigned: "Арбитры не назначены",
                        conflicts: "Есть конфликты",
                        conflicts_players: "Есть конфликты между командами",
                        is_draw: "Ничья",
                        missing_scores: "Не все оценки проставлены: {arbiters}",
                        unequal_player_assignment: "Неравное назначение команд: {players}", 
                        arbiters_time_clash: "Арбитры назначены на несколько раундов, проходящих в одно время: {arbiters}. Раунды: {rounds}",
                        arbiters_time: "Арбитры не доступны в это время: {arbiters}",
                    },
                },

                validation: {
                    must_have_some_rounds: "Должны быть раунды",
                    specify_playoff_stages_cnt: "Укажите число стадий на вылет",
                    specify_players_accepted: "Укажите число допущенных команд",
                    not_enough_players_for_playoff: "Недостаточно команд для раундов на вылет",
                    players_count_doesnt_match_required_for_playoff: "Число команд не соответствует необходимому для раундов на вылет",
                    specify_rating_rounds_cnt: "Укажите число рейтинговых раундов",
                    pairs_need_even_teams: "Для разбивки команд на пары необходимо четное число команд",
                    triplets_need_divisible3_teams: "Для разбивки команд на тройки число команд должно делиться на 3",
                    triplets_must_have_2_rounds_per_team: "При разбивке на тройки должно быть по два раунда на команду",
                    cant_update_launched: "Невозможно обновить - раунды уже запущены",
                    problems_with_config: "Пожалуйста, исправьте проблемы с конфигурацией",
                    already_launched: "Раунды уже запущены",

                    arbiter_not_found: "Арбитр не найден",
                    cant_assign_player_against_itself: "Команда не может играть против самой себя",
                    cant_assign_the_same_arbiter_to_a_round_twice: "Арбитр может быть назначен на один и тот же раунд только один раз",
                    dont_know_how_to_create_multiple_rounds: "Некорректные настройки: алгоритм сбора множественных пар",
                    next_stage_is_finished: "Следующая стадия завершена - сначала откатите ее",
                    only_two_rounds_in_triples: "Некорректные настройки: в тройках может быть только по два раунда на команду",
                    player_not_found: "Команда не найдена",
                    players_count_doesnt_match_required_for_rounds: "Некорректные настройки: число команд не соответствует необходимому для раундов",
                    stage_has_problems: "В стадии есть проблемы",
                    stage_is_finished: "Стадия уже завершена",
                    stage_is_launched: "Стадия уже запущена",
                    stage_is_not_finished: "Стадия еще не завершена",
                    stage_is_not_launched: "Стадия еще не запущена",
                    unknown_matching_rule: "Некорректные настройки: правило матчинга команд",
                },
            },

            stage: {
                labels: {
                    kind: "Тип",
                    is_launched: "Запущена",
                    is_finished: "Завершена",
                },
            },

            round: {
                edit: "Раунд {title}",
                labels: {
                    title: "Название",
                    number: "Номер",
                    time: "Время",
                    description: "Описание",
                    meeting_join_url: "Ссылка для присоединения",
                    winner: "Победитель",
                    arbiters: "Арбитры",
                    vs: "vs",
                    player1: "Команда 1",
                    player2: "Команда 2",
                    player_unassigned: "не выбрана",
                    player1_score: "Результат команды 1",
                    player2_score: "Результат команды 2",
                    arbiter: "Арбитр {position}",
                    arbiter_unassigned: "не выбран",
                },
            },

            player_standing: {
                sort_order: "№",
                display_name: "Команда",
                score: "Результат",
                is_round_winner: "Победа в раунде",
                wins: "Побед",
                losses: "Поражений",
                pass: "Проходит дальше",

                pass_yes: "Да",
                pass_no: "Нет",
            },

            player: {
                select: "Выбрать команду",
                labels: {
                    display_name: "Номер",
                    description: "Описание",
                    rounds_assigned: "Раундов назначено",
                    tags: "Теги",
                }
            },

            arbiter: {
                select: "Выбрать арбитра",
                labels: {
                    display_name: "Имя",
                    description: "Описание",
                    rounds_assigned: "Раундов назначено",
                }
            },

            scores: {
                score: "Оценить",
                scored: "Оценено",
                select_player: "Выбрать",
                selected_player: "Выбрана",
            },

            ratings: {
                columns: {
                    position: "№",
                    display_name: "Команда",
                    result: "Результат",
                    scores_cnt: "Оценок",
                    scores: "Все оценки",
                }
            },

            public: {
                stage_names: {
                    po_final: "Финал",
                    po_1_2: "Полуфинал",
                    po_1_4: "Четвертьфинал",
                    po_1_8: "Одна восьмая",
                    po_1_16: "Одна шестнадцатая",
                    rating: "Рейтинговые раунды",
                },
                
                join_conference: "Подключиться",
                copy_join_url: "Скопировать ссылку для подключения",
                empty_state: "Здесь будут показаны раунды, в которых вы участвуете",
            }
        },

        registrations: {
            total: "Регистраций:",
            remove_hint: 'Если вы уверены, что хотите удалить запись {description}, введите слово "delete" в поле ниже и нажмите "Удалить". Это действие необратимо!',
            labels: {
                approval: "Одобрение",
                actions: "Действия",
            },
            tabs: {
                all: "Все",
                approved: "Одобренные",
                unapproved: "Неодобренные",
            },
        },

        timeslots: {
          add: "Добавить слот",
          date: "Дата",
          time: "Время",
        },

        join_conference: "Подключиться к конференции",
    },

    forms: {
        page: {
            list_title: "Формы",
            create_form: "Создать форму",
            form_page_title: "Форма",
            config: "Конфигурация",
            actions: "Действия",
            submissions: "Заявки",
            delete_confirmation: "Удалить форму?",
            delete_submission_confirmation: "Удалить заявку?",
            add_field: "добавить поле",
            non_default_locale_edit_fields_hint: "Переключитесь на язык по умолчанию, чтобы изменять тип и обязательность полей",
            review_title: "Заявка",
            change_field_id: "Сменить ID поля",

            submission_view: {
                all: "Все",
                new: "Новые",
                processed: "Обработанные",
            },
            submissions_total: "Всего заявок: {total}",
        },

        form: {
            title: "Название",
            is_active: "Активна",
            button_label: "Текст на кнопке",
            on_submission_robud_program: "При получении исполнить",
            fields: "Поля",
        },

        field: {
            title: "Название",
            fieldtype: "Тип",
            is_required: "Обязательное",
            is_hidden: "Скрытое",
            hint: "Подсказка",
            validation_regex: "Валидация (regex)",
            options: "Варианты (отдельными строчками)",
            block_submit_if: "Заблокировать форму при варианте",
            hide_next_if: "Скрыть последующие поля при варианте",
            block_submit_message: "Сообщение, если форма заблокирована",
            copy_code_hint: "Скопировать код поля",
            actions: {
              on_submission: "При отправке",
              manual: "Ручные",
            },
            types: {
                text: "Текст",
                text_multiline: "Многострочный текст",
                date: "Дата",
                select_radio: "Выбор",
                select_multi: "Выбор множественный",
                email: "E-mail",
                boolean: "Чекбокс",
                number: "Число",
                file: "Файл",
                rank: "Ранжирование",
            },
            regex_help: {
              phone: "Валидация номера телефона",
              reference: "Справка по регулярным выражениям",
              reference_link: "https://www.regular-expressions.info/quickstart.html",
            },
        },

        submission: {
            created_at: "Создана",
            lang: "Язык",
            processing_status: "Статус",
            comment: "Комментарий",
            download: "скачать",

            processing_status_value: {
                new: "Новая",
                processed: "Обработана",
            },
        },

        editor: {
            insert: "Конфигурируемая форма",
            form_selection_label: "Форма",
            success_message: "Сообщение при отправке",
        },

        actions: {
          update_form_submission: {
            title: "Обновление заявки",
            description: "Обновить поля заявки.\nСинтаксис инструкций: fields.by_idx.0 <- fields.email | fields.by_idx.0 = READY | processing_status = processed\nВ инструкциях можно использовать {{подстановки}} (справа от =)",
            instructions_hint: " ",
            form_id: "ID формы",
            submission_id: "ID заявки",
            skip_on_not_found: "Пропускать, если не найдена",
          },
        },
    },

    powerdoc: {
        menu: {
            formatting: "Форматирование",
            elements: "Элементы",
            insert_item: "Вставить",
        },

        format: {
            bold: "Жирный",
            italic: "Курсив",
            underline: "Подчеркнуть",
            strikethrough: "Зачеркнуть",
            highlight: "Выделить",
            code: "Код",
        },
        
        elements: {
            heading: "Заголовок {level}",
            paragraph: "Параграф",
            ul: "Список",
            ol: "Нумерованный список",
            new_line_after: "Новая строка",
            delete_block: "Удалить блок",
            divider: "Разделитель",
            non_breaking_space: "Неразрывный пробел",
        },

        align: {
            left: "По левому краю",
            right: "По правому краю",
            center: "По центру",
            justify: "Выровнять",
        },

        pages: {
            title: "Документы",
            remove_document: "Удалить документ",
            new_document_title: "Новый документ",
            placeholder: "Введите текст…",
            document_title_placeholder: "Название документа",
        },

        fields: {
            title: "Название",
            created_datetime: "Создан",
        },

        plugins: {
            block_settings: {
                title: "Настройки блока",
                dialog_title: "Настройки блока ({block_type})",
            },

            columns: {
                title: "Колонки",
                n_columns: "Колонки",
            },

            conditional_block: {
                title: "Условный блок",
                condition: "Условие",
            },

            expander: {
                title: "Разворачивающийся блок",
            },

            custom_fields_block: {
                title: "Произвольные поля",
                field: {
                    label: "Имя",
                    value: "Значение",
                },
            },

            gallery: {
                title: "Изображения",

                click_here_to_add: "нажмите здесь, чтобы вставить изображение",
                dialog_title: "Вставить изображение",
                image_url: "URL изображения",
                insert: "Вставить",
            },

            images: {
                title: "Изображение",
                dialog_title: "Настройки изображения",

                fields: {
                    width: "Ширина",
                    height: "Высота",
                    _class: "_class",
                    replace_image: "Заменить изображение",
                },
            },

            links: {
                title: "Ссылка",
                url: "URL",
                remove_link: "Удалить ссылку",
            },

            media_files: {
                title: "Изображения и документы",
                empty_state: "Нажмите, чтобы выбрать или загрузить файлы",

                display_mode: {
                    title: "Режим отображения",
                    
                    gallery: "Галлерея",
                    documents: "Документы",
                    slider: "Слайдер",
                },
            },

            panel: {
                title: "Панель",
            },

            placeholder: {
                title: "Подстановка",
                dialog_title: "Вставить подстановку",
                expression: "Выражение",
                mode: {
                    field: "Поле",
                    expression: "Выражение",
                },
            },

            script: {
                title: "Скрипт",
            },

            table: {
                title: "Таблица",
                insert: {
                    row_before: "Вставить строку сверху",
                    row_after: "Вставить строку снизу",
                    column_before: "Вставить колонку слева",
                    column_after: "Вставить колонку справа",
                },
                remove: {
                    row: "Удалить строку",
                    column: "Удалить столбец",
                },
            },

            template_block: {
                title: "Блок",
                dialog_title: "Вставить блок",
            },

            tiles: {
                title: "Тайлы",
                columns: "Колонки: {columns}",
                tile: {
                    title: "Название",
                    content: "Текст",
                    image_url: "Изображение",
                    link: "Ссылка",
                    _class: "Класс / свойства",
                },
            },
        },
    },

    robud: {
        title: "Robud",

        program: {
            page_title: "Программа",
            remove: "Удалить программу?",
            program_removed: "Программа удалена",

            tabs: {
                program: "Конфигурация",
                triggers: "Триггеры",
                executions: "Исполнения",
            },

            labels: {
                _id: "ID",
                title: "Название",
                data: "Данные",
                operations: "Операции",
                created_at: "Создана",
            },

            data: {
                kind: {
                    sql: "SQL",
                    constant: "Константа",
                    custom: "Специальный",
                },
                labels: {
                    kind: "Вид",
                    script: "Запрос",
                    values: "Значения",
                    values_hint: "в виде json массива",
                    custom_name: "Тип",
                }
            },

            operations: {
                add: "Добавить операцию",
                kind: {
                    sql: "SQL",
                    email: "Email",
                },
                labels: {
                    kind: "Вид",

                    sql: {
                        script: "Скрипт",
                    },
                    email: {
                        template: "Шаблон",
                        user_email: "Кому (e-mail)",
                    },
                }
            },

            triggers: {
                add: "Добавить триггер",
                execute: "Запустить",
                remove: "Удалить триггер?",

                kind: {
                    manual: "Ручной",
                    schedule: "Расписание",
                },

                labels: {
                    trigger_id: "ID",
                    kind: "Режим",
                    is_active: "Активен",

                    configuration: {
                        period: {
                            qty: "Период",
                            unit: " ",
                        },

                        calendar: {
                            at: "Время",
                            from: "С",
                            to: "По",
                            dom: "Дни месяца",
                            dow: "Дни недели",
                            dow_labels: {
                                "1": "Пн",
                                "2": "Вт",
                                "3": "Ср",
                                "4": "Чт",
                                "5": "Пт",
                                "6": "Сб",
                                "7": "Вс",
                            },
                        },
                    },
                },
            },
        },

        programs: {
            page_title: "Программы",
            create_program: "Создать программу",
        },

        executions: {
            page_title: "Лог исполнений",
            see_log: "смотреть лог",
            details_title: "Детали исполнения",
            copy_data: "Скопировать данные",
            copy_results: "Скопировать результаты",
            copy_errors: "Скопировать ошибки",
            has_errors: "ошибки",

            filter: {
                after: "С",
                before: "По",
                reset: "Сбросить фильтр",
            },

            labels: {
                program_id: "Программа",
                program_title: "Название",
                trigger_id: "Триггер",
                execution_id: "Исполнения",
                created_at: "Время",
                data: "Данные",
                data_rows_nbr: "Строк данных",
                results: "Результаты",
                errors: "Ошибки",
            },
        },

        error: {
            trigger_inactive: "Триггер неактивен - его нельзя запустить",
            cant_delete_trigger_already_executed: "Триггер уже запускался - его нельзя удалить",
            substitution_not_found_for_param: "SQL: не удалось произвести подстановку параметра",
            robud_inactive: "Robud неактивен - возможно его что-то отключило",
        },

        errors: {
            substitution_not_found_for_param: "SQL: не удалось произвести подстановку параметра",
        }
    },

    cms: {
        title: "CMS",
        list: {
            new_record: "Новая запись: {record_type}",
            all_languages: "все",
        },
        form: {
            publish: "Опубликовать",
            remove: "Удалить запись?",
            translation_links: "Переводы",
            translation_links_popup: "Выбрать перевод: {lang}",
            open_site: "Посмотреть на сайте",
        },
        importexport: {
            title: "Импорт / экспорт",
            record_type: "Тип записи",
            record_type_label: " ",

            export_action: "Экспорт",
            import_action: "Импорт",
            import_success: "Импорт записей успешно завершен",
        },

        record: {
            fields: {
                _id: "ID",
                title: "Название",
                slug: "Slug",
                lang: "Язык",
                record_datetime: "Дата",
                created_datetime: "Создана",
                published_datetime: "Опубликована",
                content: "Содержимое",
                attributes: "Атрибуты",
            }
        },

        editor: {
            insert_linked_records: "Связанные записи",
            linked_records_empty: "Выбрать записи",
        },


        error: {
            record_type_not_found: "Неизвестный тип записи",
            link_type_not_found: "Неизвестный тип ссылки",
            unknown_language: "Неизвестный язык",
            slug_is_required: "Поле Slug обязательно для заполнения",
            slug_in_use: "Этот slug уже используется ({slug}, {record_type})",
            unknown_link_kind: "Неизвестный механизм ссылки",
            cant_link_record_to_itself: "Нельзя связать запись с самой собой",
            cant_have_more_than_one_translation_per_language: "Нельзя добавить больше одного перевода для каждого языка",
        },
    },

    documentation: {
        create_doc: {
            insert: "Вставить документ",
            dialog_title: "Создать документ",
            document_title: "Название",

            mode: {
                new: "Новый",
                existing: "Существующий",
            },
        },
    },

    extras: {
      actions: {
        fields: {
          action_type: "Тип",
          action_params: "Параметры",
          label: "Название",
        },

        types: {
          action_chain: {
            title: "Цепочка действий",
            description: "Выполнить последовательность действий друг за другом",
            actions: "Действия",
          },
          enrich_data: {
            title: "Обогащение данных",
            description: "Добавить/заменить поля в данных.\nСинтаксис инструкций: field.in.result <- field.in.data || default ИЛИ field.in.result = value",
            instructions: "Инструкции",
            instructions_hint: "можно указать несколько отдельными строками",
          },
          log_data: {
            title: "Лог",
            description: "Записывает данные в лог приложения (для отладки)",
            message: "Текст сообщения",
          },
        },

        triggers: {
          edit_triggers_actions_title: "Действия",
        },
      },

      mass_action: {
        dialog_title: "Выполнить действие: {label}",
        execute: "Запустить",
      },
    },
    
    features: {
        switches: {
            title: "Функции",
        },
    },
}