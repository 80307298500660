import React from 'react';
import { Search } from './components/Search';
import { Questions } from '../questions/presentation/Questions';
import { useQuestionList } from '../questions/hook/useQuestionList';

export const MainPage = () => {
  const data = useQuestionList();
  return (
    <>
      <Search searchText={data.searchText} updateText = {data.updateText} />
      <Questions data = {data} />
    </>
  );
};
