import React from 'react';
import { PowerEditorBase } from '../toolympus/components/PowerDoc/slate/PowerEditorBase';
import { useMediaFilesPlugin } from '../toolympus/components/PowerDoc/plugins/MediaFiles';
import { MediaLibProvider } from '../toolympus/components/medialib';

interface Props {
  content: any;
}

export const DocumentationViewerUnwrapped = (props: Props) => {
  const medialibPlugin = useMediaFilesPlugin();
  return (
    <PowerEditorBase
      content={props.content}
      update={() => {}}
      plugins={[
        medialibPlugin,
      ]}
      viewMode
      placeholder=" "
      />
  );
}

export const DocumentationViewer = (props: Props) => {
  
  return (
    <MediaLibProvider apiPath="/api/docs/media" spaceId="docs" protectedFiles getFilepath={mf => `/api/docs/media/${mf.space_id}/${mf._id}`} loadOnDemand>
      <DocumentationViewerUnwrapped {...props} />
    </MediaLibProvider>
  );
}
