import React, { createContext, ReactNode, useContext } from 'react';
import { PresidiumMember } from '../api/types/questions';
import { useLoadedData } from '../toolympus/hooks/useLoadedData';

interface CurrentMember extends PresidiumMember {
    reload: () => void;
}

const AuthorizedUserContext = createContext<CurrentMember>({ reload: () => { } } as CurrentMember);

export const AuthorizedUserProvider = (props : { children: ReactNode }) => {
    const { data, reload } = useLoadedData<PresidiumMember>("/api/presidium-app/me", { firstname: "", lastname: "" } as PresidiumMember);

    return <AuthorizedUserContext.Provider value={{ ...data, reload }}>
        {props.children}
    </AuthorizedUserContext.Provider>
}

export const useAuthorizedUser =  () => useContext(AuthorizedUserContext);

export const getMemberName = (locale: string, member: PresidiumMember) => locale === "ru"
    ? `${member.firstname} ${member.lastname}`
    : `${member.firstname_en || member.firstname} ${member.lastname_en || member.lastname}`;
